import React, { createContext, useCallback } from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ConfirmationModal, GlassModal } from "../../components/modal";
import { GlassCard } from "../../components/card";
import { Link, Outlet, useNavigate } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import { useAuth } from "../../context/auth";
import axios from "../../api/axios";
import { CreateAdmin } from "./create-admin";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Avatar } from "../../components/avatar";
import logo from "../../assets/logo/coloredlogo.png";
import { TableSpinner } from "../../components/spinner";
import { FormatDateTime } from "../../components/formatDate";

const ADMIN_LIST_URL = "/admin/get-all";
const ADMIN_DELETE_URL = "/admin/delete";
const ADMIN_UPDATE_STATUS = "/admin/update-admin-status";
const GET_ADMIN_BY_ID = "/admin/admin-by-id?id=";

const columns = [
  { id: "s_n", label: "S/N", minWidth: 30 },
  { id: "profile_picture", label: "", minWidth: 80 },
  { id: "fullname", label: "FullName", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 80 },

  {
    id: "phone",
    label: "Phone Number",
    minWidth: 80,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 80,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "timestamp",
    label: "Joined",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  //   {
  //     id: "action",
  //     label: "Action",
  //     minWidth: 100,
  //     align: "center",
  //     format: (value) => value.toFixed(2),
  //   },
];

function createData(
  id,
  s_n,
  profile_picture,
  fullname,
  email,
  phone,
  type,
  status,
  timestamp
  //   action
) {
  return {
    id,
    s_n,
    profile_picture,
    fullname,
    email,
    phone,
    type,
    status,
    timestamp,
    // action,
  };
}

export const AdminView = createContext();

export const AdminList = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const token = auth.token;

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalFilteredData, setTotalFilteredData] = React.useState(0);
  const [adminList, setAdminList] = React.useState([]);
  const [getAdminsObj, setAdminsObject] = React.useState();
  const [status, setAccStatus] = React.useState({
    accStatus: "",
  });

  React.useEffect(() => {
    getAdminList();
  }, []);

  const getAdminList = async () => {
    try {
      const response = await axios({
        method: "get",
        url: ADMIN_LIST_URL,
        ...config,
      });
      // console.log(response?.data);
      setPage(0);
      setAdminList(response?.data?.data);
      setTotalFilteredData(response?.data.data.length);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  // Admin list pagination
  const handleChangePage = async (event, newPage) => {
    const pg = newPage + 1;
    try {
      const response = await axios({
        method: "get",
        url: ADMIN_LIST_URL + "?page=" + pg,
        ...config,
      });
      setPage(newPage);
      setTotalFilteredData(response?.data.data.length);
      setAdminList(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    try {
      const response = await axios({
        method: "get",
        url: +"?limit=" + event.target.value,
        ...config,
      });
      setRowsPerPage(+event.target.value);
      setPage(0);
      setTotalFilteredData(response?.data.data.length);
      setAdminList(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const adminObject = (data) => {
    // console.log(data);
    adminList?.map((item, i) => {
      if (item._id === data) {
        navigate("view-admin", { state: { data: item } });
        setAdminsObject(item);
      }
    });
  };

  const onChange = async (e, param) => {
    setAccStatus({ ...status, [e.target.name]: e.target.value });
    const params = {
      email: param,
      status: e.target.value,
      comment: "Account is active",
    };

    try {
      const response = await axios({
        method: "put",
        url: ADMIN_UPDATE_STATUS,
        data: params,
        ...config,
      });
      toast.success(response?.data.message);
      console.log(response?.data.message);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err?.response.data);
        toast.error(err?.response.data);
      }
    }
  };

  const [addAdmin, setModalOpen] = React.useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // Confirmation dialog
  const [confirmationModal, setConfirmationModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleOpenConfirmationModal = async (value) => {
    setConfirmationModalOpen(true);
    setEmail(value);
  };
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  // DELETE ADMIN WITH EMAIL
  const deleteAdmin = async (email) => {
    try {
      //    DeleteAdmin(email)
      await axios
        .delete(ADMIN_DELETE_URL + `?email=${email}`, config)
        .then(() => toast.success(`${email} is deleted successfully`));
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const searchUser = async (e) => {
    try {
      if (e.keyCode === 13 && e.target.value.length > 0) {
        await axios
          .get(GET_ADMIN_BY_ID + e.target.value, config)
          .then((response) => {
            setAdminList(response?.data?.data);
          });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  const rows = (
    <>
      {adminList?.map((item, index) => {
        return createData(
          item.id,
          index + 1,
          <Avatar
            cl="inline-block h-10 w-12 rounded-full ring-1 ring-[#59899b]"
            src={item.profile_picture || logo}
            alt=""
          />,
          <span className="capitalize">
            {item.last_name + " " + item.first_name}
          </span>,
          item.email,
          item.phone_number,
          item.type,
          <>
            <select
              className={
                item.account_status.status === "ACTIVE"
                  ? "text-green-600 font-black"
                  : [
                      item.account_status.status === "ARCHIVED"
                        ? "text-blue-900 font-black"
                        : [
                            item.account_status.status === "PENDING"
                              ? "text-orange-500 font-black"
                              : "text-red-500 font-black",
                          ],
                    ]
              }
              value={status.acc_status}
              name="accStatus"
              id="accStatus"
              onChange={(e) => onChange(e, item.email)}
            >
              <option value="">{item.account_status.status}</option>
              <option value={status["ACTIVE"]}>ACTIVE </option>
              <option value={status["ARCHIVED"]}>ARCHIVED </option>
              <option value={status["PENDING"]}>PENDING </option>
              <option value={status["DELETED"]}>DELETED </option>
            </select>
          </>,
          FormatDateTime(item.registration_date),
          <span className="flex space-x-4">
            {/* <Link
              to={"view-admin"}
              className="flex"
              onClick={() => adminObject(item)}
            >
              <RiIcons.RiEyeFill size={15} color={"#59899b"} className="my-1" />
              View
            </Link> */}
            {/* <button className='flex' onClick={() => adminObject(item)}>
                            <TbIcons.TbEditCircle size={15} color={'#f98c19'} className="my-1" />
                            Edit
                        </button> */}
            {/* <button className='flex' onClick={() => handleOpenConfirmationModal(item.email)}>
                            <FaIcons.FaTimesCircle size={15} color={'red'} className="my-1" />
                            Delete
                        </button> */}
          </span>
        );
      })}
    </>
  );

  return (
    <>
      <GlassModal handleOpen={addAdmin} handleClose={handleClose}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-end font-bold"
        >
          <button
            type="button"
            onClick={handleClose}
            className="border-2 border-blue-900 rounded-lg p-1 h-min text-xs"
          >
            <FaIcons.FaTimes size={12} className="dark-blue-text" />
          </button>
        </Typography>
        <CreateAdmin />
      </GlassModal>

      <ConfirmationModal
        handleOpen={confirmationModal}
        handleClose={handleCloseConfirmationModal}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-between font-bold"
        >
          <p className="dark-blue-text font-bold">Delete confirmation</p>
          <button
            type="button"
            onClick={handleCloseConfirmationModal}
            className="border-2 border-orange-400 rounded-lg p-1 h-min text-xs"
          >
            <FaIcons.FaTimes size={12} color={"orange"} />
          </button>
        </Typography>
        <Typography
          component={"span"}
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex lg:flex-row flex-col space-y-6"
        >
          <article className="w-full mb-5">
            <header className="light-orange-text font-semibold">
              Are you sure you want to permanently delete {email}?
            </header>
          </article>
        </Typography>
        <Typography component={"span"}>
          <footer className="flex w-full justify-end mt-2 space-x-3">
            <button
              type="button"
              className="bg-gradient-to-t from-[#ff0000] via-[#ffffff] to-[#ff0000] text-[#000000] rounded-lg font-bold px-4 py-2"
              onClick={() => deleteAdmin(email)}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-black outline-2 outline-double rounded-lg font-bold px-3 py-1"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </button>
          </footer>
        </Typography>
      </ConfirmationModal>

      <div className="lg:flex lg:flex-row flex-col lg:space-x-4">
        <div className="lg:flex w-full">
          <GlassCard>
            <div className="flex justify-between border-b-2 border-gray-300 pb-2 mb-2">
              <h1 className="flex text-xl dark-blue-text font-black">
                <FaIcons.FaUserTie size={28} /> Admin List
              </h1>
              <span
                className="space-x-4"
                hidden={auth.user.profile.type !== "SUPER"}
              >
                <Link to="" onClick={handleOpen} className="flex">
                  <MdIcons.MdAddCircle size={20} />
                  Add Admin
                </Link>
              </span>
            </div>

            {/* <div className="flex my-2">
              <div className="relative">
                <div className="absolute pointer-events-auto py-1 px-2">
                  <RiIcons.RiSearch2Line size={18} />
                </div>
                <input
                  type="text"
                  placeholder="Search by id"
                  onKeyDown={searchUser}
                  className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
                />
              </div>
            </div> */}

            {rows?.props?.children?.length === 0 ? (
              <TableSpinner />
            ) : (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer className="min-w-full max-w-[500px]">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            key={index}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.props?.children
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.email}
                            >
                              {columns.map((column, index) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={index}
                                    align={column.align}
                                    onClick={() => adminObject(row?.id)}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={totalFilteredData || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>
            )}
          </GlassCard>
        </div>

        <div className={!!getAdminsObj ? "flex-1 lg:mt-0 mt-4" : "hidden"}>
          <AdminView.Provider value={getAdminsObj}>
            <Outlet />
          </AdminView.Provider>
        </div>
      </div>
    </>
  );
};
