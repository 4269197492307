import React from "react";
import { DefaultCard, GlassCard } from "../../components/card";
import TextTruncate from "react-text-truncate"; // recommend
import * as GiIcons from "react-icons/gi";
import * as RiIcons from "react-icons/ri";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Badge } from "../../components/badge";
import { useAuth } from "../../context/auth";
import axios from "../../api/axios";
import { FilterSelect } from "../../components/form";
import { toast } from "react-toastify";
import { Pagination, Stack } from "@mui/material";
import { TableSpinner } from "../../components/spinner";
import { FormatDateTime } from "../../components/formatDate";
import { ExportExcel } from "../../components/excel-export";

const ticketsFilterList = [
  {
    id: 1,
    name: "Unassinged",
  },
  {
    id: 2,
    name: "Assinged",
  },
  {
    id: 3,
    name: "All Ticket",
  },
  {
    id: 4,
    name: "Archived",
  },
];

// const ticketList = [
//     {
//         id: 1,
//         header: 'Error message prompt while trying to withdra',
//         timestamp: '1 min ago',
//         content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.',
//         category: 'Urgent',
//         badgeClass: 'light-orange-bg rounded-full py-1 px-3 w-fit text-xs'
//     },
//     {
//         id: 2,
//         header: 'Error message prompt while trying to withdra',
//         timestamp: '1 min ago',
//         content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.',
//         category: 'Pending',
//         badgeClass: 'light-blue-bg rounded-full py-1 px-3 w-fit text-xs'
//     },
//     {
//         id: 3,
//         header: 'Error message prompt while trying to withdra',
//         timestamp: '1 min ago',
//         content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.',
//         category: 'Low',
//         badgeClass: 'bg-green-500 rounded-full py-1 px-3 w-fit text-xs'
//     }
// ]

const GET_SUPPORT_LIST = "/admin/supports";
const GET_USER_BY_ID = "/admin/user-by-id?id=";

const FILTER_BY_STATUS = [
  {
    id: 0,
    title: "All status",
    type: "ALL",
  },
  {
    id: 1,
    title: "Pending",
    type: "PENDING",
  },
  {
    id: 2,
    title: "Processing",
    type: "PROCESSING",
  },
  {
    id: 3,
    title: "Resolved",
    type: "RESOLVED",
  },
];

const FILTER_BY_TYPE = [
  {
    id: 0,
    title: "All type",
    type: "ALL",
  },
  {
    id: 1,
    title: "General",
    type: "GENERAL",
  },
  {
    id: 2,
    title: "Transaction",
    type: "TRANSACTION",
  },
  {
    id: 3,
    title: "Wallet",
    type: "WALLET",
  },
  {
    id: 4,
    title: "Profile",
    type: "PROFILE",
  },
];

export const ChatList = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getSupportList();
  }, []);

  const getSupportList = async () => {
    try {
      const response = await axios({
        method: "get",
        url: GET_SUPPORT_LIST,
        ...config,
      });

      setTicketList(response?.data.data.page_data);
      setPageCount(response?.data.data.page_count);
      setTotalFilteredData(response?.data.data.total_filtered_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const [ticketList, setTicketList] = React.useState();
  const [pageCount, setPageCount] = React.useState(0);
  const [totalFilteredData, setTotalFilteredData] = React.useState(0);

  const [filterValue, setFilterValue] = React.useState({
    filterType: "",
    filterStatus: "",
  });

  const [isActive, setActiveButton] = React.useState("ALL");
  //Filter tickets by status
  const filterStatus = async (value) => {
    // setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    setActiveButton(value);
    try {
      const response = await axios({
        method: "get",
        url:
          value === "ALL"
            ? GET_SUPPORT_LIST
            : GET_SUPPORT_LIST + "?status=" + value,
        ...config,
      });
      setTicketList(response.data?.data?.page_data);
      setPageCount(response?.data.data.page_count);
      setTotalFilteredData(response?.data.data.total_filtered_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  //Filter tickets by type
  const filterType = async (e) => {
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    try {
      if (e.target.value.length > 0) {
        const response = await axios({
          method: "get",
          url:
            e.target.value === "ALL"
              ? GET_SUPPORT_LIST
              : GET_SUPPORT_LIST + "?type=" + e.target.value,
          ...config,
        });

        setTicketList(response.data?.data?.page_data);
        setPageCount(response?.data.data.page_count);
        setTotalFilteredData(response?.data.data.total_filtered_data);
      } else {
        // setTransactions({transactions})
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  //Search for ticket by ticket id
  const searchTicket = async (e) => {
    try {
      if (e.keyCode === 13 && e.target.value.length > 0) {
        const response = await axios({
          method: "get",
          url: GET_SUPPORT_LIST + "?ticket_id=" + e.target.value,
          ...config,
        });
        setTicketList(response.data?.data?.page_data);
        setPageCount(response?.data.data.page_count);
        setTotalFilteredData(response?.data.data.total_filtered_data);
      } else {
        const response = await axios({
          method: "get",
          url: GET_SUPPORT_LIST,
          ...config,
        });
        setTicketList(response.data?.data?.page_data);
        setPageCount(response?.data.data.page_count);
        setTotalFilteredData(response?.data.data.total_filtered_data);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  const navigateToChatBox = (data) => {
    navigate("chat-box", { state: { data: data } });
  };

  const onChangePage = async (newPage) => {
    try {
      const response = await axios({
        method: "get",
        url: GET_SUPPORT_LIST + "?page=" + newPage,
        ...config,
      });
      setTicketList(response?.data.data.page_data);
      setPageCount(response?.data.data.page_count);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      // console.log(response?.data.data)
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  return (
    <>
      <GlassCard>
        <>
          <div className="space-y-4">
            <div className="flex justify-between">
              <h1 className="flex text-md dark-blue-text font-black space-x-1">
                <GiIcons.GiTicket size={20} />
                <strong>Tickets</strong>
              </h1>
            </div>

            <nav className="flex lg:flex-row">
              <ul className="flex flex-row space-x-3 text-sm">
                {FILTER_BY_STATUS.map((item, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        exact="true"
                        className={
                          item.type === isActive
                            ? "border-b-2 border-orange-400 rounded-full"
                            : "none"
                        }
                        to=""
                        onClick={() => filterStatus(item.type)}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>

            <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
              <span className="flex lg:mt-0 mt-4">
                <div className="relative">
                  <div className="absolute pointer-events-auto py-1 px-2">
                    <RiIcons.RiSearch2Line size={18} />
                  </div>
                  <input
                    type="text"
                    placeholder="Search Ticket"
                    onKeyDown={searchTicket}
                    className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
                  />
                </div>
                {/* <SearchInput label="Search" icon={<RiIcons.RiSearch2Line size={22} />} /> */}
              </span>
              <span className="flex space-x-4">
                {/* <FilterSelect
                  defaultOption="Filter by status"
                  name="filterStatus"
                  value={filterValue.filterStatus}
                  onChange={filterStatus}
                  icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
                  options={FILTER_BY_STATUS.map((item, index) => {
                    return (
                      <>
                        <option key={index} value={item.type}>
                          {item.title}
                        </option>
                      </>
                    );
                  })}
                /> */}

                <FilterSelect
                  defaultOption="Filter by type"
                  name="filterType"
                  value={filterValue.filterType}
                  onChange={filterType}
                  icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
                  options={FILTER_BY_TYPE.map((item, index) => {
                    return (
                      <option key={index} value={item.type}>
                        {item.title}
                      </option>
                    );
                  })}
                />

                <div className="flex w-full bg-transparent border-2 rounded-md py-0.5 px-2">
                  <RiIcons.RiArrowRightUpLine
                    size={15}
                    color={"#f98c19"}
                    className="my-1"
                  />
                  <ExportExcel
                    excelData={ticketList}
                    fileName={"Tickets Data"}
                  />
                </div>
              </span>
            </div>

            <div
              className={
                ticketList
                  ? "relative max-h-screen overflow-y-auto space-y-2"
                  : "relative max-h-screen overflow-hidden"
              }
            >
              {ticketList ? null : <TableSpinner />}
              {ticketList?.map((item, index) => {
                return (
                  <DefaultCard
                    key={index}
                    cl="drop-shadow-xl shadow-lg h-min p-4 bg-white rounded-lg"
                  >
                    <span
                      className="cursor-pointer"
                      onClick={() => navigateToChatBox(item)}
                    >
                      <article>
                        <header className="dark-blue-text font-bold flex justify-between">
                          <span className="capitalize">
                            {item.title} &nbsp;
                            <Badge
                              cl={"light-blue-bg rounded-md px-2 w-fit text-xs"}
                              content={item.type}
                            />
                          </span>
                          <sub>{FormatDateTime(item.created_at)}</sub>
                        </header>
                        <TextTruncate
                          line={2}
                          truncateText="…"
                          element="sub"
                          text={item.description}
                        />

                        <p className="flex space-x-3 mt-2 justify-between">
                          <span className="flex flex-col-reverse">
                            <Badge
                              cl={
                                item.status.status === "PENDING"
                                  ? "light-orange-bg rounded-full py-1 px-3 w-fit text-xs"
                                  : [
                                      item.status.status === "PROCESSING"
                                        ? "bg-blue-300 rounded-full py-1 px-3 w-fit text-xs"
                                        : "bg-green-500 rounded-full py-1 px-3 w-fit text-xs text-white",
                                    ]
                              }
                              content={item.status.status}
                            />
                            <sub className="dark-blue-text my-auto mb-3">
                              ID: {item.ticket_id}
                            </sub>
                          </span>
                          <span className="flex items-center align-text-bottom min-w-max space-x-2">
                            <p className="min-w-max">
                              <strong className="dark-orange-text text-sm">
                                Created By:
                              </strong>
                            </p>
                            <p className="capitalize w-full text-xs">
                              {item?.user[0]?.bio_data?.last_name}{" "}
                              {item?.user[0]?.bio_data?.first_name}
                              <br />
                              {item?.user[0]?.auth?.username}
                            </p>
                          </span>
                        </p>
                      </article>
                    </span>
                  </DefaultCard>
                );
              })}
            </div>
          </div>

          <div className="flex justify-between mt-2">
            <span>Filtered Data: {totalFilteredData}</span>
            <Stack spacing={2}>
              <Pagination
                count={pageCount}
                shape="rounded"
                onChange={(e, value) => onChangePage(value)}
              />
            </Stack>
          </div>
        </>
      </GlassCard>
    </>
  );
};
