import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/logo/coloredlogo-name.png";

import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { BasicModal, GlassModal } from "../../components/modal";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { Typography } from "@mui/material";
import { FilterSelect } from "../../components/form";
import { Link } from "react-router-dom";
import { GlassCard } from "../../components/card";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FILTER_BY_STATUS, FILTER_BY_TYPE } from "../../enum/transactions-enum";
import { TableSpinner } from "../../components/spinner";
import { VerifyTransaction } from "../../api/transaction-endpoints";
import copy from "copy-to-clipboard";
import { FormatDateTime } from "../../components/formatDate";
import { ExportExcel } from "../../components/excel-export";
import ReactToPrint, { useReactToPrint } from "react-to-print";

const GET_TRANSACTIONS = "/admin/transactions";
const GET_TRANSACTION_BY_ID = "/admin/transaction-with-tid?tid=";
const FILTER_TRANSACTIONS = "/admin/transactions?";
const GET_USER_BY_ID = "/admin/user-by-id";
const VERIFY_TRANSACTION = "/transaction/verify-with-reference?reference=";
const GET_ADMIN_BY_ID = "/admin/admin-by-id";

const columns = [
  { id: "amount", label: "Amount", minWidth: 150 },
  { id: "type", label: "Transaction Type", minWidth: 100 },
  { id: "reference", label: "Reference Number", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  {
    id: "timestamp",
    label: "Performed At",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(id, amount, type, reference, status, timestamp) {
  return {
    id,
    amount,
    type,
    reference,
    status,
    timestamp,
  };
}

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div>
        <h2 style={{ color: "green" }}>Attendance</h2>
        <table>
          <thead>
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Njoku Samson</td>
              <td>samson@yahoo.com</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ebere Plenty</td>
              <td>ebere@gmail.com</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Undefined</td>
              <td>No Email</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export const TransactionTable = () => {
  const auth = useAuth();
  const token = auth.token;
  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getTransactionList();
  }, []);

  const getTransactionList = async () => {
    try {
      await axios.get(GET_TRANSACTIONS, config).then((response) => {
        setPage(0);
        setTotalFilteredData(response?.data.data.total_filtered_data);
        setTransactions(response?.data.data.page_data);
      });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  const copyToClipboard = async (copyText) => {
    await copy(copyText);
    await toast(`You have copied "${copyText}" to clipboard`);
  };

  const [transactions, setTransactions] = React.useState([]);
  const [openTransModal, setTransModalOpen] = React.useState(false);
  const [displayTrans, setDisplayTrans] = React.useState({});
  const [sourceDetails, setSourceDeatils] = React.useState();
  const [destinationDetails, setDestinationDetails] = React.useState();
  const [filterValue, setFilterValue] = React.useState({
    filterType: "",
    filterStatus: "",
  });
  const [verificationDetails, setVerificationDetails] = React.useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalFilteredData, setTotalFilteredData] = React.useState(0);

  const handleChangePage = async (event, newPage) => {
    const pg = newPage + 1;
    try {
      const response = await axios({
        method: "get",
        url: GET_TRANSACTIONS + "?page=" + pg,
        ...config,
      });
      setPage(newPage);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      setTransactions(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    try {
      const response = await axios({
        method: "get",
        url: GET_TRANSACTIONS + "?limit=" + event.target.value,
        ...config,
      });
      setRowsPerPage(+event.target.value);
      setPage(0);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      setTransactions(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const [verificationModal, setVerificationModalOpen] = React.useState(false);
  const openVerificationModal = () => {
    setVerificationModalOpen(true);
  };
  const closeVerificationModal = () => setVerificationModalOpen(false);

  const handleOpen = async (tid) => {
    setTransModalOpen(true);
    setDisplayTrans();
    try {
      const response = await axios({
        method: "get",
        url: GET_TRANSACTION_BY_ID + tid,
        ...config,
      });
      setDisplayTrans(response?.data);

      if (response?.data.source_user_id) {
        setSourceDeatils();
        axios({
          method: "get",
          url: GET_ADMIN_BY_ID + `?id=${response?.data.source_user_id}`,
          ...config,
        })
          .then((res) => {
            // console.log(res?.data?.data);
            setSourceDeatils(res?.data?.data);
          })
          .catch((err) => {
            console.log(err);
          });

        axios({
          method: "get",
          url: GET_USER_BY_ID + `?id=${response?.data.source_user_id}`,
          ...config,
        })
          .then((res) => {
            // console.log(res?.data)
            setSourceDeatils(res?.data?.data);
          })
          .catch((err) => {
            console.log(err?.response.data.status);
          });
      }
      if (response?.data.destination_user_id) {
        const getDestinationUserResponse = await axios({
          method: "get",
          url: GET_USER_BY_ID + `?id=${response?.data.destination_user_id}`,
          ...config,
        });
        setDestinationDetails(getDestinationUserResponse?.data?.data);
        // console.log(response?.data.destination_user_id);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const handleClose = () => setTransModalOpen(false);

  const fetchUserTransaction = async (e) => {
    // console.log(e.target.value)
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    try {
      if (e.target.value.length > 0) {
        await axios
          .get(FILTER_TRANSACTIONS + e.target.value, config)
          .then((response) => {
            setTotalFilteredData(response?.data.data.total_filtered_data);
            setTransactions(response?.data.data.page_data);
          });
      } else {
        // setTransactions({transactions})
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  const searchUserTransaction = async (e) => {
    try {
      if (e.keyCode === 13 && e.target.value.length > 0) {
        await axios
          .get(FILTER_TRANSACTIONS + "tid=" + e.target.value, config)
          .then((response) => {
            setTransactions(response?.data.data.page_data);
          });
      } else {
        await axios.get(GET_TRANSACTIONS, config).then((response) => {
          setTransactions(response?.data.data.page_data);
        });
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  const verifyTransaction = async (e) => {
    try {
      if (e.keyCode === 13 && e.target.value.length > 0) {
        const response = await axios({
          method: "get",
          url: VERIFY_TRANSACTION + e.target.value,
          ...config,
        });
        setVerificationDetails(response?.data.data);
      } else {
        // setVerificationModalOpen(false)
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
      }
    }
  };

  const componentToPrintRef = React.useRef(null);
  const [printClicked, setPrintClicked] = useState(false);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (printClicked && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printClicked]);

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setPrintClicked(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setPrintClicked(false);
    },
  });

  const rows = (
    <>
      {transactions.map((item, index) => {
        return createData(
          item.id,
          item.currency + " " + item.amount,
          item.type,
          item.tid,
          // <span>
          //   {item.tid}
          //   <button type="button" onClick={() => copyToClipboard(item.tid)}>
          //     <MdIcons.MdContentCopy
          //       size={20}
          //       color="#f98c19"
          //       className="my-auto ml-2"
          //     />
          //   </button>
          // </span>,
          <>
            {item.status === "SUCCESS" ? (
              <strong className="text-green-600 font-black">SUCCESSFUL</strong>
            ) : (
              [
                item.status === "PENDING" ? (
                  <strong className="text-orange-600 font-black">
                    PENDING
                  </strong>
                ) : (
                  [
                    item.status === "ABANDONED" ? (
                      <strong className="text-gray-400 font-black">
                        ABANDONED
                      </strong>
                    ) : (
                      <strong className="text-red-600 font-black">
                        FAILED
                      </strong>
                    ),
                  ]
                ),
              ]
            )}
          </>,
          // item.created_at,
          FormatDateTime(item.created_at)
        );
      })}
    </>
  );

  return (
    <>
      {/* View transaction details Modal */}
      <BasicModal
        handleOpen={openTransModal}
        handleClose={handleClose}
        cl="absolute w-[50%] m-auto h-fit rounded-2xl border-1"
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-between font-bold"
        >
          <p className="dark-blue-text font-bold">{displayTrans?.tid}</p>
          <button
            type="button"
            onClick={handleClose}
            className="border-2 border-orange-400 rounded-lg p-1 h-min text-xs"
          >
            <FaIcons.FaTimes size={12} color={"orange"} />
          </button>
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="span"
          component="span"
          sx={{ mt: 2 }}
          className="flex flex-col space-y-6 h-[500px] overflow-y-auto"
        >
          {!displayTrans ? (
            <TableSpinner />
          ) : (
            <div
              ref={componentToPrintRef}
              className={printClicked ? "p-10" : ""}
            >
              <p
                className={
                  printClicked ? "dark-blue-text font-bold mb-2" : "hidden"
                }
              >
                {"Reference: " + displayTrans?.tid}
              </p>

              <div className="grid gap-3 grid-cols-2 w-full ">
                <div className="">
                  <p className="dark-blue-text text-xl font-bold bg-gray-300 px-2 rounded-lg">
                    Sender Informations
                  </p>

                  <article className="my-2">
                    <header className="light-orange-text font-semibold">
                      Sender Name
                    </header>
                    <p className="dark-blue-text font-bold text-md break-words capitalize">
                      {sourceDetails?.bio_data
                        ? sourceDetails?.bio_data?.last_name +
                          " " +
                          sourceDetails?.bio_data?.first_name
                        : sourceDetails?.last_name +
                          " " +
                          sourceDetails?.first_name}
                    </p>
                  </article>

                  <article className="my-2">
                    <header className="light-orange-text font-semibold">
                      Email
                    </header>
                    <p className="dark-blue-text font-bold text-md break-words">
                      {sourceDetails?.auth
                        ? sourceDetails?.auth?.email
                        : sourceDetails?.email}
                    </p>
                  </article>

                  <article className="my-2">
                    <header className="light-orange-text font-semibold">
                      {sourceDetails?.auth ? "Username" : "Admin"}
                    </header>
                    <p className="dark-blue-text font-bold text-md break-words">
                      {sourceDetails?.auth
                        ? sourceDetails?.auth?.username
                        : sourceDetails?.type}
                    </p>
                  </article>
                </div>
                <div className="">
                  <p className="dark-blue-text text-xl font-bold bg-gray-300 px-2 rounded-lg">
                    Beneficiary Informations
                  </p>
                  {displayTrans?.destination_user_id ? (
                    <>
                      <article className="my-2">
                        <header className="light-orange-text font-semibold">
                          Reciever Name
                        </header>
                        <p className="dark-blue-text font-bold text-md break-words">
                          {destinationDetails?.bio_data?.last_name +
                            " " +
                            destinationDetails?.bio_data?.first_name}
                        </p>
                      </article>
                      <article className="my-2">
                        <header className="light-orange-text font-semibold">
                          Email
                        </header>
                        <p className="dark-blue-text font-bold text-md break-words">
                          {destinationDetails?.auth?.email}
                        </p>
                      </article>
                      <article className="my-2">
                        <header className="light-orange-text font-semibold">
                          Username
                        </header>
                        <p className="dark-blue-text font-bold text-md break-words">
                          {destinationDetails?.auth?.username}
                        </p>
                      </article>
                    </>
                  ) : (
                    [
                      displayTrans?.meta?.phone ? (
                        <article className="my-2">
                          <header className="light-orange-text font-semibold">
                            Reciever's Phone Number
                          </header>
                          <p className="dark-blue-text font-bold text-md break-words">
                            {displayTrans?.meta?.phone}
                          </p>
                        </article>
                      ) : (
                        [
                          displayTrans?.type === "ELECTRICITY" ? (
                            <>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Name
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.Name}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Address
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.Address}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Meter Number
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.MeterNumber}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Receipt Number
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.ReceiptNumber}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Reference
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.Reference}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Token
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.Token}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Purchased Unit
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.PurchasedUnits}
                                </p>
                              </article>
                            </>
                          ) : (
                            <>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Bank Name
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.phone}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Account Name
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.phone}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Account Number
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.phone}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Recipient ID
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.recipient}
                                </p>
                              </article>
                              <article className="my-2">
                                <header className="light-orange-text font-semibold">
                                  Transfer Code
                                </header>
                                <p className="dark-blue-text font-bold text-md break-words">
                                  {displayTrans?.meta?.transfer_code}
                                </p>
                              </article>
                            </>
                          ),
                        ]
                      ),
                    ]
                  )}
                </div>
              </div>

              <div className="flex flex-wrap w-full h-[300px] overflow-y-auto">
                <p className="h-fit dark-blue-text text-xl w-full font-bold bg-gray-300 px-2 rounded-lg mb-3">
                  Other Informations
                </p>

                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Type
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.type}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Status
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.status}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Time
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {FormatDateTime(displayTrans?.created_at)}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Charges
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.charges}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Amount
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.currency + " " + displayTrans?.amount}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Balance
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.balance || 0}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Mode
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.mode}
                  </p>
                </article>
                <article className="w-1/3 mb-5">
                  <header className="light-orange-text font-semibold">
                    Narration
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {displayTrans?.narration}
                  </p>
                </article>
              </div>
            </div>
          )}
        </Typography>
        <Typography variant="span" component="span">
          <footer className="flex w-full justify-end mt-2">
            <button
              type="button"
              onClick={handlePrint}
              className="bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#59899b] text-[#f6f6f6] rounded-lg font-bold px-3 py-1"
            >
              Print
            </button>
          </footer>
        </Typography>
      </BasicModal>

      {/* <ComponentToPrint ref={(el) => (componentToPrintRef = el)} /> */}

      {/* Paystack Verification Modal */}
      <GlassModal
        handleOpen={verificationModal}
        handleClose={closeVerificationModal}
        cl={"absolute w-[30%] m-auto h-fit rounded-2xl border-1"}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-end font-bold"
        >
          <button
            type="button"
            onClick={closeVerificationModal}
            className="border-2 border-blue-900 rounded-lg p-1 h-min text-xs"
          >
            <FaIcons.FaTimes size={12} className="dark-blue-text" />
          </button>
        </Typography>
        <div className="flex justify-center pt-4">
          <input
            type="text"
            placeholder="Enter transaction reference"
            onKeyDown={verifyTransaction}
            className="w-[90%] h-10 pl-8 rounded-full border-2 border-b-4 border-gray-200  bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
          />
        </div>
        {verificationDetails ? (
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="flex flex-col space-y-6"
          >
            <div className="flex flex-col min-h-[300px] m-4 bg-slate-100 rounded-xl px-4 pt-4 pb-20 relative">
              <p className="dark-blue-text font-semibold text-sm text-center mb-6">
                TRANSACTION DETAILS
              </p>
              <ul>
                <li className="flex justify-between">
                  <p>Customer:</p>
                  <p>{verificationDetails?.customer}</p>
                </li>
                <li className="flex justify-between">
                  <p>Reference No:</p>
                  <p>{verificationDetails?.reference}</p>
                </li>
                <li className="flex justify-between">
                  <p>Status:</p>
                  <p>{verificationDetails?.status}</p>
                </li>
                <li className="flex justify-between">
                  <p>Initiated At:</p>
                  <p>{FormatDateTime(verificationDetails?.created_at)}</p>
                </li>
                <li className="flex justify-between">
                  <p>Completed At:</p>
                  <p>{FormatDateTime(verificationDetails?.paid_at)}</p>
                </li>
              </ul>
              <div className="flex justify-end absolute bottom-0 right-2">
                <img src={logo} className="w-16" alt="logo" />
              </div>
            </div>
          </Typography>
        ) : null}
      </GlassModal>

      <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
        <span className="flex lg:mt-0 mt-4">
          <div className="relative">
            <div className="absolute pointer-events-auto py-1 px-2">
              <RiIcons.RiSearch2Line size={18} />
            </div>
            <input
              type="text"
              placeholder="Search by transaction ID"
              onKeyDown={searchUserTransaction}
              className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
            />
          </div>
        </span>
        <span className="flex space-x-4">
          <FilterSelect
            defaultOption="Filter by status"
            name="filterStatus"
            value={filterValue.filterStatus.replace("status=", "") || ""}
            onChange={fetchUserTransaction}
            icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
            options={FILTER_BY_STATUS.map((item, index) => {
              return (
                <option key={index} value={item.filterProp + item.type}>
                  {item.title}
                </option>
              );
            })}
          />
          <FilterSelect
            defaultOption="Filter by type"
            name="filterType"
            value={filterValue.filterType.replace("type=", "")}
            onChange={fetchUserTransaction}
            icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
            options={FILTER_BY_TYPE.map((item, index) => {
              return (
                <option key={index} value={item.filterProp + item.type}>
                  {item.title}
                </option>
              );
            })}
          />
          <button className="flex w-full bg-transparent border-2 rounded-md py-0.5 px-2">
            <RiIcons.RiArrowRightUpLine
              size={15}
              color={"#f98c19"}
              className="my-1"
            />
            <ExportExcel
              excelData={rows?.props?.children}
              fileName={"Transactions Data"}
            />
          </button>
          {/* <button className='flex bg-transparent border-2 rounded-md py-0.5 px-2'>
                            <RiIcons.RiArrowUpDownFill size={15} color={'#f98c19'} className="my-1" />
                            Sort
                        </button> */}
        </span>
      </div>

      <GlassCard>
        <div className="flex justify-between mb-2">
          <h1 className="flex lg:text-2xl text-lg dark-blue-text font-black">
            <AiIcons.AiOutlineSwap size={28} /> Transactions
          </h1>
          <span className="flex space-x-4">
            <Link
              to={""}
              onClick={openVerificationModal}
              className="bg-blue-400 rounded-full px-2 py-1 flex h-min text-white text-md whitespace-nowrap"
            >
              <MdIcons.MdVerified size={20} />
              &nbsp; Verify paystack transaction{" "}
            </Link>
            <Link
              to={"fund-user"}
              className={
                auth.user.profile?.type === "SUPER"
                  ? "bg-green-600 rounded-full px-2 py-1 flex h-min text-white text-md whitespace-nowrap"
                  : "hidden"
              }
            >
              <FaIcons.FaMoneyBillAlt size={25} />
              &nbsp; Fund User{" "}
            </Link>
          </span>
        </div>

        <div className="relative">
          {rows?.props?.children?.length === 0 ? <TableSpinner /> : null}

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer className="min-w-full max-w-[1000px] max-h-96">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length === 0 ? (
                    <TableSpinner />
                  ) : (
                    rows?.props?.children?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column, index) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                onClick={() => handleOpen(row?.reference)}
                                align={column.align}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={totalFilteredData || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </GlassCard>
    </>
  );
};
