import React from "react";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import * as CiIcons from "react-icons/ci";
import * as DiIcons from "react-icons/di";
import * as FiIcons from "react-icons/fi";
import * as FcIcons from "react-icons/fc";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import * as GoIcons from "react-icons/go";
import * as GrIcons from "react-icons/gr";
import * as HiIcons from "react-icons/hi";
import * as Hi2Icons from "react-icons/hi2";
import * as ImIcons from "react-icons/im";
import * as IoIcons from "react-icons/io";
import * as Io5Icons from "react-icons/io5";
import * as MdIcons from "react-icons/md";
import * as RxIcons from "react-icons/rx";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";
import * as SlIcons from "react-icons/sl";
import * as TbIcons from "react-icons/tb";
import * as TfiIcons from "react-icons/tfi";
import * as TiIcons from "react-icons/ti";
import * as VscIcons from "react-icons/vsc";
import * as WiIcons from "react-icons/wi";
import * as CgIcons from "react-icons/cg";

export const Icons = {
  AiIcons,
  BsIcons,
  BiIcons,
  CiIcons,
  DiIcons,
  FiIcons,
  FcIcons,
  FaIcons,
  GiIcons,
  GoIcons,
  GrIcons,
  HiIcons,
  Hi2Icons,
  ImIcons,
  IoIcons,
  Io5Icons,
  MdIcons,
  RxIcons,
  RiIcons,
  SiIcons,
  SlIcons,
  TbIcons,
  TfiIcons,
  TiIcons,
  VscIcons,
  WiIcons,
  CgIcons,
};
const Icon = ({ typeName, name, color, size = 24, style }) => {
  const fontSize = 24;
  const Tag = typeName;
  return (
    <>
      {typeName && (
        <Tag size={size || fontSize} color={color} style={style} />
        // <Tag name={name} size={size || fontSize} color={color} style={style} />
      )}
    </>
  );
};
export default Icon;
