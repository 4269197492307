import React from "react";
import { GlassCard, TransactionGlassCard } from "../../components/card";
import { TransactionTable } from "./transaction-list";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TRANSACTION_SUMMARY } from "../../enum/transactions-enum";
import { TableSpinner } from "../../components/spinner";

const GET_TRANS_SUMMARY = "/admin/transaction-summary";

export const Transactions = () => {
  const auth = useAuth();
  const token = auth.token;

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [summary, setTransSummary] = React.useState();
  const [transactionStatistic, setTransStatistics] = React.useState();

  React.useEffect(() => {
    getTransactionSummary()
  }, []);

  const getTransactionSummary = async () => {
    try {
      await axios.get(GET_TRANS_SUMMARY, config).then((response) => {
        const res = response.data;
        delete res.message;
        delete res.transactions;
        setTransSummary(res);
      });
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout();
      } else {
        console.log(err.response?.data);
        toast.error(err.response?.data.message);
      }
    }
  };

  const [inputValues, setInputValues] = React.useState({
    transactionSummary: "OVERALL",
  });
  const onChange = async (e) => {
    const value = e.target.value;
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    try {
      await axios.get(GET_TRANS_SUMMARY, config).then((response) => {
        const res = response.data;
        if (value === "OVERALL") {
          delete res.message;
          delete res.transactions;
          setTransSummary(res);
          setTransStatistics();
        } else if (value === "SUCCESS") {
          setTransStatistics(res.transactions[0].success);
        } else if (value === "PENDING") {
          setTransStatistics(res.transactions[0].pending);
        } else if (value === "FAILED") {
          setTransStatistics(res.transactions[0].failed);
        }
      });
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  return (
    <>
      <div className="lg:p-8 p-0 space-y-6">
        <GlassCard>
          <div className="relative flex flex-col space-y-6 lg:overflow-hidden overflow-y-auto lg:h-fit h-[350px] min-h-[90px]">
            <div className="flex justify-between">
              <strong className="dark-blue-text font-black text-lg">
                Transaction Statistics
              </strong>

              <select
                className="rounded-xl shadow-inner outline-none bg-white bg-opacity-20"
                name="transactionSummary"
                value={inputValues.transactionSummary}
                onChange={onChange}
              >
                <option>Select Summary</option>
                {TRANSACTION_SUMMARY.map((item, index) => {
                  return (
                    <option key={index} value={item.type}>
                      {item.type}
                    </option>
                  );
                })}
              </select>
            </div>
            
            {!summary ? (
              <TableSpinner />
            ) : (
              <div className="flex flex-col space-y-4 justify-center">
                <div className="flex flex-wrap space-x-4 justify-center">
                  {Object.keys(summary || {})?.map((item, index) => {
                    const title =
                      item.replace("_summary", "").toUpperCase();
                    const count = summary[item].total_count?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    );
                    const amount = summary[item].total_amount?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    );
                    return (
                      <>
                        <TransactionGlassCard
                          key={index}
                          title={title}
                          count={count}
                          amount={amount}
                        ></TransactionGlassCard>
                      </>
                    );
                  })}
                </div>

                <div className="flex flex-wrap space-x-4 justify-center">
                  {transactionStatistic?.map((item, index) => {
                    const title = item._id?.toUpperCase();
                    const count = item.total_count?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    });
                    const amount = item.total_amount?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    );
                    return (
                      <>
                        <TransactionGlassCard
                          key={index}
                          title={title}
                          count={count}
                          amount={amount}
                        ></TransactionGlassCard>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </GlassCard>

        <TransactionTable />
      </div>
    </>
  );
};
