import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from './auth'
import 'react-toastify/dist/ReactToastify.css';

export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()
  if (!auth.user) {
    return (
      <>
        <Navigate to='/sign-in' state={{ path: location.pathname }} />
        {toast('Only authenticated user can navigate')}
      </>
    )
  }
  return (
    <>
      {children}
    </>
  )
}

export const RequireTransactionAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()
  const transactionAdmin = ["ENGINEER","SUPER","ADMIN","TRANSACTION"]
  // console.log(auth.user)
  if (transactionAdmin?.filter(data => data === auth.user.profile?.type).length === 0) {
    return (
      <>
        <Navigate to='/sign-in' state={{ path: location.pathname }} />
        {toast('Only authenticated user can navigate')}
      </>
    )
  }
  return (
    <>
      {children}
    </>
  )
}

export const RequireUsersAuth = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()
  const transactionAdmin = ["ENGINEER","SUPER","ADMIN","TRANSACTION"]
  // console.log(auth.user)
  if (transactionAdmin?.filter(data => data === auth.user.profile?.type).length === 0) {
    return (
      <>
        <Navigate to='/sign-in' state={{ path: location.pathname }} />
        {toast('Only authenticated user can navigate')}
      </>
    )
  }
  return (
    <>
      {children}
    </>
  )
}
