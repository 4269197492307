import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from '../layout/navbar';

export const Home = () => {
    return (
        <>
            <Nav/>
            <div className='container mx-auto -my-20 grid h-screen content-center'>
                <div className='mb-10'>
                    <p className="light-orange-text text-center lg:text-5xl text-3xl break-word font-black -mt-20">
                        A premier information sharing; <br /> "trado-tech" <br /> empowerment & payment system.</p>
                </div>
                <div className='mx-auto mt-10'>
                    <NavLink exact="true" to="sign-in" className='bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#59899b] text-[#f6f6f6] text-2xl shadow-md py-4 px-8 rounded-3xl hover:from-transparent hover:border-[#012a4a] hover:border-2 hover:text-[#012a4a]'>Get Started</NavLink>
                </div>
            </div>
        </>
    )
}