import React from "react";

export const AuthsInput = (props) => {
  const {
    icon,
    errorMessage,
    label,
    forgotPwdSuffix,
    goodSuffix,
    onChange,
    id,
    ...inputProps
  } = props;
  return (
    <>
      <div className="relative">
        <label htmlFor={id} className="text-xs text-gray-500">
          {label}
        </label>
        <div className="absolute pointer-events-auto top-8">{icon}</div>
        <input
          id={id}
          {...inputProps}
          onChange={onChange}
          className="peer w-full px-8 py-2 border-b-2 border-gray-400 focus:invalid:border-red-600 bg-transparent focus:border-green-600 outline-0"
        />
        <div className="absolute pointer-events-auto right-0 top-8 dark-blue-text cursor-pointer">
          {goodSuffix}
          {forgotPwdSuffix}
        </div>
        <span className="text-sm hidden text-red-600 peer-focus:block peer-valid:invisible">
          {errorMessage}
        </span>
      </div>
    </>
  );
};

export const AuthInput = (props) => {
  const {
    icon,
    label,
    name,
    showPwdSuffix,
    forgotPwdSuffix,
    goodSuffix,
    disable,
    type,
    error,
    touched,
    ...inputProps
  } = props;
  return (
    <div className="relative w-full">
      <label className="text-xs text-gray-500 flex">{label}</label>
      <div className="relative w-full">
        <input
          type={type}
          {...inputProps}
          className={
            error && touched
              ? "h-12 w-full rounded-lg bg-transparent border-b-2 border-red-600 disabled:border-gray-500 focus:border-b-2 focus:border-red-600 hover:border-red-600 outline-0 p-2 pr-10"
              : "h-12 w-full rounded-lg bg-transparent border-b-2 border-gray-500 disabled:border-gray-500 disabled:focus:border-gray-500 focus:border-b-2 focus:border-green-700 hover:border-green-700 outline-0 p-2 pr-10"
          }
        />
        <div className="absolute my-auto right-2 top-3 cursor-pointer">
          {/* {showPwdSuffix} */}
          {goodSuffix}
          {forgotPwdSuffix}
        </div>
      </div>
      {error && touched ? (
        <span className="ml-2 text-xs text-red-600">{error}</span>
      ) : null}
    </div>
  );
};

export const Input = (props) => {
  const {
    icon,
    errorMessage,
    label,
    forgotPwdSuffix,
    goodSuffix,
    onChange,
    id,
    disable,
    type,
    error,
    touched,
    ...inputProps
  } = props;
  return (
    <>
      <div className="relative w-full">
        <label className="text-xs text-gray-500">{props.label}</label>
        <div className="absolute pointer-events-auto top-8 left-1">{icon}</div>
        <input
          id={id}
          type={type}
          disable={"disabled"}
          {...inputProps}
          onChange={onChange}
          className={
            error && touched
              ? "w-full pl-8 pr-2 py-2 rounded-lg shadow-lg border-2 border-red-600 disabled:border-gray-500 focus:border-red-600 hover:border-red-600 outline-0"
              : "w-full pl-8 pr-2 py-2 rounded-lg shadow-lg border-2 focus:border-[#012a4a] outline-0  disabled:bg-white placeholder:text-gray disabled:placeholder:text-black"
          }
          // className="peer is-dirty normal-case w-full pl-8 pr-2 py-2 rounded-lg shadow-lg focus:border-[#012a4a] outline-0 disabled:bg-white placeholder:text-black"
        />
        {error && touched ? (
          <span className="ml-2 text-xs text-red-600">{error}</span>
        ) : null}
      </div>
    </>
  );
};

export const Textarea = (props) => {
  const {
    label,
    placeholder,
    children,
    disabled,
    onChange,
    name,
    value,
    required,
    error,
    touched
  } = props;
  return (
    <>
      <div className="relative w-full">
        <label className="text-xs text-gray-500">{label}</label>
        <textarea
          type="text"
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onChange={onChange}
          value={value}
          required={required}
          className={
            error && touched
              ? "h-52 w-full px-2 py-2 rounded-lg shadow-lg border-2 border-red-600 disabled:border-gray-500 focus:border-red-600 hover:border-red-600 outline-0"
              : "h-52 w-full px-2 py-2 rounded-lg shadow-lg border-2 focus:border-[#012a4a] outline-0  disabled:bg-white placeholder:text-black"
          }
          // className="w-full px-2 py-2 rounded-lg shadow-lg focus:border-[#012a4a] outline-0 h-40 disabled:bg-white placeholder:text-gray"
        />
        {children}
        {error && touched ? (
          <span className="ml-2 text-xs text-red-600">{error}</span>
        ) : null}
      </div>
    </>
  );
};

export const Select = ({
  label,
  icon,
  defaultOption,
  options,
  onChange,
  value,
  name,
  disabled,
  error,
  touched,
}) => {
  return (
    <>
      <div className="relative w-full text-black">
        <label className="text-xs text-gray-500 flex">{label}</label>
        <div className="absolute pointer-events-auto top-6 left-1">{icon}</div>
        <select
          placeholder={label}
          value={value}
          name={name}
          onChange={onChange}
          required
          className={
            error && touched
              ? "w-full pl-8 pr-2 py-2 rounded-lg shadow-lg border-2 border-red-600 disabled:border-gray-500 focus:border-red-600 hover:border-red-600 outline-0 text-black"
              : "w-full pl-8 pr-2 py-2 rounded-lg shadow-lg border-2 focus:border-[#012a4a] outline-0  disabled:bg-white placeholder:text-gray disabled:placeholder:text-black text-black"
          }
          disabled={disabled}
        >
          <option>{value ? value : defaultOption}</option>
          {options}
        </select>
        {error && touched ? (
          <span className="ml-2 text-xs text-red-600">{error}</span>
        ) : null}
      </div>
    </>
  );
};

export const FilterSelect = ({
  label,
  icon,
  defaultOption,
  options,
  onChange,
  value,
  name,
}) => {
  return (
    <>
      <div className="relative w-full text-black">
        <label className="text-xs text-gray-500 flex">{label}</label>
        <div className="absolute pointer-events-auto top-2 left-0">{icon}</div>
        <select
          placeholder={label}
          value={value}
          name={name}
          onChange={onChange}
          required
          className="flex bg-transparent border-2 rounded-md drop-shadow-2xl outline-none shadow-inner py-0.5 px-3 opacity-100 text-black"
        >
          <option>{value ? value : defaultOption}</option>
          {options}
        </select>
      </div>
    </>
  );
};

export const SearchInput = (props) => {
  return (
    <>
      <div className="relative">
        <div className="absolute pointer-events-auto py-3 px-2">
          {props.icon}
        </div>
        <input
          type="text"
          placeholder={props.label}
          className="w-[300px] pl-8 py-2 rounded-full border-2 border-b-4 border-gray-200 bg-transparent focus:border-[#012a4a] shadow-xl outline-0"
        />
      </div>
    </>
  );
};

export const Checkbox = (props) => {
  return (
    <>
      <label className="container">
        {" "}
        {props.label}
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          className="checkmark"
        />
      </label>
    </>
  );
};

export const RadioButton = (props) => {
  return (
    <>
      <label className="container">
        <input
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          checked={props.checked}
          required={props.required}
          className="checked:bg-green-500 text-green-500"
        />{" "}
        <span className="dark-orange-text">{props.label}</span>
      </label>
    </>
  );
};
