import React from "react";

import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { Link, useNavigate } from "react-router-dom";
import { GlassCard } from "../../components/card";
import { FilterSelect } from "../../components/form";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import { TableSpinner } from "../../components/spinner";
import { FormatDateTime } from "../../components/formatDate";
import { ExportExcel } from "../../components/excel-export";

const GET_EMPOWERMENT_LIST = "/admin/empowerments";
const UPDATE_EMPOWERMENT_STATUS = "/admin/update-empowement-status";

const columns = [
  { id: "title", label: "Title", minWidth: 100 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "reference", label: "Reference", minWidth: 200 },
  { id: "description", label: "Description", minWidth: 150 },
  {
    id: "status",
    label: "Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "created_at",
    label: "Requested On",
    minWidth: 100,
    align: "left",
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   minWidth: 100,
  //   align: "center",
  // },
];

function createData(
  id,
  title,
  type,
  reference,
  description,
  status,
  created_at
  // action
) {
  return {
    id,
    title,
    type,
    reference,
    description,
    status,
    created_at,
    // action,
  };
}

const FILTER_BY_STATUS = [
  {
    id: 1,
    title: "All Empowerment",
    type: "",
  },
  {
    id: 2,
    title: "Approved",
    type: "APPROVED",
  },
  {
    id: 3,
    title: "Pending",
    type: "PENDING",
  },
  {
    id: 4,
    title: "Declined",
    type: "DECLINED",
  },
];

export const EmpowermentList = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getEmpowermentList();
  }, []);

  const getEmpowermentList = async () => {
    try {
      await axios.get(GET_EMPOWERMENT_LIST, config).then((response) => {
        setPage(0);
        setTotalFilteredData(response?.data.data.total_filtered_data);
        setEmpowermentList(response?.data.data.page_data);
      });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err?.response?.data);
        toast.error(err.response?.data.message);
      }
    }
  };

  const [clickSpinner, setClickSpinner] = React.useState(false);
  const [empowerments, setEmpowermentList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalFilteredData, setTotalFilteredData] = React.useState(0);
  const [status, setEmpStatus] = React.useState({
    empStatus: "",
  });
  const [filterValue, setFilterValue] = React.useState({ filterType: "" });

  const handleChangePage = async (event, newPage) => {
    const pg = newPage + 1;
    try {
      const response = await axios({
        method: "get",
        url: GET_EMPOWERMENT_LIST + "?page=" + pg,
        ...config,
      });
      setPage(newPage);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      setEmpowermentList(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    try {
      const response = await axios({
        method: "get",
        url: GET_EMPOWERMENT_LIST + "?limit=" + event.target.value,
        ...config,
      });
      setRowsPerPage(+event.target.value);
      setPage(0);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      setEmpowermentList(response?.data.data.page_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const onChangeEmpowermentStatus = async (e, param) => {
    setEmpStatus({ ...status, [e.target.name]: e.target.value });
    const params = {
      reference: param,
      status: e.target.value,
      comment: "Empowerment is approved",
    };
    try {
      await axios.put(UPDATE_EMPOWERMENT_STATUS, params, config).then(() => {
        toast.success("Empowerment status updated succefully");
        console.log("Empowerment status updated succefully");
      });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast.error(err.response?.data?.message);
        console.log(err.response?.data);
      }
    }
  };

  const filterEmpowermentByStatus = async (e) => {
    // console.log(e.target.value)
    setClickSpinner(true);
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    try {
      const response = await axios.get(
        e.target.value.length > 0
          ? GET_EMPOWERMENT_LIST + `?status=${e.target.value}`
          : GET_EMPOWERMENT_LIST,
        config
      );
      setTotalFilteredData(response?.data.data.total_filtered_data);
      setEmpowermentList(response?.data?.data?.page_data);
      setClickSpinner(false);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
        setClickSpinner(false);
      }
    }
  };

  const searchEmpowerment = async (e) => {
    setClickSpinner(true);
    try {
      const response = await axios.get(
        e.keyCode === 13 && e.target.value.length > 0
          ? GET_EMPOWERMENT_LIST + `?reference=${e.target.value}`
          : GET_EMPOWERMENT_LIST,
        config
      );
      setEmpowermentList(response?.data?.data?.page_data);
      setClickSpinner(false);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response.data);
        setClickSpinner(false);
      }
    }
  };

  const handleOpenUserModal = async (value) => {
    console.log(value);
    navigate("view-empowerment", { state: { data: value } });

    // try {
    //   await axios
    //     .get(GET_EMPOWERMENT_LIST + `?reference=${value}`, config)
    //     .then((response) => {
    //       const res = response?.data?.data?.page_data;
    //       navigate("view-empowerment", { state: { data: res } });
    //     });
    // } catch (err) {
    //   if (err.response?.status === 401) {
    //     auth.logout();
    //   } else {
    //     console.log(err.response.data);
    //   }
    // }
  };

  const copyToClipboard = async (copyText) => {
    await copy(copyText);
    await toast(`You have copied "${copyText}" to clipboard`);
  };

  const rows = (
    <>
      {empowerments?.map((item, index) => {
        return createData(
          item._id,
          <span className="capitalize">{item.title}</span>,
          item.type,
          <span>
            {item.reference}
            <button
              type="button"
              onClick={() => copyToClipboard(item.reference)}
            >
              <MdIcons.MdContentCopy
                size={20}
                color="#f98c19"
                className="my-auto ml-2"
              />
            </button>
          </span>,
          item.description,
          <span
            className={
              item.status.status === "APPROVED" ||
              status.emp_status === "APPROVED"
                ? "text-green-600 font-black"
                : [
                    item.status.status === "PENDING" ||
                    status.emp_status === "PENDING"
                      ? "text-orange-500 font-black"
                      : "text-red-600 font-black",
                  ]
            }
          >
            {item.status.status}
          </span>,
          FormatDateTime(item.created_at)

          // <span className="flex space-x-4">
          //   <Link
          //     to={""}
          //     className="flex"
          //     onClick={() => handleOpenUserModal(item.reference)}
          //   >
          //     <RiIcons.RiEyeFill size={15} color={"#59899b"} className="my-1" />
          //     View
          //   </Link>
          // </span>
        );
      })}
    </>
  );
  return (
    <>
      <div className="flex lg:flex-row flex-col-reverse lg:justify-between">
        <span className="flex lg:mt-0 mt-4">
          <div className="relative">
            <div className="absolute pointer-events-auto py-1 px-2">
              <RiIcons.RiSearch2Line size={18} />
            </div>
            <input
              type="text"
              placeholder="Search by reference"
              onKeyDown={searchEmpowerment}
              className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
            />
          </div>
        </span>
        <span className="flex space-x-4">
          <FilterSelect
            defaultOption="Filter by status"
            name="filterType"
            value={filterValue.filterType}
            onChange={filterEmpowermentByStatus}
            icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
            options={FILTER_BY_STATUS.map((item, index) => {
              return (
                <option key={index} value={item.type}>
                  {item.title}
                </option>
              );
            })}
          />
          <div className="flex w-full bg-transparent border-2 rounded-md py-0.5 px-2 hover:bg-slate-200">
            <RiIcons.RiArrowRightUpLine
              size={15}
              color={"#f98c19"}
              className="my-1"
            />
            <ExportExcel
              excelData={rows?.props?.children}
              fileName={"Empowerment Request Data"}
            />
          </div>
          {/* <button className='flex bg-transparent border-2 rounded-md py-0.5 px-2'>
                        <RiIcons.RiArrowUpDownFill size={15} color={'#f98c19'} className="my-1" />
                        Sort
                    </button> */}
        </span>
      </div>

      <GlassCard>
        <div className="flex justify-between mb-2">
          <h1 className="flex text-2xl dark-blue-text font-black">
            <SiIcons.SiHelpdesk size={28} />
            &nbsp;Empowerment
          </h1>
          {/* <span>
            <button className="flex">
              See all
              <FaIcons.FaCaretRight size={20} />
            </button>
          </span> */}
        </div>

        <div className="relative">
          {clickSpinner ? <TableSpinner /> : null}
          {!rows?.props?.children ? (
            <TableSpinner />
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer className="min-w-full max-w-[1000px] max-h-96">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.props?.children?.length === 0 ? (
                      <TableSpinner />
                    ) : (
                      rows?.props?.children.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "reference" ? (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              ) : (
                                <TableCell
                                  onClick={() =>
                                    handleOpenUserModal(
                                      row?.reference?.props?.children[0]
                                    )
                                  }
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={totalFilteredData || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </GlassCard>
    </>
  );
};
