import React from "react";
import { GlassCard } from "../../components/card";
import * as BsIcons from "react-icons/bs";
import { Input, Textarea } from "../../components/form";
import * as MdIcons from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as FaIcons from "react-icons/fa";
import { EMPOWERMENT_ENUM } from "../../enum/constant";
import { format } from "date-fns";

const GET_USER_BY_ID = "/admin/user-by-id";
const UPDATE_EMPOWERMENT_STATUS = "/admin/update-empowerment-status";
const GET_EMPOWERMENT_LIST = "/admin/empowerments";

export const ViewEmpowerment = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [userDetails, setUser] = React.useState();
  const [empowermentDetails, setEmpowermentDetails] = React.useState();

  React.useEffect(() => {
    getEmpowerment();
  }, []);

  const getEmpowerment = async () => {
    let userId;
    try {
      await axios
        .get(GET_EMPOWERMENT_LIST + `?reference=${data}`, config)
        .then((response) => {
          const res = response?.data?.data?.page_data;
          userId = res?.[0]?.user_id;
          setEmpowermentDetails(res?.[0]);
          // navigate("view-empowerment", { state: { data: res } });
        });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }

    try {
      await axios
        .get(GET_USER_BY_ID + `?id=${userId}`, config)
        .then((response) => {
          const res = response?.data?.data;
          setUser(res);
        });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err?.response?.data);
        toast.error(err.response?.data.message);
      }
    }
  };

  // Change feeds status
  const [openDialog, setDialogOpen] = React.useState(false);
  const [status, setStatus] = React.useState();
  const [comment, setComment] = React.useState();

  // Confirmation dialog
  const [confirmationModal, setConfirmationModalOpen] = React.useState(false);
  const [reference, setReference] = React.useState("");
  const handleOpenConfirmationModal = async (value) => {
    setConfirmationModalOpen(true);
    setReference(value);
  };
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleStatusMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = async (value) => {
    setDialogOpen(true);
    setStatus(value);

    const params = {
      reference: data,
      status: value,
      comment: `Empowerment status is changed to ${value}`,
    };
    console.log({ ...config });
    try {
      const response = await axios({
        method: "put",
        url: UPDATE_EMPOWERMENT_STATUS,
        data: params,
        ...config,
      });
      toast(response?.data?.message);
      navigate(-1);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast.error(err.response?.data?.message);
        toast.error(err.message);
        console.log(err.message);
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ToastContainer />

      <GlassCard>
        <div className="flex w-full justify-between border-b-2 border-gray-400 pb-2 mb-10">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            <strong className="capitalize">
              {empowermentDetails?.title}&nbsp;Request
            </strong>
          </h1>
          <span className="flex space-x-4 text-white lg:text-lg text-sm">
            {empowermentDetails?.status?.status ===
            EMPOWERMENT_ENUM.APPROVED ? (
              <button
                onClick={handleStatusMenuClick}
                className="bg-green-500 hover:bg-slate-400 rounded-full px-2 py-1 flex h-min"
              >
                <MdIcons.MdOutlineGppGood className="lg:text-2xl text-lg" />
                Approved{" "}
                <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
              </button>
            ) : (
              [
                empowermentDetails?.status?.status ===
                EMPOWERMENT_ENUM.PENDING ? (
                  <button
                    onClick={handleStatusMenuClick}
                    className="bg-orange-400 hover:bg-slate-400 rounded-full px-3 py-1 flex h-min"
                  >
                    <MdIcons.MdBlock className="lg:text-2xl text-lg" />
                    Pending{" "}
                    <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
                  </button>
                ) : (
                  [
                    empowermentDetails?.status?.status ===
                    EMPOWERMENT_ENUM.DECLINED ? (
                      <button
                        onClick={handleStatusMenuClick}
                        className="bg-red-600 hover:bg-slate-400 rounded-full px-3 py-1 flex h-min"
                      >
                        <MdIcons.MdBlock className="lg:text-2xl text-lg" />
                        Declined{" "}
                        <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
                      </button>
                    ) : null,
                  ]
                ),
              ]
            )}

            <button
              className="flex w-full rounded-full px-2 py-1 h-min bg-red-700 hover:bg-slate-400"
              onClick={() => handleOpenConfirmationModal(data)}
            >
              <span className="flex">
                <FaIcons.FaTimesCircle className="lg:text-2xl text-lg" />
                &nbsp;Delete
              </span>
            </button>
          </span>
        </div>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleStatusMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleDialogOpen(EMPOWERMENT_ENUM.APPROVED)}>
            Approve
          </MenuItem>
          <MenuItem onClick={() => handleDialogOpen(EMPOWERMENT_ENUM.PENDING)}>
            Pending
          </MenuItem>
          <MenuItem onClick={() => handleDialogOpen(EMPOWERMENT_ENUM.DECLINED)}>
            Decline
          </MenuItem>
        </Menu>

        <form className="lg:flex flex-wrap lg:mx-20 space-y-6 h-screen overflow-auto">
          <div className="flex flex-wrap justify-center w-full space-x-4">
            {empowermentDetails?.media?.map((item, index) => {
              const ext = item.path.split(".").pop();
              return ext === "jpg" || ext === "png" || ext === "jpeg" ? (
                <a key={index} href={item.path} target="blank">
                  <img
                    src={item.path}
                    className="w-40 h-40 bg-white rounded-xl mb-3"
                    alt="logo"
                  />
                </a>
              ) : (
                [
                  ext === "pdf" || ext === "doc" ? (
                    <a key={index} href={item.path} target="blank">
                      <BsIcons.BsFillFileEarmarkPdfFill
                        color={"orange"}
                        className="w-40 h-40 bg-white rounded-xl mb-3"
                      />
                    </a>
                  ) : (
                    <a key={index} href={item.path} target="blank">
                      <video
                        className="w-40 h-40 bg-white rounded-xl mb-3"
                        controls
                      >
                        <source src={item.path} type="video/mp4" />
                        <source src={item.path} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  ),
                ]
              );
            })}
          </div>

          <div className="flex w-full">
            <Textarea
              label="Description"
              disabled="disabled"
              placeholder={empowermentDetails?.description}
            />
          </div>

          <div className="lg:flex w-full lg:space-x-20">
            <Input
              label="Requester's Name"
              disabled="disabled"
              placeholder={
                userDetails
                  ? userDetails?.bio_data?.last_name +
                    " " +
                    userDetails?.bio_data?.first_name
                  : ""
              }
            />
            <Input
              label="User Type"
              disabled="disabled"
              placeholder={userDetails?.user_type}
            />
          </div>

          <div className="lg:flex w-full lg:space-x-20">
            <Input
              label="Requester's Username"
              disabled="disabled"
              placeholder={userDetails?.referral?.referral_id}
            />
            <Input
              label="Request Title"
              disabled="disabled"
              placeholder={empowermentDetails?.title}
            />
          </div>

          <div className="lg:flex w-full lg:space-x-20">
            <Input
              label="Request Type"
              disabled="disabled"
              placeholder={empowermentDetails?.type}
            />
            <Input
              label="Request Status"
              disabled="disabled"
              placeholder={empowermentDetails?.status.status}
            />
          </div>

          <div className="lg:flex w-full lg:space-x-20">
            <Input
              label="Request Reference"
              disabled="disabled"
              placeholder={empowermentDetails?.reference}
            />
            <Input
              label="Request Date"
              disabled="disabled"
              placeholder={
                empowermentDetails?.created_at
                  ? format(
                      new Date(empowermentDetails?.created_at),
                      "yyyy-MM-dd"
                    )
                  : ""
              }
            />
          </div>

          {/* <div className='flex flex-wrap'>
                        <h1 className='dark-blue-text text-lg font-bold mr-3'>Upload photos</h1>
                        <span className='space-x-4'>
                            <button className='rounded-lg dark-gray-bg p-2'><MdIcons.MdAddCircle size={40} color="#f6f6f680" /></button>
                            <button className='rounded-lg dark-gray-bg p-2'><MdIcons.MdAddCircle size={40} color="#f6f6f680" /></button>
                            <button className='rounded-lg dark-gray-bg p-2'><MdIcons.MdAddCircle size={40} color="#f6f6f680" /></button>
                            <button className='rounded-lg dark-gray-bg p-2'><MdIcons.MdAddCircle size={40} color="#f6f6f680" /></button>
                        </span>
                    </div> */}
        </form>
      </GlassCard>
    </>
  );
};
