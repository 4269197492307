import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// export function BasicModal({ handleOpen, handleClose, children, cl }) {
export const BasicModal = React.forwardRef(
  (props, ref) => {
    return (
      <>
          <Modal
            ref={ref}
            open={props.handleOpen}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={props.cl}
          >
            <Box className="light-gray-bg p-6 rounded-2xl">{props.children}</Box>
          </Modal>
      </>
    );
  }
);

export function GlassModal({ handleOpen, handleClose, children, cl }) {
  return (
    <>
      <Modal
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"absolute m-auto h-fit w-fit border-0  border-1 rounded-2xl"+cl}
      >
        <Box className="light-gray-bg p-6 rounded-2xl">
          {children}
        </Box>
      </Modal>
    </>
  );
}

export function ConfirmationModal({ handleOpen, handleClose, children }) {
  return (
    <>
      <Modal
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute lg:w-[32%] lg:top-[18%] lg:left-[37%] top-[10%] left-[5%] bg-white bg-opacity-90 shadow-inner border  border-1 rounded-2xl p-5">
          {children}
        </Box>
      </Modal>
    </>
  );
}
