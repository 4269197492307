import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons";
import { Card } from "../../components/card";
import { AuthInput } from "../../components/form";
import logo from "../../assets/logo/coloredlogo-name.png";
import ToggleButton from "../../components/toggleButton";

import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";
import { Nav } from "../../layout/navbar";
import { useAuth } from "../../context/auth";
import Spinner from "../../components/spinner";
import { AdminLogin } from "../../api/auth-endpoints";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { LoginSchema } from "../../yup";

export const SignIn = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/dashboard";

  const [showPwd, setShowPwd] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const response = await AdminLogin(values);
        const res = response?.data;
        auth.login(res);
        navigate(redirectPath, { replace: true });
      } catch (err) {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      }
    },
  });

  const input = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      icon: <AiIcons.AiOutlineMail size="30" className="text-gray-500" />,
      required: true,
      goodSuffix: <FcIcons.FcCheckmark size={25} />,
    },
    {
      id: 2,
      name: "password",
      type: showPwd ? "text" : "password",
      placeholder: "Password",
      label: "Password",
      icon: <MdIcons.MdLockOutline size="30" className="text-gray-500" />,
      required: true,
      autoComplete: "off",
      forgotPwdSuffix: showPwd ? (
        <button type="button" onClick={() => setShowPwd(false)}>
          {" "}
          <AiIcons.AiOutlineEye size={25} />{" "}
        </button>
      ) : (
        <button type="button" onClick={() => setShowPwd(true)}>
          {" "}
          <AiIcons.AiOutlineEyeInvisible size={25} />{" "}
        </button>
      ),
    },
  ];

  return (
    <>
      <Nav />
      <div className="flex justify-center mt-20 my-auto">
        <Card>
          <div className="text-center">
            <h1 className="dark-blue-text text-2xl">Let's Sign You In</h1>
            <sub>Welcome back, you've been missed</sub>
          </div>

          <form onSubmit={formik.handleSubmit}>
            {formik.isSubmitting ? <Spinner /> : false}

            {input.map((item, index) => {
              return (
                <div key={index} className="pt-10">
                  <AuthInput
                    {...item}
                    // value={formik.values[item.name]}
                    defaultValue={formik.values[item.name]}
                    // onChange={onChange}
                    error={formik.errors[item?.name]}
                    touched={formik.touched[item?.name]}
                    onChange={formik.handleChange(item?.name)}
                    onBlur={formik.handleBlur(item?.name)}
                    disabled={formik.isSubmitting}
                    autoFocus={true}
                  />
                </div>
              );
            })}

            <div className="flex justify-between py-3 text-xs">
              {/* <span>
                <label htmlFor="toggle" className="text-xs text-gray-500 pr-2">
                  Remember Me
                </label>
                <ToggleButton />
              </span> */}
              {/* <span>
                                <Link exact="true" to="/forgot-pwd">Forgot Password?</Link>
                            </span> */}
            </div>

            <div className="pt-5">
              <Button type="submit">Sign In </Button>
            </div>
          </form>

          <div className="flex justify-end lg:hidden">
            <img src={logo} className="w-20" alt="logo" />
          </div>
        </Card>
      </div>
    </>
  );
};
