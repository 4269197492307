import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Card } from '../../components/card'
import { Button } from '../../components/buttons'
import { AuthInput } from '../../components/form'
import { Nav } from '../../layout/navbar';
import { Footer } from '../../layout/footer'
import logo from '../../assets/logo/coloredlogo-name.png';
import { Checkbox } from '@mui/material';
import { green } from '@mui/material/colors'
import * as AiIcons from 'react-icons/ai'
import * as MdIcons from 'react-icons/md'
import * as BsIcons from 'react-icons/bs';
import * as FcIcons from 'react-icons/fc'
import axios from '../../api/axios'
const REGISTER_URL = '/admin/register'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const EMAIL_REGEX = "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-zA-Z]{2,4}"
const PWD_REGEX = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,24}$"
const TEXT_REGEX = "[a-zA-Z-]{3,}"
const NUM_REGEX = "[0-9]{11,}"



export const SignUp = () => {
    const [step, setstate] = useState(true);
    const [values, setValues] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        lastName: '',
        firstName: '',
        phoneNumber: ''
    })

    const inputElement = {
        pageOne: [
            {
                id: 1,
                name: 'email',
                type: 'email',
                placeholder: 'Email',
                errorMessage: "Email is invalid",
                label: 'Email',
                icon: <AiIcons.AiOutlineMail size="30" className='text-gray-500' />,
                required: true,
                pattern: EMAIL_REGEX,
                goodSuffix: <FcIcons.FcCheckmark size={25} />
            },
            {
                id: 2,
                name: 'pwd',
                type: 'password',
                placeholder: 'Password',
                errorMessage: "Password is invalid",
                label: 'Password',
                icon: <MdIcons.MdLockOutline size="30" className='text-gray-500' />,
                required: true,
                pattern: PWD_REGEX,
                autoComplete: "off",
                autoFocus: 'off',
                forgotPwdSuffix: <AiIcons.AiOutlineEye size={30} />
            },
            {
                id: 3,
                name: 'confirmPassword',
                type: 'password',
                placeholder: 'Confirm Password',
                errorMessage: "Confirm Password does not match password",
                label: 'Confirm Password',
                icon: <MdIcons.MdLockOutline size="30" className='text-gray-500' />,
                required: true,
                pattern: values.pwd,
                autoComplete: "off",
                autoFocus: 'off',
                forgotPwdSuffix: <AiIcons.AiOutlineEye size={30} />
            }
        ],
        pageTwo: [
            {
                id: 4,
                label: 'Last Name',
                name: 'lastName',
                type: 'text',
                placeholder: 'Last Name',
                errorMessage: 'Last name is empty',
                icon: <AiIcons.AiOutlineUser size={30} className="text-gray-500" />,
                required: true,
                pattern: TEXT_REGEX,
                autoComplete: 'off'
            },
            {
                id: 5,
                label: 'First Name',
                name: 'firstName',
                type: 'text',
                placeholder: 'First Name',
                errorMessage: 'First name is empty',
                icon: <AiIcons.AiOutlineUser size={30} className="text-gray-500" />,
                required: true,
                pattern: TEXT_REGEX,
                autoComplete: 'off'
            },
            {
                id: 6,
                label: 'Phone Number',
                name: 'phoneNumber',
                type: 'text',
                placeholder: 'Phone Number',
                errorMessage: 'Phone number is empty',
                icon: <MdIcons.MdLocalPhone size={30} className="text-gray-500" />,
                required: true,
                pattern: NUM_REGEX,
                autoComplete: 'off',
                max: 11
            }
        ]
    }

    const handelStep = () => {
        setstate(!step)
    }

    // React.useEffect(() => {
    //     axios.interceptors.request.use(req => {
    //         // `req` is the Axios request config, so you can modify
    //         // the `headers`.
    //         req.headers.authorization = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTkxMjY2MzUsImV4cCI6MTY1OTEzMDIzNSwiYXVkIjoia29vcGF5LXNlcnZpY2VzIiwiaXNzIjoia29vcGF5LXRva2VuaXplciIsInN1YiI6IjYyZTNlNzI4ZjI1Zjk4ODMxOGE1ODcwNyJ9.Yze4M230Tao7aKZMEFHgaIz_mOTcSE04usDBOjLqCuM';
    //         return req;
    //     });
    // }, [])

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log({ ...values })
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTkxMjY2MzUsImV4cCI6MTY1OTEzMDIzNSwiYXVkIjoia29vcGF5LXNlcnZpY2VzIiwiaXNzIjoia29vcGF5LXRva2VuaXplciIsInN1YiI6IjYyZTNlNzI4ZjI1Zjk4ODMxOGE1ODcwNyJ9.Yze4M230Tao7aKZMEFHgaIz_mOTcSE04usDBOjLqCuM'
        const bodyParameter = {
            email: values.email,
            last_name: values.lastName,
            first_name: values.firstName,
            password: values.password,
            phone_number: values.phoneNumber,
            type: 'ADMIN'
        }
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        try {
            const response = await axios.post(REGISTER_URL, bodyParameter, config)

            console.log(response?.data)
            console.log(response?.accessToken)

        }
        catch (err) {
            if (!err?.response) {
                console.log("No server Response")
                // setErrMsg('No Server Response')
            } else if (err.response?.status === 400) {
                console.log("Missing Username or Password")
                // setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                console.log("Unauthorized")
                // setErrMsg('Unauthorized')
            } else if (err.response?.status === 403) {
                console.log("I dont know what happened")
                // setErrMsg('I dont know what happened')
            } else {
                console.log("Registration Failed")
                // setErrMsg('Login Failed');
            }
            // errRef.current.focus();
        }

    }

    return (
        <>
            <Nav />
            <div className='flex justify-center py-10'>
                <Card>
                    <div className='text-center'>
                        <h1 className='dark-blue-text text-2xl'>Getting Started</h1>
                        <sub>Create an account to continue!</sub>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {Object.keys(inputElement)?.map(page => {
                            return (
                                <div key={page}>
                                    {
                                        inputElement[page]?.map(item => {
                                            return (
                                                page === 'pageOne' ?
                                                    <div key={item.id} className={!step ? 'hidden ease-in-out transition delay-700 duration-300' : 'block ease-in-out transition delay-700 duration-300'}>
                                                        <div className='pt-10'>
                                                            <AuthInput {...item} value={values[item.name]} onChange={onChange} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div key={item.id} className={!step ? 'block ease-in-out transition delay-700 duration-300' : 'hidden ease-in-out transition delay-700 duration-300'}>
                                                        <div className='pt-10'>
                                                            <AuthInput {...item} value={values[item.name]} onChange={onChange} />
                                                        </div>
                                                    </div>

                                            )
                                        })

                                    }
                                </div>
                            )
                        })}


                        <div className={!step ? 'hidden ease-in-out transition delay-700 duration-300' : 'block ease-in-out transition delay-700 duration-300'}>
                            <div className='pt-5' onClick={handelStep}>
                                <Button>Next</Button>
                            </div>
                        </div>


                        <div className={!step ? 'block ease-in-out transition delay-700 duration-300' : 'hidden ease-in-out transition delay-700 duration-300'}>
                            <div className='flex pt-2'>
                                <Checkbox {...label} defaultChecked
                                    sx={{
                                        color: green[800],
                                        '&.Mui-checked': {
                                            color: green[600],
                                        },
                                    }}
                                />
                                <div >
                                    <sub className='text-xs text-gray-500'>By creating an account, you agree to our</sub> <br /> <NavLink to="" className="text-xs dark-blue-text">Terms and conditions</NavLink>
                                </div>
                                {/* <Checkbox id="terms" name="terms" /> <sub className='text-xs text-gray-500'>By creating an account, you agree to our</sub> <br/> <NavLink to="" className="text-xs dark-blue-text">Terms and conditions</NavLink> */}
                            </div>

                            <div className='pt-5'>
                                <Button type="submit">Sign Up</Button>
                            </div>
                        </div>
                    </form>

                    <div className={!step ? 'flex pt-5 justify-center' : 'hidden'} onClick={handelStep}>
                        <BsIcons.BsFillArrowLeftCircleFill size={25} className="light-orange-text cursor-pointer" />
                    </div>

                    <div className='text-center pt-3'>
                        <sub>Already have an account? <NavLink to="/sign-in" className='dark-blue-text font-black'>Sign in</NavLink></sub>
                    </div>

                    <div className='flex mt-8 justify-end lg:hidden'>
                        <img src={logo} className="w-20" alt="logo" />
                    </div>

                </Card>
            </div>

            <Footer />
        </>
    )
}

