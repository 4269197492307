import { Typography } from '@mui/material'
import React from 'react'
import axios from '../../api/axios'
import { GlassCard } from '../../components/card'
import { GlassModal } from '../../components/modal'
import { useAuth } from '../../context/auth'
import logo from '../../assets/logo/coloredlogo.png'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as RiIcons from 'react-icons/ri'

const AUDIT_LIST = '/audit'
const GET_USER_BY_ID = '/admin/user-by-id?id='

export const AuditLogs = () => {
    const auth = useAuth()
    const token = auth.token
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    const [auditList, setAuditList] = React.useState()
    const [pageCount, setPageCount] = React.useState(0);
    const [totalFilteredData, setTotalFilteredData] = React.useState(0);

    React.useEffect(() => {
        return async () => {
            try {
                const response = await axios({
                    method: "get",
                    url: AUDIT_LIST,
                    headers: { Authorization: `Bearer ${token}` }
                })
                setAuditList(response?.data.data.page_data)
                setPageCount(response?.data.data.page_count)
                setTotalFilteredData(response?.data.data.total_filtered_data)
                // console.log(response?.data.data)
            }
            catch (err) {
                if (err.response?.status === 401) {
                    auth.logout()
                } else {
                    console.log(err.response.data)
                }
            }
        };
    }, [])

    const [logDetailsModal, setLogDetailsModal] = React.useState(false);
    const [selectedLog, setSelectedLog] = React.useState(false);
    const handleOpen = async (data) => {
        setSelectedLog(data)
        setLogDetailsModal(true)
        try {
            const response = await axios({
                method: "get",
                url: GET_USER_BY_ID + data.action_by,
                ...config
            })
            console(response?.data.data.page_data)
        } catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                console.log(err.response.data)
            }
        }
    };
    const handleClose = () => setLogDetailsModal(false);

    const onChangePage = async (value) => {
        try {
            const response = await axios({
                method: "get",
                url: AUDIT_LIST + '?page=' + value,
                ...config
            })
            setAuditList(response?.data.data.page_data)
            setPageCount(response?.data.data.page_count)
            setTotalFilteredData(response?.data.data.total_filtered_data)
            // console.log(response?.data.data)
        } catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                console.log(err.response.data)
            }
        }
    }

    const searchByAction = async (e) => {
        try {
            if (e.keyCode === 13 && e.target.value.length > 0) {
            const response = await axios({
                method: 'get',
                url: AUDIT_LIST + '?action=' + e.target.value,
                ...config
            })
            setAuditList(response?.data.data.page_data)
            setPageCount(response?.data.data.page_count)
            setTotalFilteredData(response?.data.data.total_filtered_data)
        }else{
            const response = await axios({
                method: 'get',
                url: AUDIT_LIST,
                ...config
            })
            setAuditList(response?.data.data.page_data)
            setPageCount(response?.data.data.page_count)
            setTotalFilteredData(response?.data.data.total_filtered_data)
        }
        }
        catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                // toast(err.response?.data.message)
                console.log(err.response.data)
            }
        }

    }

    return (
        <>
            <GlassModal handleOpen={logDetailsModal} handleClose={handleClose}>
                <Typography id="modal-modal-description" variant="span" component="span" sx={{ mt: 2 }} className="flex flex-col space-y-6">
                    <div className='flex flex-col min-h-[300px] m-4 bg-slate-100 rounded-xl px-4 pt-4 pb-20 relative'>
                        <strong className='dark-blue-text font-semibold text-sm text-center mb-6'>Complete Details of this Activity</strong>
                        <ul className='space-y-5'>
                            <li className='flex justify-between'>
                                <p>Action By:</p>
                                <p>{selectedLog.action_by}</p>
                            </li>
                            <li className='flex justify-between'>
                                <p>Action:</p>
                                <p>{selectedLog.action}</p>
                            </li>
                            <li className='flex justify-between'>
                                <p>Activity:</p>
                                <p>{selectedLog?.message}</p>
                            </li>
                            <li className='flex justify-between'>
                                <p>Timestamp:</p>
                                <p>{selectedLog.created_at}</p>
                            </li>
                        </ul>
                        <div className='flex justify-end absolute bottom-0 right-2'>
                            <img src={logo} className="w-16" alt="logo" />
                        </div>
                    </div>
                </Typography>
            </GlassModal>

            <GlassCard>
                <div className='flex lg:flex-row flex-col-reverse lg:justify-between mb-2'>
                    <span className='flex lg:mt-0 mt-4'>
                        <div className="relative">
                            <div className="absolute pointer-events-auto py-1 px-2">
                                <RiIcons.RiSearch2Line size={18} />
                            </div>
                            <input type="text" placeholder="Search by action" onKeyDown={searchByAction} className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0" />
                        </div>
                    </span>
                    <span>
                        Filtered Data: {totalFilteredData}
                    </span>
                </div>
                <div className='flex'>
                    <ul>
                        <li className='grid grid-cols-3 gap-2 w-full bg-white rounded-lg mb-1 p-3'>
                            <strong>Time</strong>
                            <strong>Action</strong>
                            <strong>Activity</strong>
                        </li>
                        {auditList?.map((item, index) => {
                            return (
                                <li key={index} onClick={() => handleOpen(item)} className='grid grid-cols-3 gap-2 w-full cursor-pointer bg-white rounded-lg mb-2 p-3'>
                                    <span>
                                        {item.created_at}
                                    </span>
                                    <span className='truncate'>
                                        {item.action}
                                    </span>
                                    <span className='truncate'>
                                        {item.message}
                                    </span>
                                </li>
                            )
                        })}
                        <li className="flex justify-center">
                            <Stack spacing={2}>
                                <Pagination count={pageCount} shape="rounded" onChange={(e, value) => onChangePage(value)} />
                            </Stack>
                        </li>
                    </ul>
                </div>
            </GlassCard>
        </>
    )
}
