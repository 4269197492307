export const TRANSACTION_TYPE = [
    {
        id: 1,
        title: 'Empowerment',
        type: 'EMPOWERMENT'
    },
    {
        id: 2,
        title: 'Salary',
        type: 'SALARY'
    }, {
        id: 3,
        title: 'Refund',
        type: 'REFUND'
    }
]

export const WALLET_TYPE = [
    {
        id: 1,
        title: 'Ledger Balance',
        type: 'LEDGER_BALANCE'
    },
    {
        id: 2,
        title: 'Available Balance',
        type: 'AVAILABLE_BALANCE'
    },
]

export const FILTER_BY_STATUS = [
    {
        id: 1,
        title: 'Success',
        type: 'SUCCESS',
        filterProp: 'status='
    },
    {
        id: 2,
        title: 'Pending',
        type: 'PENDING',
        filterProp: 'status='
    },
    {
        id: 3,
        title: 'Failed',
        type: 'FAILED',
        filterProp: 'status='
    },
    {
        id: 4,
        title: 'Abandoned',
        type: 'ABANDONED',
        filterProp: 'status='
    }
]

export const FILTER_BY_TYPE = [
    {
        id: 1,
        title: 'Transfer',
        type: 'TRANSFER',
        filterProp: 'type='
    },
    {
        id: 2,
        title: 'In App Transfer',
        type: 'IN_APP_TRANSFER',
        filterProp: 'type='
    },
    {
        id: 3,
        title: 'Empowerment',
        type: 'EMPOWERMENT',
        filterProp: 'type='
    },
    {
        id: 4,
        title: 'Salary',
        type: 'SALARY',
        filterProp: 'type='
    },
    {
        id: 5,
        title: 'Airtime',
        type: 'AIRTIME',
        filterProp: 'type='
    },
    {
        id: 6,
        title: 'Utility',
        type: 'UTILITY',
        filterProp: 'type='
    },
    {
        id: 7,
        title: 'Top Up',
        type: 'TOP_UP',
        filterProp: 'type='
    },
    {
        id: 8,
        title: 'Data',
        type: 'DATA',
        filterProp: 'type='
    },
    {
        id: 9,
        title: 'Electricity',
        type: 'ELECTRICITY',
        filterProp: 'type='
    },
    {
        id: 10,
        title: 'Tv Renewal',
        type: 'TV_RENEWAL',
        filterProp: 'type='
    },
    {
        id: 11,
        title: 'Tv Change',
        type: 'TV_CHANGE',
        filterProp: 'type='
    },
    {
        id: 12,
        title: 'Referral',
        type: 'REFERRAL',
        filterProp: 'type='
    },
]

export const TRANSACTION_SUMMARY = [
    {
        id: 1,
        title: 'Overall Summary',
        type: 'OVERALL'
    },
    {
        id: 2,
        title: 'Success Summary',
        type: 'SUCCESS'
    }, {
        id: 3,
        title: 'Pending Summary',
        type: 'PENDING'
    },
    {
        id: 4,
        title: 'Failed Summary',
        type: 'FAILED'
    },
    {
        id: 5,
        title: 'Abandoned Summary',
        type: 'ABANDONED'
    }
]