export const FEED_CATEGORY = [
    {
        id: 1,
        title: 'Security',
        type: 'SECURITY',
    },
    {
        id: 2,
        title: 'Health',
        type: 'HEALTH',
    },
    {
        id: 3,
        title: 'Economy',
        type: 'ECONOMY',
    }
]

export const FILTER_BY_STATUS = [
    {
        id: 1,
        title: 'Published',
        type: 'PUBLISHED',
        filterProp: 'status='
    },
    {
        id: 2,
        title: 'Archived',
        type: 'ARCHIVED',
        filterProp: 'status='
    },
    {
        id: 3,
        title: 'Pending',
        type: 'PENDING',
        filterProp: 'status='
    }
]