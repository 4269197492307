import React from "react";
import { Avatar } from "../../components/avatar";
import { Input, Select } from "../../components/form";
import * as MdIcons from "react-icons/md";
import { FileUploader } from "../../components/fileUploader";
import { Button } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import * as RiIcons from "react-icons/ri";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import logo from "../../assets/logo/coloredlogo.png";

const REGISTER_URL = "/admin/register";

const EMAIL_REGEX = "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-zA-Z]{2,4}";
const TEXT_REGEX = "[a-zA-Z-]{3,}";
const NUM_REGEX = "[0-9]{11,}";

export const Profile = () => {
  const auth = useAuth();
  const token = auth.token;

  const [values, setValues] = React.useState({
    email: auth.user.profile?.email,
    lastName: auth.user.profile?.last_name,
    firstName: auth.user.profile?.first_name,
    phoneNumber: auth.user.profile?.phone_number,
    type: auth.user.profile?.type,
  });

  const inputElement = {
    authdata: [
      {
        id: 1,
        name: "email",
        type: "email",
        placeholder: auth.user.profile?.email,
        errorMessage: "Email is invalid",
        label: "Email",
        icon: <AiIcons.AiOutlineMail size="25" className="text-gray-500" />,
        required: true,
        pattern: EMAIL_REGEX,
        goodSuffix: <FcIcons.FcCheckmark size={25} />,
        disabled: "disabled",
      },
      {
        id: 2,
        label: "Last Name",
        name: "lastName",
        type: "text",
        placeholder: auth.user.profile?.last_name,
        errorMessage: "Last name is empty",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        required: true,
        pattern: TEXT_REGEX,
        autoComplete: "off",
        disabled: "disabled",
      },
      {
        id: 3,
        label: "First Name",
        name: "firstName",
        type: "text",
        placeholder: auth.user.profile?.first_name,
        errorMessage: "First name is empty",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        required: true,
        pattern: TEXT_REGEX,
        autoComplete: "off",
        disabled: "disabled",
      },
      {
        id: 4,
        label: "Phone Number",
        name: "phoneNumber",
        type: "text",
        placeholder: auth.user.profile?.phone_number,
        errorMessage: "Phone number is empty",
        icon: <MdIcons.MdLocalPhone size={25} className="text-gray-500" />,
        required: true,
        pattern: NUM_REGEX,
        autoComplete: "off",
        max: 11,
        disabled: "disabled",
      },
      {
        id: 5,
        name: "type",
        type: "text",
        placeholder: auth.user.profile?.type,
        errorMessage: "Type is invalid",
        label: "User Type",
        icon: (
          <RiIcons.RiUserSettingsLine size={25} className="text-gray-500" />
        ),
        required: true,
        pattern: TEXT_REGEX,
        disabled: "disabled",
      },
    ],
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyParameter = {
      email: values.email,
      last_name: values.lastName,
      first_name: values.firstName,
      phone_number: values.phoneNumber,
      type: values.type,
    };
    console.log(bodyParameter)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    try {
      const response = await axios.post(REGISTER_URL, bodyParameter, config)
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-between">
          <div>
            <h1 className="dark-blue-text text-lg">Personal Info</h1>
          </div>
          {/* <div className="flex justify-end">
            <div className="w-20 ">
              <button className="bg-[#f6f6f6] rounded-lg p-2">Cancel</button>
            </div>
            <div className="w-40">
              <Button type="button" onClick={(e)=>handleSubmit(e)}>Update Biodata</Button>
            </div>
          </div> */}
        </div>
        <hr className="mt-2 " />

        <form className="relative">
          <div className="p-8">
            <div className="flex lg:flex-row flex-col lg:space-x-4">
              <div className="flex-1 w-full space-y-4">
                <div>
                  <h1>Profile photo</h1>
                </div>
                <div className="relative">
                  <Avatar
                    cl="inline-block h-[250px] w-[250px] rounded-full ring-1 ring-[#f98c19]"
                    src={auth.user.profile?.profile_picture || logo}
                  />
                  <FileUploader
                    cl="absolute top-48 left-48 rounded-lg p-1 bg-[#f98c19]"
                    icon={<MdIcons.MdEdit size={20} color="white" />}
                  />
                </div>
              </div>

              <div className="flex-1 w-full space-y-4">
                {Object.keys(inputElement)?.map((page) => {
                  return (
                    <div key={page}>
                      {inputElement[page]?.map((item) => {
                        return page === "authdata" ? (
                          <div key={item.id} className="mb-6">
                            <Input
                              {...item}
                              value={values[item.name]}
                              onChange={onChange}
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </form>
        {/* <div className='p-8'>
                    <h1 className='dark-blue-text text-lg'>Last Session</h1>

                </div> */}
      </GlassCard>
    </>
  );
};
