import React, { useEffect, useState } from "react";
import Icon, { Icons } from "./Icons";

const DetectNetworkConnectivity = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [displayOnlineDiv, setDisplayOnlineDiv] = useState(true);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
      setDisplayOnlineDiv(true);
    }

    if (navigator.onLine === true) {
      setTimeout(() => {
        setDisplayOnlineDiv(false);
      }, 2000);
    }

    function offlineHandler() {
      setIsOnline(false);
      setDisplayOnlineDiv(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, [displayOnlineDiv]);
  return (
    <>
      {isOnline ? (
        <div
          className={
            displayOnlineDiv ? "absolute top-0 z-[100] w-full h-20" : "hidden"
          }
        >
          <div className="flex bg-green-500 h-full items-center space-x-2 px-5">
            <Icon typeName={Icons.BiIcons.BiWifi} size={36} color={"#fff"} />
            <p>Hurray! You're online now. </p>
          </div>
        </div>
      ) : (
        <div className="absolute top-0 z-[100] w-full h-20">
          <div className="flex red-bg h-full items-center space-x-2 px-5">
            <Icon typeName={Icons.BiIcons.BiWifiOff} size={36} color={"#fff"} />
            <p>
              Opps! You're offline now. Please check your internet connection.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default DetectNetworkConnectivity;
