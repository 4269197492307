import React from "react";
// import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

export const ExportExcel = ({ excelData, fileName }) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = (fileName) => {
    // const ws = XLSX.utils.json_to_sheet(excelData);
    // const wb = { Sheets: { data: ws }, sheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);

    /* pass here the json data */
    const ws = XLSX.utils.json_to_sheet(excelData);
    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, fileName+fileExtension);
    
  };

  return (
    <>
      <button className="flex w-full" onClick={(e) => exportToExcel(fileName)}>Export To Excel</button>
    </>
  );
};

export const ExportFunctionToExcel = (fileName, excelData) => {
  // const ws = XLSX.utils.json_to_sheet(excelData);
  // const wb = { Sheets: { data: ws }, sheetNames: ["data"] };
  // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  // const data = new Blob([excelBuffer], { type: fileType });
  // FileSaver.saveAs(data, fileName + fileExtension);

  const fileExtension = ".xlsx";

  /* pass here the json data */
  const ws = XLSX.utils.json_to_sheet(excelData);
  /* generate workbook and add the worksheet */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  /* save to file */
  XLSX.writeFile(wb, fileName+fileExtension);
  
};