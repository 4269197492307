export const SECTOR_TYPE = [
    {
        id: 1,
        title: 'Ministries Department & Agencies',
        abbreviation: 'MDAS'
    },
    {
        id: 2,
        title: 'Kogi State University, Anyigba',
        abbreviation: 'KSU'
    },
    {
        id: 3,
        title: 'College of Education, Ankpa',
        abbreviation: 'COE_ANKPA'
    },
    {
        id: 4,
        title: 'College of Health Science and Technology, Idah',
        abbreviation: 'CHT_IDAH'
    },
    {
        id: 5,
        title: 'Kogi State University Teaching Hospital, Anyigba',
        abbreviation: 'KSUTH'
    },
    {
        id: 6,
        title: 'College of Nursing, Obangede',
        abbreviation: 'CON_OBANGEDE'
    },
    {
        id: 7,
        title: 'College of Education (Technical), Kabba',
        abbreviation: 'COE_KABBA'
    },
    {
        id: 8,
        title: 'Kogi State Specialist Hospital, Lokoja',
        abbreviation: 'KSSH'
    },
    {
        id: 9,
        title: 'Polytechnic, Lokoja',
        abbreviation: 'POLY'
    },
    {
        id: 10,
        title: 'Confluence University of Science and Technology, Osara',
        abbreviation: 'CUSTECH'
    },
    {
        id: 11,
        title: 'Political Office Holder',
        abbreviation: 'POH'
    },
    {
        id: 12,
        title: 'Political Office Holder Constituency',
        abbreviation: 'POH_CONST'
    },
    {
        id: 13,
        title: 'Traditional Rulers',
        abbreviation: 'TRAD_RULERS'
    },
    {
        id: 14,
        title: 'Vigilante',
        abbreviation: 'VIGILANTE'
    },    
    {
        id: 15,
        title: 'Pension',
        abbreviation: 'PENSION'
    }

]

export const USER_TYPE = [
    {
        id: 1,
        title: 'All Users',
        type: 'ALL',
    },
    {
        id: 2,
        title: 'Staff',
        type: 'STAFF',
    },
    {
        id: 3,
        title: 'Member',
        type: 'MEMBER',
    },
    {
        id: 4,
        title: 'Pension',
        type: 'PENSION',
    }
]