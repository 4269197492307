import React from "react";
import { GlassCard } from "../../components/card";
import logo from "../../assets/logo/coloredlogo.png";
import * as RiIcons from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../components/badge";
import * as BsIcons from "react-icons/bs";
import { RadioButton, Textarea } from "../../components/form";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { TableSpinner } from "../../components/spinner";

// const chatList = [
//   {
//     id: 1,
//     image: logo,
//     message:
//       "Error message prompt while trying to withdrawError message prompt while trying to withdrawError message prompt while trying to withdraw",
//     timestamp: "1 min ago",
//     sender: "Hassan Abubakar",
//     from: "user",
//   },
//   {
//     id: 2,
//     image: logo,
//     message: "Error message prompt while trying to withdraw",
//     timestamp: "just now",
//     sender: "Akinbode Abdulrazaq",
//     from: "admin",
//   },
//   {
//     id: 3,
//     image: logo,
//     message:
//       "Error message prompt while trying to withdrawError message prompt while trying to withdrawError message prompt while trying to withdraw",
//     timestamp: "1 min ago",
//     sender: "Hassan Abubakar",
//     from: "user",
//   },
//   {
//     id: 4,
//     image: logo,
//     message: "Error message prompt while trying to withdraw",
//     timestamp: "just now",
//     sender: "Akinbode Abdulrazaq",
//     from: "admin",
//   },
//   {
//     id: 5,
//     image: logo,
//     message:
//       "Error message prompt while trying to withdrawError message prompt while trying to withdrawError message prompt while trying to withdraw",
//     timestamp: "1 min ago",
//     sender: "Hassan Abubakar",
//     from: "user",
//   },
//   {
//     id: 6,
//     image: logo,
//     message: "Error message prompt while trying to withdraw",
//     timestamp: "just now",
//     sender: "Akinbode Abdulrazaq",
//     from: "admin",
//   },
// ];

const UPDATE_SUPPORT_STATUS = "/admin/update-support-status";
const GET_ADMIN_BY_ID = "/admin/admin-by-id?id=";

const STATUS_LIST = [
  {
    id: 1,
    title: "Pending",
    type: "PENDING",
  },
  {
    id: 2,
    title: "Peocessing",
    type: "PROCESSING",
  },
  {
    id: 3,
    title: "Resolved",
    type: "RESOLVED",
  },
];

export const ChatBox = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  
  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    return async () => {
      await axios
        .get(GET_ADMIN_BY_ID + data.status.updated_by, config)
        .then((response) => {
          const res = response?.data?.data;
          // console.log(res)
          setAdminDetails(res);
        });
    };
  }, []);

  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [commentValues, setCommentValues] = React.useState({ comment: "" });
  const [statusValues, setStatusValues] = React.useState({ status: "PENDING" });

  const onChangeComment = (e) => {
    setCommentValues({ ...commentValues, [e.target.name]: e.target.value });
  };

  const onChangeStatus = (e) => {
    setStatusValues({ ...statusValues, [e.target.name]: e.target.value });
  };

  const [adminDetails, setAdminDetails] = React.useState();


  const changeTicketStatus = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const dataBody = {
      id: data._id,
      status: statusValues.status,
      comment: commentValues.comment,
    };

    try {
      const response = await axios({
        method: "put",
        url: UPDATE_SUPPORT_STATUS,
        data: dataBody,
        ...config,
      });
      navigate(-1);
      toast.success(response.data.message);
      setSubmitLoader(false);
      // window.location.reload();
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err?.response.data);
        toast.error(err?.response.data.message);
      }
    }
  };

  return (
    <>
      <GlassCard>
        <div className="flex">
          <span className="cursor-pointer" onClick={() => navigate(-1)}>
            <BsIcons.BsFillCaretLeftFill size={30} />
          </span>
          <strong className={adminDetails ? "capitalize" : "hidden"}>
            Updated by:{" "}
            {adminDetails?.last_name + " " + adminDetails?.first_name}
          </strong>
        </div>
        <div className="flex mb-2 p-2">
          <article className="flex w-full justify-between">
            <header className="text-sm dark-blue-text font-black">
              <p>{data?.description}</p>
              <sub>ID: {data?.ticket_id}</sub>
            </header>
            <p className="flex space-x-3 h-min">
              <Badge
                cl={
                  data?.status?.status === "PENDING"
                    ? "light-orange-bg rounded-full py-1 px-3 w-fit text-xs"
                    : [
                        data?.status?.status === "PROCESSING"
                          ? "bg-blue-300 rounded-full py-1 px-3 w-fit text-xs"
                          : "bg-green-500 rounded-full py-1 px-3 w-fit text-xs text-white",
                      ]
                }
                content={data?.status?.status}
              />
              <Badge
                cl={
                  "light-blue-bg rounded-full py-1 px-3 w-fit text-xs text-white"
                }
                content={data?.type}
              />
            </p>
          </article>
        </div>

        {/* <ul className="w-full h-[500px] min-h-0 bg-[#f6f6f6] rounded-lg px-4 py-10 mb-2 drop-shadow-lg shadow-inner space-x-2 space-y-8 overflow-y-auto">
          {chatList.map((item, index) => {
            return (
              <div key={index}>
                {item.from === "user" ? (
                  <li className="flex space-x-3">
                    <img
                      src={item.image}
                      className="rounded-full w-10 h-10 bg-gray-300 "
                      alt="img"
                    />
                    <div className="flex flex-col w-[70%]">
                      <span className="dark-blue-bg h-min p-3 rounded-r-xl rounded-tl-xl text-sm">
                        {item.message}
                      </span>
                      <sub className="dark-blue-text text-xs w-full text-start">
                        {item.sender} (Customer) - <time>{item.timestamp}</time>
                      </sub>
                    </div>
                  </li>
                ) : (
                  <li className="flex justify-end space-x-3">
                    <div className="flex flex-col w-[70%]">
                      <span className="dark-gray-bg h-min p-3 rounded-l-xl rounded-tr-xl text-sm">
                        {item.message}
                      </span>
                      <sub className="dark-blue-text text-xs w-full text-end">
                        {item.sender} (Agent) - <time>{item.timestamp}</time>
                      </sub>
                    </div>
                    <img
                      src={item.image}
                      className="rounded-full w-10 h-10 bg-gray-300 "
                      alt="img"
                    />
                  </li>
                )}
              </div>
            );
          })}
        </ul> */}

        <form onSubmit={changeTicketStatus} className="relative">
          {submitLoader ? <TableSpinner /> : null}
          <div className="mb-2">
            {STATUS_LIST.map((item, index) => {
              return (
                <>
                  <RadioButton
                    key={index}
                    label={item.type}
                    value={item.type}
                    id=""
                    name="status"
                    onChange={onChangeStatus}
                    // checked={(statusValues.status === 'PENDING' && item.type === 'PENDING')? true:false}
                    required
                  />{" "}
                  &nbsp;
                </>
              );
            })}
          </div>
          <Textarea
            label=""
            name="comment"
            onChange={onChangeComment}
            placeholder={data?.status?.comment ? data?.status?.comment:"Comment"}
            required
          >
            <div className="absolute bottom-3 right-3 space-x-3">
              {/* <button
              type="button"
              className="bg-transaparent drop-shadow-5xl shadow-inner rounded-md px-2 text-sm border border-orange-400"
            >
              <span className="flex my-auto text-sm light-orange-text">
                Attach{" "}
                <RiIcons.RiAttachment2
                  className="my-auto"
                  color="orange"
                  size={12}
                />
              </span>
            </button> */}

              <button
                type="submit"
                // onClick={changeTicketStatus}
                className="bg-gradient-to-t from-[#c05e00] via-[#c05e00] to-[#f98c19] text-[#f6f6f6] drop-shadow-lg rounded-md px-2 text-sm"
              >
                Send
              </button>
            </div>
          </Textarea>
        </form>
      </GlassCard>
    </>
  );
};
