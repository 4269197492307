import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  password: Yup.string()
    .min(6, "The password is too short")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    .required("Password is required!"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  password: Yup.string()
    .min(6, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required!"),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required!"),
  password: Yup.string()
    .min(6, "The password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_\-+=~])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required!"),
});

export const AddUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  last_name: Yup.string()
    .min(3, "Last Name cannot be less than 3 letters")
    .required("Last Name is required!"),
  first_name: Yup.string()
    .min(3, "First Name cannot be less than 3 letters")
    .required("First Name is required!"),
  phone_number: Yup.string().required("Phone Number is required!"),
  zone: Yup.string().required("Zone is required!"),
  state: Yup.string().required("State is required!"),
  senatorial_district: Yup.string().required(
    "Senatorial district is required!"
  ),
  local_government: Yup.string().required("LGA is required!"),
  ward: Yup.string().required("Ward is required!"),
  polling_unit: Yup.string().required("Polling Unit is required!"),
  user_type: Yup.string().required("User type is required!"),

  payee: Yup.string().optional("Payee is required!"),
  account_number: Yup.string().optional("Account Number is required!"),
  bank_name: Yup.string().optional("Bank name is required!"),
  payable_amount: Yup.number()
    .positive()
    .integer()
    .optional("Payable Amount is required!"),
  grade_level: Yup.string().optional("Grade level is required!"),
});

export const EditUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  last_name: Yup.string()
    .min(3, "Last Name cannot be less than 3 letters")
    .required("Last Name is required!"),
  first_name: Yup.string()
    .min(3, "First Name cannot be less than 3 letters")
    .required("First Name is required!"),
  phone_number: Yup.string().required("Phone Number is required!"),
  zone: Yup.string().required("Zone is required!"),
  state: Yup.string().required("State is required!"),
  senatorial_district: Yup.string().required(
    "Senatorial district is required!"
  ),
  local_government: Yup.string().required("LGA is required!"),
  ward: Yup.string().required("Ward is required!"),
  polling_unit: Yup.string().required("Polling Unit is required!"),
  user_type: Yup.string().required("User type is required!"),

  payee: Yup.string().optional("Payee is required!"),
  account_number: Yup.string().optional("Account Number is required!"),
  bank_name: Yup.string().optional("Bank name is required!"),
  payable_amount: Yup.number()
    .positive()
    .integer()
    .optional("Payable Amount is required!"),
  grade_level: Yup.string().optional("Grade level is required!"),
});

export const SalaryInfoSchema = Yup.object().shape({
  salary: Yup.number().positive().integer().required("Salary is required!"),
  earAllowance: Yup.number()
    .positive()
    .integer()
    .required("Earn Allowance is required!"),
  hmo: Yup.number().positive().integer().required("HMO is required!"),
  pit: Yup.number().positive().integer().required("PIT is required!"),
  pension: Yup.number().positive().integer().required("Pension is required!"),
  lifeInsurance: Yup.number()
    .positive()
    .integer()
    .required("Life Insuarance is required!"),
});

export const AddAdminSchema = Yup.object().shape({
  email: Yup.string()
    .email("invalid email is required!")
    .required("Email is required!"),
  last_name: Yup.string().required("Last Name is required!"),
  first_name: Yup.string().required("First Name is required!"),
  phone_number: Yup.number()
    .positive()
    .integer()
    .required("Phone Number is required!"),
  type: Yup.string().required("Type is required!"),
});

export const PaySalarySchema = Yup.object().shape({
  fundingType: Yup.string().required("Funding Type is required!"),
  transactionCategory: Yup.string().required(
    "Transaction Category is required!"
  ),
  idempotentKey: Yup.string().required("Idempotent Key is required!"),
  purpose: Yup.string().required("Narration is required!"),
  account: Yup.string().required("Account Type is required!"),
  groupFunding: Yup.object().shape({
    portfolio: Yup.string().required("Portfolio is required!"),
  }),
});

export const GroupFundSchema = Yup.object().shape({
  user_type: Yup.string().required("User Type is required!"),
  transaction_type: Yup.string().required("Transaction Type is required!"),
  narration: Yup.string().required("Narration is required!"),
  wallet_type: Yup.string().required("Wallet Type is required!"),
  amount: Yup.number().positive().integer().required("Amount is required!"),
  state: Yup.string().required("State is required!"),
  local_government: Yup.string().required("LGA is required!"),
  ward: Yup.string().required("Ward is required!"),
  polling_unit: Yup.string().required("Polling Unit is required!"),
});

export const FundSingleUserSchema = Yup.object().shape({
  wallet_code: Yup.string().required("Wallet Code is required!"),
  wallet_type: Yup.string().required("Wallet Type is required!"),
  transaction_type: Yup.string().required("Transaction Type is required!"),
  amount: Yup.number().positive().integer().required("Amount is required!"),
  narration: Yup.string().required("Narration is required!"),
  idempotentKey: Yup.string().optional(),
});

export const BulkFundingSchema = Yup.object().shape({
  transaction_type: Yup.string().required("Transaction Type is required!"),
  narration: Yup.string().required("Narration Type is required!"),
  wallet_type: Yup.string().required("Account Type is required!"),
  members: Yup.array().of(
    Yup.object().shape({
      wallet_code: Yup.string().required("Wallet Code is required!"),
      amount: Yup.number().positive().integer().required("Amount is required!"),
    })
  ),
});

export const CreateHandleSchema = Yup.object().shape({
  handleName: Yup.string().required("Handle Name is required!"),
  handleLocation: Yup.string().required("Handle Location is required!"),
  handleDescription: Yup.string().required("Handle Description is required!"),
  handleCategory: Yup.string().required("Category is required!"),
});

export const CreateShopSchema = Yup.object().shape({
  shopName: Yup.string().required("Shop Name is required!"),
  shopLocation: Yup.string().required("Shop Location is required!"),
  shopDescription: Yup.string().required("Shop Description is required!"),
  shopCategory: Yup.string().required("Shop category is required!"),
});

export const AddServiceSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required!"),
  type: Yup.string().required("Service Type is required!"),
  location: Yup.string(),
  description: Yup.string().required("Service Description is required!"),
  price: Yup.string(),
});

export const EditHandleSchema = Yup.object().shape({
  handleName: Yup.string().required("Handle Name is required!"),
  handleCategory: Yup.string().required("Handle Category is required!"),
});

export const SmsSchema = Yup.object().shape({
  message: Yup.string().required("Message is required!"),
  // type: Yup.string().required("Type is required!"),
  // receiver: Yup.array().of(Yup.string().required("Receipient is required!")),
  receiver: Yup.string().required("Receipient is required!"),
  type: Yup.string().required("Type is required!"),
  version: Yup.number().required("Version is required!"),
});

export const NotificationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required!"),
  title: Yup.string().required("Title is required!"),
  username: Yup.string().required("Username is required!"),
});

export const FeedsSchema = Yup.object().shape({
  reference: Yup.string().required("Reference is required!"),
  title: Yup.string().required("Title is required!"),
  content: Yup.string().required("Content is required!"),
  category: Yup.string().required("Category is required!"),
  author_name: Yup.string().required("Author Name is required!"),
});
