import React from "react";
import { GlassCard } from "../../components/card";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { Button } from "../../components/buttons";
import { Input, Select, Textarea } from "../../components/form";
import * as MdIcons from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { REGEX } from "../../enum/regex";
import { FEED_CATEGORY } from "../../enum/feeds-enum";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableSpinner } from "../../components/spinner";

const CREATE_FEEDS = "/admin/create-feed";

let previewImg = [];
let formData = new FormData();

export const AddFeeds = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const initialInputValues = {
    title: "",
    content: "",
    category: "",
    author_name: "",
  };
  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [imgFiles, setImageFiles] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [inputValues, setInputValues] = React.useState(initialInputValues);

  const inputElement = [
    {
      id: 1,
      name: "title",
      type: "tet",
      placeholder: "Title",
      // errorMessage: "Title is invalid",
      label: "Title",
      required: true,
      pattern: REGEX.NAME_REGEX,
      autoFocus: "on",
    },
    {
      id: 2,
      label: "Author Name",
      name: "author_name",
      type: "text",
      placeholder: "Author Name",
      // errorMessage: 'Author name is empty',
      required: true,
      pattern: REGEX.NAME_REGEX,
      autoComplete: "off",
    },
    {
      id: 3,
      label: "Content",
      name: "content",
      type: "text",
      placeholder: "Content",
      // errorMessage: 'Content is empty',
      required: true,
      pattern: REGEX.NAME_REGEX,
      autoComplete: "off",
    },
    {
      id: 4,
      label: "Feed Category",
      name: "category",
      type: "text",
      placeholder: "Category Name",
      // errorMessage: 'Category is empty',
      required: true,
      pattern: REGEX.NAME_REGEX,
      autoComplete: "off",
    },
  ];

  const handleFileInput = React.useRef(null);
  const handleClick = () => {
    handleFileInput.current.click();
  };

  const handleChange = async (event) => {
    const selectedFile = event.target.files;
    const filename = event.target.files[0]?.name;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    let extension = filename.split(".").pop();
    // let formData = new FormData();

    if (allowedExtensions.includes(extension)) {
      if (selectedFile.length <= 10 && previewImg.length <= 10) {
        for (let i = 0; i < selectedFile.length; i++) {
          formData.append("media", selectedFile[i]);
          // setImageFiles([URL.createObjectURL(selectedFile[i])])
          previewImg.push(URL.createObjectURL(selectedFile[i]));
        }
      } else {
        formData = new FormData();
        event.target.value = "";
        previewImg = [];
        toast.error("You can only upload 1 to 10 files");
      }
      setImageFiles(previewImg);
    } else {
      toast.error(
        "Invalid file type, only accepts:" +
          allowedExtensions[0] +
          ", " +
          allowedExtensions[1] +
          ", " +
          allowedExtensions[2] +
          ", " +
          allowedExtensions[3]
      );
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    if (
      inputValues.title.length > 0 &&
      inputValues.author_name.length > 0 &&
      inputValues.category.length > 0 &&
      inputValues.content.length > 0
    ) {
      createFeeds();
    } else {
      toast.error("All fields are required");
      setSubmitLoader(false);
    }
  };

  const createFeeds = async () => {
    try {
      const res = await axios({
        method: "post",
        url:
          CREATE_FEEDS +
          "?title=" +
          inputValues?.title +
          "&content=" +
          inputValues?.content +
          "&category=" +
          inputValues?.category +
          "&author_name=" +
          inputValues?.author_name,
        data: formData,
        ...config,
      });

      if (res.data.status === 200) {
        // previewImg = []
        formData = new FormData();
        setInputValues(initialInputValues);
        toast.success(res.data.message);
        setSubmitLoader(false);
        window.location.reload();
      } else {
        toast.error(res.data.message);
        setSubmitLoader(false);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err?.response.data);
        toast.error(err?.response.data.message);
        setSubmitLoader(false);
      }
    }
  };

  const onChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const removeImage = React.useCallback(
    (i) => {
      imgFiles.splice(i, 1);
      setSelectedIndex(i);
    },
    [imgFiles]
  );

  return (
    <>
      <GlassCard>
        <div className="flex justify-between border-b-2 border-gray-400 pb-2">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            <strong>Create New Feeds</strong>
          </h1>
          <span className="flex space-x-4">
            <Button type="button" onClick={handleSubmit}>
              Publish
            </Button>
          </span>
        </div>

        <form className="relative lg:flex flex-wrap lg:m-20 space-y-6">
          {submitLoader ? <TableSpinner /> : null}
          <div className="lg:flex w-full lg:space-x-10">
            {inputElement?.map((item, index) => {
              return item.name !== "content" && item.name !== "category" ? (
                <Input
                  key={index}
                  {...item}
                  value={inputValues[item.name]}
                  onChange={onChange}
                />
              ) : (
                [
                  item.name === "category" ? (
                    <Select
                      key={item.id}
                      defaultOption={item.label}
                      name={item.name}
                      label={item.label}
                      value={inputValues.category}
                      onChange={onChange}
                      options={FEED_CATEGORY.map((item, index) => {
                        return (
                          <option key={index} value={item.type}>
                            {item.title}
                          </option>
                        );
                      })}
                    />
                  ) : null,
                ]
              );
            })}
          </div>

          <div className="flex w-full">
            <Textarea label="Content" name="content" onChange={onChange} />
          </div>

          <div className="flex flex-wrap justify-center w-full">
            <h1 className="flex w-[100%] border-b-2 border-gray-500 dark-blue-text mb-2 px-2 text-lg font-bold">
              {imgFiles?.length > 0 ? "Selected photos" : "Upload photos"}
            </h1>
            <div className="flex w-full lg:flex-row md:flex-row flex-col lg:space-x-4 md:space-x-3">
              <input
                type="file"
                name="media"
                className="hidden"
                ref={handleFileInput}
                onChange={handleChange}
                multiple
              />
              {imgFiles?.map((item, index) => {
                return (
                  <>
                    <div key={index} className="relative lg:my-0 md:my-0 my-2">
                      <button
                        type="button"
                        className="absolute top-0.5 right-0.5 bg-white rounded-full"
                        onClick={() => removeImage(index)}
                      >
                        <FaIcons.FaTimesCircle size={20} color="red" />
                      </button>
                      <img
                        key={index}
                        className={
                          index === selectedIndex
                            ? "hidden"
                            : "rounded-lg dark-gray-bg p-2 h-[120px] w-[120px]"
                        }
                        src={item}
                        alt=""
                        onClick={handleClick}
                      />
                    </div>
                  </>
                );
              })}

              <button
                className="rounded-lg dark-gray-bg p-2"
                type="button"
                onClick={handleClick}
              >
                <MdIcons.MdAddCircle size={100} color="#f6f6f680" />
              </button>
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};
