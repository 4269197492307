import React, { useEffect } from "react";
import logo from "../../assets/logo/coloredlogo.png";

import { Avatar } from "../../components/avatar";
import * as MdIcons from "react-icons/md";
import * as TbIcons from "react-icons/tb";
import * as BsIcons from "react-icons/bs";
import { Badge } from "../../components/badge";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
import { useLocation, useNavigate } from "react-router-dom";
import { TableSpinner } from "../../components/spinner";
import { FormatDateTime } from "../../components/formatDate";
import { GlassCard } from "../../components/card";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { format } from "date-fns";

const GET_USER_BY_ID = "/admin/user-by-id?id=";

export const ViewUser = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = location.state || {};

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const copyToClipboard = async (copyText) => {
    await copy(copyText);
    await toast(`You have copied "${copyText}" to clipboard`);
  };

  const [userData, setUserData] = React.useState();

  const getUser = async () => {
    try {
      const response = await axios({
        method: "get",
        url: GET_USER_BY_ID + data,
        ...config,
      });
      const res = response?.data;
      setUserData(res);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response?.data);
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleModify = (userData) => {
    navigate("edit-user", { state: { data: userData } });
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-between border-b-2 pb-2">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            User Information
          </h1>
          <div className="">
            <button
              type="button"
              onClick={() => handleModify(userData?.data)}
              className="flex h-fit bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#59899b] text-[#f6f6f6] rounded-lg font-bold px-3 py-1"
            >
              <TbIcons.TbEditCircle
                size={15}
                color={"#f98c19"}
                className="my-1"
              />
              Edit Profile
            </button>
          </div>
        </div>

        {userData ? (
          <div className="flex flex-row w-full p-5 space-x-6">
            <div className="flex flex-col justify-center items-center h-full max-w-[40%] lg:space-x-10 space-x-3 ">
              <Avatar
                cl="h-[150px] w-[150px] my-auto rounded-full ring-1 ring-[#59899b]"
                src={userData?.data?.auth?.profile_picture || logo}
              />
              <div className="w-full">
                <h1 className="lg:text-2xl text-md font-bold dark-blue-text">
                  {userData?.data?.bio_data?.last_name +
                    " " +
                    userData?.data?.bio_data?.first_name}
                </h1>
                <sub className="flex w-fit max-w-fit lg:text-lg text-md light-orange-text  mb-3">
                  {userData?.data?.referral.referral_id}
                  <button
                    type="button"
                    onClick={() =>
                      copyToClipboard(userData?.data?.referral.referral_id)
                    }
                  >
                    <MdIcons.MdContentCopy
                      size={20}
                      color="#f98c19"
                      className="my-auto ml-2"
                    />
                  </button>
                </sub>
                {userData?.data?.account_status?.status === "ACTIVE" ? (
                  <Badge
                    cl="bg-green-100 w-min px-2 rounded-md text-green-700 font-bold text-sm"
                    content={userData?.data?.account_status?.status}
                  />
                ) : (
                  <Badge
                    cl="bg-orange-200 w-min px-2 rounded-md text-orange-500 font-bold text-sm"
                    content={userData?.data?.account_status?.status}
                  />
                )}
              </div>
            </div>

            <div className="flex-1 flex-wrap w-full h-full overflow-y-auto ">
              <div className="grid gap-3 grid-cols-3 w-full">
                <p className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Biodata
                </p>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Gender
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.bio_data?.gender || "Not stated"}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Email
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.auth?.email}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Phone Number
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.auth?.phone_number}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Username
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.auth?.username}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    User Type
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.user_type}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    DOB
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.bio_data?.date_of_birth
                      ? format(
                          new Date(userData?.data?.bio_data?.date_of_birth),
                          "yyyy-MM-dd"
                        )
                      : "Not stated"}
                  </p>
                </article>
              </div>

              <div className="grid gap-3 grid-cols-3 w-full mt-5 capitalize">
                <span className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Address
                </span>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Zone
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.zone}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    State
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.state}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Senatorial District
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.senatorial_district}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Local Government
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.local_government}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Ward
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.ward}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Polling Unit
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.address?.polling_unit}
                  </p>
                </article>
              </div>
              <div className="grid gap-3 grid-cols-3 w-full mt-5">
                <span className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  KYC
                </span>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    PVC Status
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.kyc?.pvc?.status}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    BVN Status
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.kyc?.bvn?.status}
                  </p>
                </article>
              </div>

              <div className="grid gap-3 grid-cols-3 w-full mt-5">
                <p className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Referral
                </p>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Referred By
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.referral?.referred_by || "Admin"}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    No of Users Referred
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.referral?.referred_users?.length || 0}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    List of Users Referred
                  </header>
                  {userData?.data?.referral?.referred_users?.map(
                    (item, index) => {
                      return (
                        <>
                          {item ? (
                            <p
                              key={index}
                              className="dark-blue-text font-bold text-md break-words max-h-24 overflow-y-auto"
                            >
                              {item.user_name}
                            </p>
                          ) : (
                            "Null"
                          )}
                        </>
                      );
                    }
                  )}
                </article>
              </div>

              <div
                className={
                  userData?.data?.user_type === "STAFF"
                    ? "grid gap-3 grid-cols-3 w-full mt-5"
                    : "hidden"
                }
              >
                <p className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Payment Information
                </p>

                <article className="">
                  <header className="light-orange-text font-semibold">
                    Bank Name
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.state_payment_information?.bank_name}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Account Number
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.state_payment_information?.account_number}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Payee Type
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.state_payment_information?.payee_type}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Payee Amount
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    NGN{" "}
                    {userData?.data?.state_payment_information?.payable_amount?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    )}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Grade Level
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.state_payment_information?.grade_level}
                  </p>
                </article>
              </div>

              <div className="grid gap-3 grid-cols-3 w-full mt-5">
                <p className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Wallet Information
                </p>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Wallet Code
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.wallet?.wallet_code}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Ledger Balance
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.wallet?.ledger_balance.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    )}
                  </p>
                </article>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Available Balance
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {userData?.data?.wallet?.available_balance.toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    )}
                  </p>
                </article>
              </div>

              <div className="grid gap-3 grid-cols-3 w-full mt-5">
                <p className="col-span-3 text-white text-xl font-bold w-full bg-gray-400 px-2 rounded-lg">
                  Registration Date
                </p>
                <article className="">
                  <header className="light-orange-text font-semibold">
                    Registered On
                  </header>
                  <p className="dark-blue-text font-bold text-md break-words">
                    {FormatDateTime(userData?.data?.auth?.registration_date)}
                  </p>
                </article>
              </div>
            </div>
          </div>
        ) : (
          <TableSpinner />
        )}
      </GlassCard>
    </>
  );
};
