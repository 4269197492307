import React from "react";
import { useState, useContext, createContext } from "react";
import { toast } from "react-toastify";
import axios from "../api/axios";

const AuthContext = createContext(null);

const GET_ADMIN_BY_ID = "/admin/admin-by-id?id=";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const user = JSON.parse(loggedInUser);
      setUser(user);
      setToken(user?.access_token);
      // console.log(user)
    }
  }, []);

  const login = (user) => {
    setUser(user);
    setToken(user.access_token);
    localStorage.setItem("user", JSON.stringify(user));
    // console.log(user);
  };

  const getAdminById = async (id) => {
    try {
    //   console.log(id);
      await axios.get(GET_ADMIN_BY_ID + id, config).then((response) => {
        const res = response?.data?.data;
        // console.log(res);
        const userData = {
          access_token: user.access_token,
          profile: {
            ...res,
          },
        };
        // console.log(userData);
        setUser(userData);
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(userData));
      });
    } catch (err) {
      if (err.response?.status === 401) {
        // logout()
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  React.useMemo(
    () =>
      performance?.getEntriesByType("navigation").map((res) => {
        return res.type === "reload" && user?.profile?._id
          ? getAdminById(user?.profile?._id)
          : null;
      }),
    [user?.profile?.profile_picture]
  );

  // if (
  //     performance?.getEntriesByType("navigation")[0]?.type === "reload" &&
  //     user?.profile?._id
  //   ) {
  //     console.log("Reload just took place");
  //     getAdminById(user?.profile?._id);
  //   }

  const logout = () => {
    setUser(null);
    window.location.href = "/";
    localStorage.clear();
    console.log("Logout");
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
