
import { useAuth } from '../context/auth';
import axios from './axios'

// Endpoints
const ADMIN_LOGIN_URL = '/admin/login'
const RESET_ADMIN_PWD = '/admin/reset-password'

export const AdminLogin = (params) =>{
    return(
         axios.post(ADMIN_LOGIN_URL, params)
    )
}

export const ResetPassword = async() =>{
    const auth = useAuth();
    const token = auth.token
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return(
        await axios.post(RESET_ADMIN_PWD, config)
    )
}