import React, { Fragment } from 'react';
import { IconContext } from 'react-icons/lib';

export const Card = (props) => {
    return (
        <>
            <div className='drop-shadow-xl shadow-lg h-min lg:w-1/3 w-full border-2 p-10 bg-white bg-opacity-30 rounded-3xl'>
                {props.children}
            </div>
        </>
    )
}

export const StatCard = (props) => {
    return (
        <>
            <div className="drop-shadow-xl shadow-lg h-min w-full border-2 px-4 py-4 bg-white rounded-lg flex justify-between">
                <IconContext.Provider value={{ color: '#fff' }}>
                    <div className='flex rounded-full bg-[#f98c19] w-[53px] h-[53px] p-3 my-auto'>
                        {props.icon}
                    </div>
                    <div className='dark-blue-text my-auto text-right'>
                        <h1 className='text-4xl h-min w-full'>{props.title}</h1>
                        <sub className='text-[10px] h-min w-full'>{props.subTitle}</sub>
                    </div>
                </IconContext.Provider>
            </div>
        </>
    )
}

export const DefaultCard = (props) => {
    return (
        <>
            <div className={props.cl}>
                {props.children}
            </div>
        </>
    )
}

export const GlassCard = ({ children, size }) => {
    return (
        <>
            <div className='w-full min-h-full max-h-screen overflow-auto bg-[#7e7e7e] bg-opacity-20 shadow-inner  border-1 rounded-2xl p-3'>
                {children}
            </div>
        </>
    )
}

export const TransactionGlassCard = (props) => {
    return (
        <>
            <div className='text-center'>
                <h1 className='dark-orange-text font-bold'>{props?.title}</h1>
                <div className='flex w-full h-20 bg-[#7e7e7e] bg-opacity-20 shadow-inner  border-1 rounded-2xl p-3'>
                    <div className='flex flex-col justify-between  border-r-2  text-center p-1'>
                        <h1 className='text-3xl font-black dark-blue-text'>{props?.count}</h1>
                        <sub className='dark-orange-text font-bold'>Total Count</sub>
                    </div>
                    <div className='flex flex-col justify-between p-1'>
                        <h1 className='text-lg dark-blue-text font-bold'>
                            <small>NGN</small> {props?.amount}
                        </h1>
                        <sub className='dark-orange-text font-bold'>Total Amount</sub>
                    </div>
                </div>
            </div>
        </>
    )
}

export const EmpowermentGlassCard = (props) => {
    return (
        <>
            <div className='text-center'>
                <h1 className='dark-orange-text font-bold'>{props.title}</h1>
                <div className='flex w-full bg-[#7e7e7e] bg-opacity-20 shadow-inner justify-center border-1 rounded-2xl p-3'>
                    <div className='text-center py-1 px-16'>
                        <h1 className='text-5xl font-black dark-blue-text'>{props.count}</h1>
                        <sub className='dark-orange-text text-lg font-bold'>Count</sub>
                    </div>
                </div>
            </div>
        </>
    )
}

export const FeedsGlassCard = ({ count, title }) => {
    return (
        <>
            <div className='text-center'>
                <div className='flex w-full bg-[#7e7e7e] bg-opacity-20 shadow-inner justify-center  border-1 rounded-2xl p-3'>
                    <div className='text-center py-1 px-16'>
                        <h1 className='text-5xl font-black dark-blue-text'>{count}</h1>
                        <sub className='dark-orange-text text-lg font-bold'>{title}</sub>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SupportGlassCard = ({ count, title, header, children, cl }) => {
    return (
        <>
            <div className=''>
                <sub className='dark-blue-text text-left pl-2'>{header}</sub>
                <div className='text-center flex w-full bg-[#7e7e7e] bg-opacity-20 shadow-inner justify-center  border-1 rounded-2xl p-3'>
                    <div className={cl}>
                        <h1 className='text-3xl font-black dark-blue-text'>{count}</h1>
                        <sub className='dark-orange-text text-md font-bold'>{title}</sub>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}