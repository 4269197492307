import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '../../components/buttons';
import { Card } from '../../components/card';
import { AuthInput } from '../../components/form';
import logo from '../../assets/logo/coloredlogo-name.png';

import { Nav } from '../../layout/navbar';
import { useAuth } from '../../context/auth';

import * as AiIcons from 'react-icons/ai'

import axios from '../../api/axios';
import { Link } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { useLocation } from 'react-router-dom';


const RESET_PWD = '/admin/request-password-update'

export const ForgotPassword = () => {

    const auth = useAuth()
    const navigate = useNavigate()
    const redirectPath = '/sign-in'

    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        // auth.login(values.email)
        // navigate(redirectPath, { replace: true })

        try{
            // axios.put(RESET_PWD, auth.user, config)
            navigate(redirectPath, { replace: true })
        }
        catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                console.log(err.response.data)
            }
        }

    }


    return (
        <>
            <Nav />
            <div className='flex justify-center pt-20'>
                <Card>
                    <div className='text-center'>
                        <h1 className='dark-blue-text text-2xl'>Forgot Password?</h1>
                        <sub>Request password reset</sub>
                    </div>

                    <form onSubmit={handleSubmit}>
                    <div className='p-8'>
                    <form className='flex-col space-y-6'>
                        <div className='pt-10'>
                            <AuthInput label="Email" type="email" name="email" placeholder='Enter email address' icon={<AiIcons.AiOutlineMail size="30" className='text-gray-500' />} disable="disabled"/>
                        </div>
                        <div className='pt-5'>
                            <Button type="submit" onClick={()=>setLoading(true)}>Reset Password </Button>
                            {loading ? Spinner:false}
                        </div>
                    </form>
                </div>
                    </form>


                    <div className='text-center pt-3'>
                        <sub>Don't have and account? <NavLink exact="true" to="/sign-up" className='dark-blue-text font-black'>Sign up</NavLink></sub>
                    </div>

                    {/* <hr className='border-t-2 border-gray-300 mt-4'/> */}
                    <div className='flex justify-end lg:hidden'>
                        <img src={logo} className="w-20" alt="logo" />
                    </div>
                </Card>
            </div>
        </>
    )
}
