import React from 'react'
import axios from '../api/axios'
import { useAuth } from '../context/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UPLOAD_PROFILE_PIC = '/admin/upload-profile-picture'
const GET_ADMIN_BY_ID = '/admin/admin-by-id?id='


export const FileUploader = (props) => {
    const auth = useAuth()
    const token = auth.token

    // INTERCEPT BARERE TOKEN FORM AUTH
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const handleFileInput = React.useRef(null)

    const handleClick = () => {
        handleFileInput.current.click()
    }
    
    const handleChange = async (event) => {
        const selectedFile = event.target.files[0];
        // props.handleFile(selectedFile)
        // console.log(props.handleFile(selectedFile) )
        const formData = new FormData()
        formData.append('image', selectedFile)
        console.log(formData)
        try {
            await axios.put(UPLOAD_PROFILE_PIC, formData, config)
                .then((response) => {
                    const res = response?.data
                    console.log(res)
                    toast.success(res.message)
                    window.location.reload()
                })
        }
        catch (err) {
            if (err.response?.status === 401) {
                // auth.logout()
                console.log(err.response.data)

            } else {
                console.log(err.response.data)
            }
        }

    }

    return (
        <>
            <ToastContainer />
            <input type="file" className="hidden" ref={handleFileInput} onChange={handleChange} />
            <button className={props.cl} type="button" onClick={handleClick}>{props.icon}</button>
        </>
    )
}
