export const INITIAL_TRANSACTION_STATE = {
    id: '',
    label: '',
    value: '',
    color: "hsl(267, 70%, 50%)",
    data: []
}

export const INITIAL_FILL_STATE = {
    match: {
        id: ''
    },
    id: 'dots'
}

export const dashboardReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_TRANSACTION_SUMMARY":
            return {
                ...state,
                data: action.payload
            };
        case "CHART_FILLS":
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export const fillReducer = (state, action) => {
    switch (action.type) {
        case "CHART_FILLS":
            return {
                ...state,
                data: action.payload
            };
        default:
            return state
    }
}