import React, { useMemo } from "react";
import { Input, Select } from "../../components/form";
import * as FaIcons from "react-icons/fa";

import { Button } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import { useLocation, useNavigate } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";
import { format } from "date-fns";

const gender = [
  {
    id: 1,
    title: "Male",
    key: "MALE",
  },
  {
    id: 2,
    title: "Female",
    key: "FEMALE",
  },
];

const EDIT_USER = "/admin/update-user-profile";

export const EditUser = () => {
  const auth = useAuth();
  const token = auth.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  const [submitLoader, setSubmitLoader] = React.useState(false);
  let rows = [];
  for (let i = 0; i < 31; i++) {
    rows.push(i + 1);
  }

  var max = new Date().getFullYear();
  var min = max - 9;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }

  const formik = useFormik({
    initialValues: {
      email: data?.auth?.email,
      username: data?.auth?.username,
      phone_number: data?.auth?.phone_number,
      last_name: data?.bio_data?.last_name,
      first_name: data?.bio_data?.first_name,
      gender: data?.bio_data?.gender,
      // date_of_birth: format(
      //   new Date(data?.bio_data?.date_of_birth),
      //   "yyyy-MM-dd"
      // ),
    },
    // validationSchema: EditUserSchema,
    onSubmit: async (values) => {
      // console.log("F V", values);
      setSubmitLoader(true);
      // try {
      //   const response = await axios({
      //     method: "put",
      //     url: EDIT_USER + "?username=" + data?.auth?.email + "&section=" + value,
      //     data: values,

      //     headers: { Authorization: `Bearer ${token}` },
      //   });
      //   // console.log(response)
      //   toast.success(response?.data.message);
      // } catch (err) {
      //   if (err.response.status === 401) {
      //     auth.logout();
      //   } else {
      //     console.log(err.response.data);
      //     toast.error(err.response.data.message);
      //   }
      // }
    },
  });

  const modify = async (value) => {
    setSubmitLoader(true);
    if (value === "AUTH") {
      const payload = {
        auth: {
          email: formik.values.email,
          username: formik.values.username,
          phone_number: formik.values.phone_number,
        },
      };
      await performUpdateRequest(payload, value);
      setSubmitLoader(false);
    } else if (value === "BIO_DATA") {
      // const d = format(Date.now(formik.values.date_of_birth), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      // console.log("New D ",d)
      const payload = {
        bio_data: {
          first_name: formik.values.first_name,
          last_name: formik.values.last_name,
          gender: formik.values.gender,
          date_of_birth: formik.values.date_of_birth,
        },
      };
      await performUpdateRequest(payload, value);
      setSubmitLoader(false);
    }
  };

  const performUpdateRequest = async (payload, value) => {
    try {
      const response = await axios({
        method: "put",
        url: EDIT_USER + "?username=" + data?.auth?.email + "&section=" + value,
        data: payload,

        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(response)
      toast.success(response?.data.message);
    } catch (err) {
      if (err.response.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      }
    }
  };

  const onChange = (e) => {
    // setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const maxDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate.setFullYear(currentDate.getFullYear() - 15);

    // function subtractYears(date, years) {
    //   date.setFullYear(date.getFullYear() - years);

    //   return date;
    // }
  }, []);

  return (
    <>
      <GlassCard>
        <div className="flex justify-start">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            <FaIcons.FaUserEdit size={30} />
            Edit User
          </h1>
        </div>
        <hr className="mt-2 " />

        <form className="relative">
          {submitLoader ? <TableSpinner /> : null}
          <div className="lg:py-8 lg:px-20 ">
            <div className="flex lg:flex-row flex-col lg:space-x-20">
              <div className="flex-1 w-full space-y-4">
                <h1 className="dark-blue-text text-lg border-b border-gray-500 pb-2">
                  Auth Data
                </h1>
                <Input
                  label="Email"
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={onChange}
                  disabled={true}
                />
                <Input
                  label="Username"
                  type="text"
                  name="username"
                  value={formik.values.username}
                  onChange={onChange}
                  disabled={true}
                />
                <Input
                  label="Phone Number"
                  type="text"
                  name="phone_number"
                  value={formik.values.phone_number}
                  onChange={onChange}
                />
                <Button type="button" onClick={() => modify("AUTH")}>
                  Update Auth Data
                </Button>
              </div>

              <div className="flex-1 w-full space-y-4">
                <h1 className="dark-blue-text text-lg border-b border-gray-500 pb-2">
                  Biodata Data
                </h1>
                <Input
                  label="Lastname"
                  type="text"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={onChange}
                />

                <Input
                  label="Firstname"
                  type="text"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={onChange}
                />

                <Select
                  defaultOption={formik.values.gender || "Choose Gender"}
                  name="gender"
                  label="Gender"
                  value={formik.values.gender}
                  onChange={onChange}
                  options={gender.map((item, index) => {
                    return (
                      <option key={index} value={item.key}>
                        {item.title}
                      </option>
                    );
                  })}
                />

                {/* <Input
                  label="Date of birth"
                  type={formik.values.date_of_birth ? "text" : "date"}
                  onFocus={(e) => (e.target.type = "date")}
                  name="date_of_birth"
                  value={
                    (formik.values.date_of_birth
                      ? formik.values.date_of_birth
                      : new Date(formik.values.date_of_birth)
                          .toLocaleDateString()
                          .replaceAll("/", "-")) || ""
                  }
                  max={format(new Date(maxDate), "yyyy-MM-dd")}
                  // max="2000-01-02"
                  onChange={onChange}
                /> */}

                <Button type="button" onClick={() => modify("BIO_DATA")}>
                  Update Biodata
                </Button>

                {/* <div>
                                    <label className='text-xs text-gray-500 flex'>User Type</label>
                                    <div className='flex pt-1 space-x-3'>
                                        <Select defaultOption="User Type" options=
                                            {userType.map((item, index) => {
                                                return (
                                                    <option key={index}>{item.title}</option>
                                                )
                                            })}
                                        />
                                    </div>
                                </div> */}
              </div>
            </div>

            {/* <div className='flex justify-end space-x-5 pt-5'>
                            <div className='flex space-x-5 w-72'>
                                <ButtonOutline>Delete</ButtonOutline>
                                <Button>Save</Button>
                            </div>
                        </div> */}
          </div>
        </form>
      </GlassCard>
    </>
  );
};
