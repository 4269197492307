import React, { useCallback, useMemo } from "react";
import * as MdIcons from "react-icons/md";
import { Input, Select } from "../../components/form";
import { Button, ButtonOutline } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import { WALLET_TYPE, TRANSACTION_TYPE } from "../../enum/transactions-enum";
import { USER_TYPE } from "../../enum/users-enum";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";
import { BulkFundingSchema } from "../../yup";
import { debounce } from "../../utils/Debounce";

const GET_USER_WITH_WALLET_CODE =
  "/admin//get-user-by-wallet-code?wallet_code=";
const BULK_FUND_WALLET = "/admin/bulk-fund-wallet";

export const BulkFundIndividual = () => {
  const auth = useAuth();
  const token = auth.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formik = useFormik({
    initialValues: {
      transaction_type: "",
      narration: "",
      wallet_type: "",
      members: [
        {
          wallet_code: "",
          amount: 0,
        },
      ],
    },
    validationSchema: BulkFundingSchema,
    onSubmit: async (values, action) => {
      action.setSubmitting(true);
      console.log(values);
      try {
        const response = await axios.post(BULK_FUND_WALLET, values, config);
        toast.success(response.data?.message);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        action.resetForm();
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err.response.data);
          toast.error(err.response?.data.message);
          action.setSubmitting(false);
        }
      }
    },
  });

  const [totalAmount, setTotalAmount] = React.useState(0);

  const inputElement = {
    constantInputElements: [
      {
        id: 1,
        label: "Transaction Type",
        name: "transaction_type",
        type: "text",
        placeholder: "Transaction Type",
        error: formik.errors.transaction_type,
        touched: formik.touched.transaction_type,
        onBlur: formik.handleBlur("transaction_type"),
        value: formik.values.transaction_type,
        // onChange: formik.handleChange("transaction_type"),
      },
      {
        id: 2,
        label: "Wallet Type",
        name: "wallet_type",
        type: "text",
        placeholder: "Wallet Type",
        error: formik.errors.wallet_type,
        touched: formik.touched.wallet_type,
        onBlur: formik.handleBlur("wallet_type"),
        value: formik.values.wallet_type,
        // onChange: formik.handleChange("wallet_type"),
      },
      {
        id: 3,
        label: "Narration",
        name: "narration",
        type: "text",
        placeholder: "Narration",
        error: formik.errors.narration,
        touched: formik.touched.narration,
        onBlur: formik.handleBlur("narration"),
        value: formik.values.narration,
        // onChange: formik.handleChange("narration"),
      },
    ],
    memberPayload: [
      {
        id: 1,
        label: "Wallet ID",
        name: "wallet_code",
        type: "text",
        placeholder: "Wallet ID",
        // error: formik.errors.wallet_code,
        // touched: formik.touched.wallet_code,
        // onBlur: formik.handleBlur("wallet_code"),
        // value: formik.values.wallet_code,
        // onChange: formik.handleChange("wallet_code"),
      },
      {
        id: 2,
        label: "User Type",
        name: "user_type",
        type: "text",
        // placeholder: validate ? validatedUser?.user_type : "User Type",
        disabled: true,
        // error: formik.errors.user_type,
        // touched: formik.touched.user_type,
        // onBlur: formik.handleBlur("user_type"),
        // value: formik.values.user_type,
        // onChange: formik.handleChange("user_type"),
      },
      {
        id: 3,
        label: "Beneficiary Name",
        name: "beneficiary_name",
        type: "text",
        // placeholder: validatedUser ? validatedUser?.name : "Beneficiary Name",
        disabled: true,
        // error: formik.errors.beneficiary_name,
        // touched: formik.touched.beneficiary_name,
        // onBlur: formik.handleBlur("beneficiary_name"),
        // value: formik.values.beneficiary_name,
        // onChange: formik.handleChange("beneficiary_name"),
      },
      {
        id: 4,
        label: "Amount",
        name: "amount",
        type: "number",
        placeholder: "Amount",
        // error: formik.errors.amount,
        // touched: formik.touched.amount,
        // onBlur: formik.handleBlur("amount"),
        // value: formik.values.amount,
        // onChange: formik.handleChange("amount"),
      },
    ],
  };

  const addFormFields = () => {
    formik.setFieldValue("members", [...formik.values.members, {}]);
  };

  const removeFormFields = (i) => {
    if (formik.values?.members?.length > 1) {
      let amt = formik.values?.members?.slice(-1);

      formik.values?.members?.splice(i, 1);
      formik.setFieldValue("members", [...formik.values.members]);

      setTotalAmount((currentAmount) => {
        return currentAmount - amt[0]?.amount;
      });
    }
  };

  // memoize the debounce call with useMemo
  const debouncedOnChange = useCallback(
    (event, index) =>
      debounce(handleValidateUser(event.target.value, index), 1000),
    []
  );

  const handleValidateUser = async (value, i) => {
    try {
      const response = await axios.get(GET_USER_WITH_WALLET_CODE + value, {
        ...config,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      formik.setFieldValue(`members[${i}]`, {
        wallet_code: value,
        name: response.data.data.name,
        user_type: response.data.data.user_type,
        amount: "",
      });
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast.error(err.data.message);
        console.log(err.data.message);
      }
    }
  };

  const amountChange = () => {
    const arr = formik.values.members?.map((item, i) => {
      return item.amount;
    });
    setTotalAmount(arr.reduce((a, b) => a + b, 0));
  };

  const onChangeConstType = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <GlassCard>
        <form
          onSubmit={formik.handleSubmit}
          className="relative flex flex-col lg:px-10 py-8 space-y-2 overflow-x-auto overflow-y-auto max-h-screen"
        >
          {formik.isSubmitting ? <TableSpinner /> : null}

          {/* Wallet Type, Transaction Type and Narration  */}
          {Object.keys(inputElement)?.map((page, i) => {
            return (
              <div className={"grid grid-cols-3 gap-4"} key={page}>
                {inputElement[page]?.map((item, index) => {
                  return page === "constantInputElements" ? (
                    <>
                      {item.name !== "wallet_type" &&
                      item.name !== "transaction_type" &&
                      item.name !== "user_type" ? (
                        <div key={index}>
                          {item.name !== "amount" ? (
                            <Input
                              {...item}
                              label=""
                              onChange={onChangeConstType}
                            />
                          ) : null}
                        </div>
                      ) : (
                        [
                          item.name !== "user_type" ? (
                            <Select
                              key={item.id}
                              defaultOption={item.label}
                              name={item.name}
                              onChange={onChangeConstType}
                              className="text-gray-500"
                              options={
                                item.name === "wallet_type"
                                  ? WALLET_TYPE.map((item, index) => {
                                      return (
                                        <option key={index} value={item.type}>
                                          {item.title}
                                        </option>
                                      );
                                    })
                                  : [
                                      item.name === "transaction_type"
                                        ? TRANSACTION_TYPE.map(
                                            (item, index) => {
                                              return item.title !== "Refund" ? (
                                                <option
                                                  key={index}
                                                  value={item.type}
                                                >
                                                  {item.title}
                                                </option>
                                              ) : null;
                                            }
                                          )
                                        : [
                                            item.name === "user_type"
                                              ? USER_TYPE.map((item, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      value={item.type}
                                                    >
                                                      {item.title}
                                                    </option>
                                                  );
                                                })
                                              : null,
                                          ],
                                    ]
                              }
                              error={formik.errors?.[item.name]}
                              touched={formik.touched?.[item.name]}
                              defaultValue={formik.values?.[item.name]}
                              value={formik.values?.[item.name]}
                            />
                          ) : null,
                        ]
                      )}
                    </>
                  ) : null;
                })}
              </div>
            );
          })}

          {/* Add users to fund to a list */}
          <div className={"space-y-2 pt-4"}>
            {Object.keys(inputElement)?.map((page) => {
              return (
                <div
                  className={
                    formik.values?.members?.length === 1
                      ? "flex flex-row justify-around pr-[3%] text-center font-bold text-sm"
                      : "flex flex-row justify-around pr-[5%]  text-center font-bold text-sm"
                  }
                  key={page}
                >
                  {inputElement[page]?.map((item, index) => {
                    return page === "memberPayload" ? (
                      <div key={index}>{item.label}</div>
                    ) : null;
                  })}
                </div>
              );
            })}

            {formik.values.members.map((items, i) => {
              return (
                <>
                  <div key={i} className="flex flex-row w-full">
                    {Object.keys(inputElement)?.map((page, it) => {
                      return (
                        <>
                          <div className={"flex flex-row space-x-4"} key={page}>
                            {inputElement[page]?.map((item, index) => {
                              return page === "memberPayload" ? (
                                <div key={index}>
                                  {item.name !== "beneficiary_name" &&
                                  item.name !== "user_type" ? (
                                    <Input
                                      {...item}
                                      label=""
                                      name={`members.${i}.${[item.name]}`}
                                      placeholder={item.placeholder}
                                      error={formik.errors.members?.[item.name]}
                                      touched={
                                        formik.touched.members?.[item.name]
                                      }
                                      onBlur={formik.handleBlur(item.name)}
                                      value={
                                        formik.values.members[i]?.[item.name]
                                      }
                                      onChange={formik.handleChange(
                                        `members.${i}.${[item.name]}`
                                      )}
                                      onKeyUp={
                                        item.name === "wallet_code"
                                          ? (e) => debouncedOnChange(e, i)
                                          : amountChange
                                      }
                                    />
                                  ) : (
                                    [
                                      item.name === "user_type" ? (
                                        <Input
                                          defaultValue={
                                            formik.values.members[i]?.user_type
                                          }
                                          placeholder="User Type"
                                          disabled
                                        />
                                      ) : (
                                        [
                                          item.name === "beneficiary_name" ? (
                                            <Input
                                              defaultValue={
                                                formik.values.members[i]?.name
                                              }
                                              placeholder="Beneficiary Name"
                                              disabled
                                            />
                                          ) : null,
                                        ]
                                      ),
                                    ]
                                  )}
                                </div>
                              ) : null;
                            })}
                          </div>
                        </>
                      );
                    })}

                    <div className="">
                      <button
                        type="button"
                        onClick={() => removeFormFields(i)}
                        className={
                          formik.values?.members?.length > 1
                            ? "visible w-fit"
                            : "hidden"
                        }
                      >
                        <MdIcons.MdRemoveCircleOutline
                          size={30}
                          className="hover:text-slate-500"
                        />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="flex justify-end">
              <button
                type="button"
                onClick={addFormFields}
                className="hover:text-slate-500"
              >
                <MdIcons.MdAddCircleOutline size={30} />
              </button>
            </div>
          </div>

          {/* 
                    <div className='flex flex-col justify-start dark-orange-text font-bold'>
                        <label>Schedule this transfer</label>
                        <div className='flex w-fit pt-1 space-x-3'>
                            <Select defaultOption="Schedule Time" options=
                                {transferTime.map((item, index) => {
                                    return (
                                        <option key={index}>{item.title}</option>
                                    )
                                })}
                            />
                        </div>
                    </div> */}

          <div className="flex justify-end dark-blue-text font-bold">
            <div className="flex flex-col">
              <strong className="flex justify-end">Total</strong>
              <div className="flex space-x-2">
                <strong className="my-auto">NGN</strong>
                <div className="flex w-min border-2 border-orange-400 rounded-xl px-10 py-2 text-xl">
                  {totalAmount || 0}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-5">
            <div className="flex space-x-5 w-72">
              <Button type={"submit"} disabled={formik.isSubmitting}>
                Pay all
              </Button>
              {/* <ButtonOutline type={"button"} onClick={() => resetFields()}>
                Cancel
              </ButtonOutline> */}
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};
