import React, { useRef, useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as FcIcons from "react-icons/fc";
import * as FiIcons from "react-icons/fi";
import * as BsIcons from "react-icons/bs";
import { GlassCard } from "../../components/card";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExportExcel } from "../../components/excel-export";
import { TableSpinner } from "../../components/spinner";
import infoLottie from "../../assets/images/no_data3.gif";
import XLSX from "sheetjs-style";
import { ResolveBanks } from "./resolve-banks";
import { Tooltip, Typography } from "@mui/material";
import { GlassModal } from "../../components/modal";
import ExcelFormat from "../../assets/images/ExcelFormat.png";
import JSONFormat from "../../assets/images/JSONFormat.png";
import { useFormik } from "formik";
import { Input } from "../../components/form";

const BULK_MAPPING = "/admin//bulk-mapping";
const BULK_TRANSFER = "/admin/bulk-transfer";

export const BulkBankTransfer = () => {
  const auth = useAuth();
  const token = auth.token;

  const [submitLoader, setSubmitLoader] = useState(false);
  const [filename, setFilename] = useState("");
  const [accountsNumberFile, setAccountsNumberFile] = useState([]);
  const [resolutionResult, setResolutionResult] = useState();
  const [imagePreview, setImagePreview] = useState();

  const exportFunctionToExcel = (excelData, fileName) => {
    /* pass here the json data */
    const ws = XLSX.utils.json_to_sheet(excelData);
    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    /* save to file */
    XLSX.writeFile(wb, fileName + ".xlsx");
  };

  const formik = useFormik({
    initialValues: {
      // idempotentKey: nanoid(),
      account_information: [],
      charges: "50",
    },
    // validationSchema: FundSingleUserSchema,
    onSubmit: async (values, action) => {
      // console.log(values);
      action.setSubmitting(true);

      try {
        const response = await axios({
          method: "post",
          url: BULK_TRANSFER,
          data: values,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setResolutionResult();
        action.setSubmitting(false);
        action.resetForm();
        exportFunctionToExcel(
          formik.values.account_information,
          filename?.split(".").slice(0, -1).join(".") +
            " Resolved Account Details"
        );
        toast.success(`${response?.data?.message}`);
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err.response?.data);
          toast.error(err.response?.data?.message);
          action.setSubmitting(false);
        }
      }
    },
  });

  const fileUpload = useRef(null);
  const handleFileUpload = () => {
    fileUpload.current.click();
  };
  const handleFileUploadCLick = () => {
    const file = filename?.split(".").slice(0, -1).join(".");

    if (accountsNumberFile.length > 0) {
      handleResolveAccounts({ account_data: accountsNumberFile, lga: file });
    } else {
      toast("File not found");
    }
  };

  const fileChange = (e) => {
    e.preventDefault();
    setAccountsNumberFile([]);
    setFilename("");

    setFilename(e.target.files[0]?.name);
    if (e.target.files[0]?.type === "application/json") {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        // console.log("e.target.result", e.target.result);
        setAccountsNumberFile(JSON.parse(e.target.result));
      };
    }

    if (
      e.target.files[0]?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0]?.type === "text/csv"
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setAccountsNumberFile(json);
        // console.log(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const removeFile = (e) => {
    e.preventDefault();
    setAccountsNumberFile([]);
    setFilename("");
  };

  const handleResolveAccounts = async (bodyParameter) => {
    formik.setSubmitting(true);
    try {
      const response = await axios({
        method: "put",
        url: BULK_MAPPING,
        data: bodyParameter,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response?.data);
      setResolutionResult(response?.data);
      toast.success(`${response?.data?.message}`);
      formik.setSubmitting(false);
      // exportFunctionToExcel(response?.data?.resolved,filename?.split(".").slice(0, -1).join(".") +" Resolved Account Details");
      // exportFunctionToExcel(response?.data?.unresolved,filename?.split(".").slice(0, -1).join(".") +" UnResolved Account Details");
      // exportFunctionToExcel(response?.data?.duplicates,filename?.split(".").slice(0, -1).join(".") +" Duplicated Account Details");

      let array = [];

      response?.data?.resolved.map((item, i) => {
        return array.push({
          ...item,
          amount: `${Number(formik.values.amount) * 100}`,
          payment_status: "PAID",
        });
      });
      formik.setFieldValue("account_information", array);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response?.data);
        toast.error(err.response?.data?.message);
        formik.setSubmitting(false);
      }
    }
  };

  const [fileFormatModal, setFileFormatModal] = useState(false);
  const handleOpen = () => setFileFormatModal(true);
  const handleClose = () => setFileFormatModal(false);

  return (
    <>
      <GlassModal handleOpen={fileFormatModal} handleClose={handleClose}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-center font-bold"
        >
          <div className="flex justify-end w-full">
            <button
              type="button"
              onClick={handleClose}
              className="border-2 border-orange-400 rounded-lg p-1 h-min text-xs"
            >
              <FaIcons.FaTimes size={12} color={"orange"} />
            </button>
          </div>
        </Typography>

        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex flex-col space-y-10 w-[1000px]"
        >
          <div
            className={
              !!imagePreview
                ? "absolute z-[999] top-0 left-0 rounded-xl w-full h-full"
                : "hidden"
            }
          >
            <button
              className="absolute right-2 top-2"
              onClick={() => setImagePreview()}
            >
              <FaIcons.FaTimes size={30} color={"orange"} />
            </button>
            <img
              src={imagePreview}
              className="w-full h-full rounded-xl"
              alt=""
            />
          </div>

          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => setImagePreview(ExcelFormat)}
              className="w-[50%] h-[300px] space-y-2"
            >
              <p className="font-bold">Excel file format sample</p>
              <img src={ExcelFormat} className="w-full h-full" alt="" />
            </button>
            <button
              type="button"
              onClick={() => setImagePreview(JSONFormat)}
              className="w-[50%] h-[300px] space-y-2"
            >
              <p className="font-bold">JSON file format sample</p>
              <img src={JSONFormat} className="w-full h-full" alt="" />
            </button>
          </div>

          <div className="">
            <strong>NB:</strong>
            <ul>
              <li>Make sure the file name signifies the content.</li>
              <li>
                Make sure the fields name are exactly like the sample:{" "}
                <b>sn, name, phone_number, bank_name, account_number.</b>
              </li>
            </ul>
          </div>
        </Typography>
      </GlassModal>

      <Input
        type="number"
        name="amount"
        placeholder="Amount to send to each beneficiary"
        error={formik.errors.amount}
        touched={formik.touched.amount}
        value={formik.values.amount}
        onBlur={formik.handleBlur("amount")}
        onChange={formik.handleChange("amount")}
      />
      <div className="relative grid grid-cols-2 gap-4">
        {formik.isSubmitting ? <TableSpinner /> : null}
        <div className="relative min-h-[300px] max-h-screen w-full overflow-y-auto">
          <GlassCard>
            <div className="absolute top-2 right-2">
              <Tooltip title="Click to view file format">
                <button
                  onClick={handleOpen}
                  className="flex items-center bg-gray-200 rounded-full px-2"
                >
                  <BsIcons.BsInfoCircle />
                  &nbsp; File Format
                </button>
              </Tooltip>
            </div>

            <div className="flex flex-col justify-center items-center h-full w-full">
              <input
                type="file"
                hidden
                ref={fileUpload}
                onChange={fileChange}
              />
              <div className="flex justify-center  dark-blue-text mt-4">
                <button type="button" onClick={handleFileUpload}>
                  <FaIcons.FaFileUpload size={100} />
                </button>
              </div>
              <div className="dark-blue-text text-center font-bold">
                {filename
                  ? filename
                  : "Upload Excel or JSON file to resolve account details"}
                <br />
                <h1 className="dark-orange-text mt-3">
                  Total Number of data: {accountsNumberFile?.length || null}{" "}
                </h1>
              </div>
              <ul className={filename ? "visible w-full" : "hidden"}>
                <li className="flex justify-between rounded-lg my-4 p-4 bg-white">
                  <h1 className="flex justify-start">
                    {filename?.split(".").slice(0, -1).join(".")}{" "}
                  </h1>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => handleFileUploadCLick()}
                      className="rounded-lg px-2 outline-orange-500 outline-2 outline-double hover:bg-orange-600 hover:text-white"
                    >
                      Resolve Accounts
                    </button>
                    &nbsp; &nbsp;
                    <button
                      type="button"
                      onClick={(e) => removeFile(e)}
                      className="rounded-lg "
                    >
                      <FaIcons.FaRegTimesCircle size={25} color={"red"} />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </GlassCard>
        </div>

        <div className="">
          <GlassCard>
            <div className="flex flex-row w-full max-h-screen overflow-y-auto space-x-2">
              {/* Nodata */}
              <div
                className={
                  resolutionResult?.resolved ||
                  resolutionResult?.unresolved ||
                  resolutionResult?.duplicates
                    ? "hidden"
                    : "flex w-full visible"
                }
              >
                <img
                  src={infoLottie}
                  className="h-[270px] w-[270px] flex m-auto"
                  alt=""
                />
              </div>

              {/* Resolved data */}
              <div
                className={
                  !resolutionResult?.resolved ||
                  resolutionResult?.resolved.length === 0
                    ? "hidden"
                    : "visible w-full"
                }
              >
                <h1 className="dark-blue-bg rounded-lg p-2 sticky">
                  {resolutionResult?.resolved.length + " Resolved " ||
                    "No Resolution Yet"}{" "}
                </h1>
                {resolutionResult?.resolved?.map((item, i) => {
                  return (
                    <>
                      <ol
                        key={i}
                        className="border-2 rounded-lg my-2 p-2 text-xs"
                      >
                        <li>{item.account_name}</li>
                        <li>{item.account_number}</li>
                        <li>{item.bank_name}</li>
                        <li>{item.bank_code}</li>
                      </ol>
                    </>
                  );
                })}
                <div className="flex flex-col space-y-2 justify-between">
                  <div className="flex justify-center rounded-lg px-2 outline-orange-500 outline-2 outline-double hover:bg-slate-200 hover:bg-opacity-30 hover:">
                    <FcIcons.FcExport size={25} />
                    <ExportExcel
                      excelData={resolutionResult?.resolved}
                      fileName={
                        filename?.split(".").slice(0, -1).join(".") +
                        " Resolved Account Details"
                      }
                    />
                  </div>
                </div>
              </div>

              {/* Unresolved data */}
              <div
                className={
                  !resolutionResult?.unresolved ||
                  resolutionResult?.unresolved.length === 0
                    ? "hidden"
                    : "visible w-full"
                }
              >
                <h1 className="dark-blue-bg rounded-lg p-2">
                  {resolutionResult?.unresolved.length + " Unresolved" ||
                    "No Resolution Yet"}{" "}
                </h1>
                {resolutionResult?.unresolved?.map((item, i) => {
                  return (
                    <>
                      <ol
                        key={i}
                        className="border-2 rounded-lg my-2 p-2 text-xs"
                      >
                        <li>{item.account_name}</li>
                        <li>{item.account_number}</li>
                        <li>{item.bank_name}</li>
                        <li>{item.bank_code}</li>
                      </ol>
                    </>
                  );
                })}
                <div className="flex flex-col space-y-2 justify-between">
                  <div className="flex justify-center rounded-lg px-2 outline-orange-500 outline-2 outline-double hover:bg-slate-200 hover:bg-opacity-30 hover:">
                    <FcIcons.FcExport size={25} />
                    <ExportExcel
                      excelData={resolutionResult?.unresolved}
                      fileName={
                        filename?.split(".").slice(0, -1).join(".") +
                        " Unresolved Account Details"
                      }
                    />
                  </div>
                </div>
              </div>

              {/* duplicates data */}
              <div
                className={
                  !resolutionResult?.duplicates ||
                  resolutionResult?.duplicates.length === 0
                    ? "hidden"
                    : "visible w-full"
                }
              >
                <h1 className="dark-blue-bg rounded-lg p-2">
                  {resolutionResult?.duplicates.length + " Duplicates" ||
                    "No Resolution Yet"}{" "}
                </h1>
                {resolutionResult?.duplicates?.map((item, i) => {
                  return (
                    <>
                      <ol
                        key={i}
                        className="border-2 rounded-lg my-2 p-2 text-xs"
                      >
                        <li>{item.account_name}</li>
                        <li>{item.account_number}</li>
                        <li>{item.bank_name}</li>
                        <li>{item.bank_code}</li>
                      </ol>
                    </>
                  );
                })}
                <div className="flex flex-col space-y-2 justify-between">
                  <div className="flex justify-center rounded-lg px-2 outline-orange-500 outline-2 outline-double hover:bg-slate-200 hover:bg-opacity-30 hover:">
                    <FcIcons.FcExport size={25} />
                    <ExportExcel
                      excelData={resolutionResult?.duplicates}
                      fileName={
                        filename?.split(".").slice(0, -1).join(".") +
                        " Duplicated Account Details"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                !resolutionResult?.resolved ||
                !resolutionResult?.unresolved ||
                !resolutionResult?.duplicates
                  ? "hidden"
                  : "visible"
              }
            >
              <button
                onClick={formik.handleSubmit}
                className="flex justify-center w-full mt-4 rounded-lg px-2 outline-blue-800 outline-2 outline-double hover:bg-slate-200 hover:bg-opacity-30"
              >
                <FiIcons.FiSend size={22} />
                Transfer Fund
              </button>
            </div>
          </GlassCard>
        </div>
      </div>

      <br />

      {/* <ResolveBanks /> */}
    </>
  );
};
