import React from 'react'
import * as FaIcons from 'react-icons/fa'
import { GlassCard } from '../../components/card'

export const ManageWebsite = () => {
    return (
        <>
            <GlassCard>
                <div className='flex '>
                    <h1 className='flex text-xl dark-blue-text font-black'>
                        <FaIcons.FaGlobeAfrica size={25} />    Manage Website
                    </h1>
                </div>
                <hr className='mt-2 ' />

                <div className='lg:p-8 py-3'>
                    <form className='flex-col space-y-3'>
                        <div className='flex space-x-2 justify-between'>
                            <span className='w-full lg:w-[30%] dark-blue-bg p-2 rounded-lg'>Mission</span>
                            <button className='rounded-full light-gray-bg shadow-lg drop-shadow-lg p-2'>Add/Edit</button>
                        </div>
                        <div className='flex space-x-2 justify-between'>
                            <span className='w-full lg:w-[30%] dark-blue-bg p-2 rounded-lg'>Vision</span>
                            <button className='rounded-full light-gray-bg shadow-lg drop-shadow-lg p-2'>Add/Edit</button>
                        </div>
                        <div className='flex space-x-2 justify-between'>
                            <span className='w-full lg:w-[30%] dark-blue-bg p-2 rounded-lg'>About</span>
                            <button className='rounded-full light-gray-bg shadow-lg drop-shadow-lg p-2'>Add/Edit</button>
                        </div>
                        <div className='flex space-x-2 justify-between'>
                            <span className='w-full lg:w-[30%] dark-blue-bg p-2 rounded-lg'>Landing Page</span>
                            <button className='rounded-full light-gray-bg shadow-lg drop-shadow-lg p-2'>Add/Edit</button>
                        </div>
                    </form>
                </div>

                <hr />

                <div className='lg:p-8 py-3'>
                    <iframe title='website' src="https://bellocare.ng" className='w-full h-96 rounded-xl' />,

                </div>
            </GlassCard>
        </>
    )
}
