import React from 'react';

export const Badge = ({ cl, content }) => {
    return (
        <>
            <span className={cl}>{content}</span>
        </>
    )
}

