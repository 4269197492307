import React from "react";
import { FeedsGlassCard, GlassCard } from "../../components/card";
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { Button, ButtonOutline } from "../../components/buttons";
import { Input, Select, Textarea } from "../../components/form";
import * as MdIcons from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { FEED_CATEGORY } from "../../enum/feeds-enum";
import { useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ConfirmationModal } from "../../components/modal";
import { Typography } from "@mui/material";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";
import { FeedsSchema } from "../../yup";

const GET_FEEDS = "/admin/get-feed-with-reference?reference=";
const UPDATE_FEEDS = "/admin/update-feed";
const UPDATE_STATUS = "/admin/update-feed-status";
const DELETE_FEEDS_URL = "/admin/delete-feed";
const GET_FEEDS_SUMMARY = "/admin/feeds-summary";

export const ViewEditFeeds = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getFeeds();
  }, []);

  const formik = useFormik({
    initialValues: {
      reference: data,
      title: "",
      content: "",
      category: "",
      author_name: "",
    },
    validationSchema: FeedsSchema,
    onSubmit: async (values, action) => {
      action.setSubmitting(true);
      try {
        const res = await axios({
          method: "put",
          url: UPDATE_FEEDS,
          data: values,
          ...config,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        action.setSubmitting(false);
        action.resetForm();
        toast.success(res.data.message);
        navigate(-1);
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err?.response.data);
          toast.error(err?.response.data.message);
          action.setSubmitting(false);
        }
      }
    },
  });

  const getFeeds = async () => {
    try {
      const res = await axios({
        method: "get",
        url: GET_FEEDS + data,
        ...config,
      });
      formik.setValues({ ...res.data?.data });
      // const feedsSummaryResponse = await axios({
      //   method: "get",
      //   url: GET_FEEDS_SUMMARY,
      //   ...config,
      // });
      // setFeedsSummary(feedsSummaryResponse?.data?.status_summary);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  // Change feeds status
  const [openDialog, setDialogOpen] = React.useState(false);
  const [status, setStatus] = React.useState();
  const [comment, setComment] = React.useState();

  // Confirmation dialog
  const [confirmationModal, setConfirmationModalOpen] = React.useState(false);
  const [reference, setReference] = React.useState("");
  const handleOpenConfirmationModal = async (value) => {
    setConfirmationModalOpen(true);
    setReference(value);
  };
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const inputElement = [
    {
      id: 1,
      name: "title",
      type: "text",
      placeholder: "Title",
      label: "Title",
      error: formik.errors.title,
      touched: formik.touched.title,
      onBlur: formik.handleBlur("title"),
      value: formik.values.title,
      onChange: formik.handleChange("title"),
    },
    {
      id: 2,
      label: "Author Name",
      name: "author_name",
      type: "text",
      placeholder: "Author Name",
      error: formik.errors.author_name,
      touched: formik.touched.author_name,
      onBlur: formik.handleBlur("author_name"),
      value: formik.values.author_name,
      onChange: formik.handleChange("author_name"),
    },
    {
      id: 3,
      label: "Content",
      name: "content",
      type: "text",
      placeholder: "Content",
      error: formik.errors.content,
      touched: formik.touched.content,
      onBlur: formik.handleBlur("content"),
      value: formik.values.content,
      onChange: formik.handleChange("content"),
    },
    {
      id: 4,
      label: "Feed Category",
      name: "category",
      type: "text",
      placeholder: "Category Name",
      // error: formik.errors.category,
      // touched: formik.touched.category,
      // onBlur: formik.handleBlur("category"),
      // value: formik.values.category,
      // onChange: formik.handleChange("category"),
    },
  ];

  // DELETE FEED WITH REFERENCE
  const deleteFeed = async (reference) => {
    formik.setSubmitting(true);
    try {
      const response = await axios({
        method: "delete",
        url: DELETE_FEEDS_URL + `?reference=${reference}`,
        ...config,
      });
      toast.success(response?.data.message);
      handleCloseConfirmationModal();
      navigate(-1);
      formik.isSubmitting(false);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response?.data);
        toast.error(err.response?.data.message);
        formik.isSubmitting(false);
      }
    }
  };

  const handleFileInput = React.useRef(null);
  const handleClick = () => {
    handleFileInput?.current.click();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleStatusMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = (value) => {
    setDialogOpen(true);
    setStatus(value);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  // Change feeds status
  const handleChangeStatus = async () => {
    setDialogOpen(false);
    formik.setSubmitting(true);
    try {
      const res = await axios({
        method: "put",
        url:
          UPDATE_STATUS +
          "?status=" +
          status +
          "&id=" +
          formik.values._id +
          "&comment=" +
          comment,
        ...config,
      });
      // console.log(res?.data.message)
      toast.success(res?.data.message);
      navigate(-1);
      formik.setSubmitting(false);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
        formik.setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirm status change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change feed status to {status}?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Comment"
            type="text"
            fullWidth
            variant="standard"
            onChange={onChangeComment}
          />
        </DialogContent>
        <DialogActions>
          <ButtonOutline onClick={handleDialogClose}>Cancel</ButtonOutline>
          <Button onClick={handleChangeStatus}>Change</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationModal
        handleOpen={confirmationModal}
        handleClose={handleCloseConfirmationModal}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-between font-bold"
        >
          <p className="dark-blue-text font-bold">Delete confirmation</p>
          <button
            type="button"
            onClick={handleCloseConfirmationModal}
            className="border-2 border-orange-400 rounded-lg p-1 h-min text-xs"
          >
            <FaIcons.FaTimes size={12} color={"orange"} />
          </button>
        </Typography>
        <Typography
          component={"span"}
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex lg:flex-row flex-col space-y-6"
        >
          <article className="w-full mb-5">
            <header className="light-orange-text font-semibold">
              Are you sure you want to permanently delete {reference}?
            </header>
          </article>
        </Typography>
        <Typography component={"span"}>
          <footer className="flex w-full justify-end mt-2 space-x-3">
            <button
              type="button"
              className="bg-[#b70000] text-[#fff] rounded-lg font-bold px-4 py-2"
              onClick={() => deleteFeed(reference)}
            >
              Yes
            </button>
            <button
              type="button"
              className="text-black outline-2 outline-double rounded-lg font-bold px-3 py-1"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </button>
          </footer>
        </Typography>
      </ConfirmationModal>

      <GlassCard>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between border-b-2 border-gray-400 pb-2">
            <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
              <span className="cursor-pointer" onClick={() => navigate(-1)}>
                <BsIcons.BsFillCaretLeftFill className="lg:text-3xl text-lg" />
              </span>
              <strong className="lg:text-2xl text-sm">
                {formik.values?.title}
              </strong>
            </h1>

            <span className="flex space-x-4 text-white lg:text-lg text-sm">
              {formik.values?.status?.status === "PUBLISHED" ? (
                <button
                  type="button"
                  onClick={handleStatusMenuClick}
                  className="bg-green-500 hover:bg-slate-400 rounded-full px-2 py-1 flex h-min"
                >
                  <MdIcons.MdOutlineGppGood className="lg:text-2xl text-lg" />
                  Published{" "}
                  <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
                </button>
              ) : (
                [
                  formik.values?.status?.status === "PENDING" ? (
                    <button
                      type="button"
                      onClick={handleStatusMenuClick}
                      className="bg-orange-400 hover:bg-slate-400 rounded-full px-3 py-1 flex h-min"
                    >
                      <MdIcons.MdBlock className="lg:text-2xl text-lg" />
                      Pending{" "}
                      <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleStatusMenuClick}
                      className="bg-red-600 hover:bg-slate-400 rounded-full px-3 py-1 flex h-min"
                    >
                      <span className="flex justify-center items-center">
                        <MdIcons.MdBlock className="lg:text-2xl text-lg" />
                        Archived
                        <MdIcons.MdKeyboardArrowDown className="lg:text-2xl text-lg" />
                      </span>
                    </button>
                  ),
                ]
              )}

              <button
                type="button"
                className="flex w-full rounded-full px-2 py-1 h-min bg-red-700 hover:bg-slate-400"
                onClick={() => handleOpenConfirmationModal(data)}
              >
                <span className="flex justify-center items-center">
                  <FaIcons.FaTimesCircle className="lg:text-2xl text-lg" />
                  &nbsp;Delete
                </span>
              </button>

              <button
                type={"submit"}
                // onClick={handleSubmit}
                disabled={formik?.isSubmitting || !formik?.isValid}
                className="flex w-full rounded-full px-2 py-1 h-min dark-blue-bg hover:bg-slate-600 disabled:bg-slate-500"
              >
                <span className="flex justify-center items-center">
                  <MdIcons.MdOutlineSave
                    size={25}
                    className="lg:text-2xl text-lg"
                  />
                  Save
                </span>
              </button>
            </span>
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleStatusMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleDialogOpen("PUBLISHED")}>
              Publish
            </MenuItem>
            <MenuItem onClick={() => handleDialogOpen("PENDING")}>
              Pending
            </MenuItem>
            <MenuItem onClick={() => handleDialogOpen("ARCHIVED")}>
              Archive
            </MenuItem>
          </Menu>

          {/* <div className="relative lg:flex flex-wrap justify-center my-10 lg:space-x-4 lg:space-y-0 space-y-4">
          {feedsSummary?.map((item, index) => {
            return (
              <FeedsGlassCard
                title={item?._id}
                count={item?.total_count || 0}
              />
            );
          })}
        </div> */}

          <div className="relative lg:flex flex-wrap lg:m-20 space-y-6">
            {formik.values?.title.length > 0 ? null : <TableSpinner />}
            {formik.isSubmitting ? <TableSpinner /> : null}

            <div className="lg:flex w-full lg:space-x-10">
              {inputElement?.map((item, index) => {
                return item.name !== "content" && item.name !== "category" ? (
                  <Input key={index} {...item} />
                ) : (
                  [
                    item.name === "category" ? (
                      <Select
                        key={item.id}
                        defaultOption={item.label}
                        name={item.name}
                        label={item.label}
                        // value={inputValues.category || feeds?.category || ""}
                        options={FEED_CATEGORY.map((item, index) => {
                          return (
                            <option key={index} value={item.type}>
                              {item.title}
                            </option>
                          );
                        })}
                        error={formik.errors?.[item.name]}
                        touched={formik.touched?.[item.name]}
                        value={formik.values?.[item.name]}
                        onChange={formik.handleChange(item.name)}
                      />
                    ) : null,
                  ]
                );
              })}
            </div>

            <div className="flex w-full">
              <Textarea
                label="Content"
                name="content"
                error={formik.errors.content}
                touched={formik.touched.content}
                value={formik.values.content}
                onBlur={formik.handleBlur("content")}
                onChange={formik.handleChange("content")}
              />
            </div>

            <div className="flex flex-wrap justify-center w-full">
              <h1 className="flex w-[100%] border-b-2 border-gray-500 dark-blue-text mb-2 px-2 text-lg font-bold">
                Photos
              </h1>
              <span className="flex relative lg:flex-row flex-col lg:space-x-4">
                {formik.values?.media?.length > 0 ? null : <TableSpinner />}
                {formik.values?.media?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      className="rounded-lg dark-gray-bg p-2 h-[120px] w-[120px]"
                      src={item.path}
                      alt=""
                      onClick={handleClick}
                    />
                  );
                })}
              </span>
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};
