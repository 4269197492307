import React from "react";
import { Select } from "../../components/form";
import { GlassCard } from "../../components/card";
import { Outlet, useNavigate } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import GroupFunding from "./group-funding";
import { BulkFundIndividual } from "./bulk-fund-individual";
import { BulkBankTransfer } from "./bulk-bank-transfer";
import { BulkFundSector } from "./bulk-fund-sector";

const USER_CATEGORY = [
  {
    id: 1,
    title: "Bulk Fund",
    category: "BULK-FUND",
  },
  {
    id: 2,
    title: "Sector Fund",
    category: "SECTOR-FUND",
  },
  {
    id: 3,
    title: "Bulk Transfer",
    category: "BULK-TRANSFER",
  },
  {
    id: 4,
    title: "Group Funding",
    category: "GROUP-FUNDING",
  },
];

export const SchedulePayment = () => {
  const navigate = useNavigate();

  const [bulkTransfer, setBulkTransfer] = React.useState(false);
  const [groupFunding, setGroupFunding] = React.useState(false);
  const [bulkFunding, setBulkFunding] = React.useState(true);
  const [sectorFunding, setSectorFunding] = React.useState(false);
  const [categorySelected, setCategory] = React.useState("BULK-FUND");

  const onChangeCategory = (e) => {
    setCategory(e.target.value);

    switch (e.target.value) {
      case "BULK-TRANSFER":
        setBulkTransfer(true);
        setBulkFunding(false);
        setGroupFunding(false);
        setSectorFunding(false);
        break;
      case "GROUP-FUNDING":
        setGroupFunding(true);
        setBulkTransfer(false);
        setBulkFunding(false);
        setSectorFunding(false);
        break;
      case "SECTOR-FUND":
        setSectorFunding(true);
        setBulkTransfer(false);
        setGroupFunding(false);
        setBulkFunding(false);
        break;
      case "BULK-FUND":
        setBulkFunding(true);
        setBulkTransfer(false);
        setGroupFunding(false);
        setSectorFunding(false);
        break;
      default:
        setBulkTransfer(false);
        setGroupFunding(false);
        setSectorFunding(false);
        setBulkFunding(true);
        break;
    }
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-between border-b-2 border-gray-400 pb-2">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            <strong>Bulk Payment</strong>
          </h1>
          <span>
            <Select
              defaultOption="Category"
              name="category"
              value={categorySelected}
              onChange={onChangeCategory}
              className="text-gray-500"
              options={USER_CATEGORY.map((item, index) => {
                return (
                  <>
                    <option key={index} value={item.category}>
                      {item.title}
                    </option>
                    {/* <option value={'BANK_TRANSFER'}>Bank Transfer</option> */}
                  </>
                );
              })}
            />
          </span>
        </div>

        <div className="relative flex flex-col lg:px-10 py-8 space-y-2 overflow-x-auto overflow-y-auto max-h-screen">
          {/* Sector Funding */}
          <div className={!sectorFunding ? "hidden" : "space-y-2 pt-4"}>
            <BulkFundSector />
          </div>

          {/* Add users to fund to a list */}
          <div className={!bulkFunding ? "hidden" : "space-y-2 pt-4"}>
            <BulkFundIndividual />
          </div>

          {/* Group funding */}
          <div className={!groupFunding ? "hidden" : "space-y-2 pt-4"}>
            <GroupFunding />
          </div>

          {/* Bulk transfer */}
          <div className={bulkTransfer ? "space-y-2 pt-4" : "hidden"}>
            <BulkBankTransfer />
          </div>
        </div>
      </GlassCard>

      <Outlet />
    </>
  );
};
