import React from "react";
import { Input, Select } from "../../components/form";
import { Button, ButtonOutline } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import { WALLET_TYPE, TRANSACTION_TYPE } from "../../enum/transactions-enum";
import { USER_TYPE } from "../../enum/users-enum";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StateLgaWardPu from "../../json/SLWP.json";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";
import { GroupFundSchema } from "../../yup";

const BULK_FUND_WALLET = "/admin/bulk-fund-wallet";

export const GroupFunding = () => {
  const auth = useAuth();
  const token = auth.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getState();
  }, []);

  const getState = () => {
    // let stateArray = [];
    // StateLgaWardPu.map((item, i) => {
    //   return stateArray.push(item.name);
    // });
    // setStatesArrayList(stateArray);
    const stateData = StateLgaWardPu.filter(
      (state) => state.name.trim().toLowerCase() === "kogi"
    );
    setLocalGovArray(stateData[0].lgas);
    //   setValues({ ...values, 'state': 'Kogi' })
  };

  const formik = useFormik({
    initialValues: {
      user_type: "",
      transaction_type: "",
      narration: "",
      wallet_type: "",
      state: "Kogi",
      local_government: "",
      ward: "",
      polling_unit: "",
      amount: "",
    },
    validationSchema: GroupFundSchema,
    onSubmit: async (values, action) => {
      action.setSubmitting(true);
      // console.log(values);
      try {
        const response = await axios.post(BULK_FUND_WALLET, values, config);
        toast(response.data?.message);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        action.resetForm();
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err.response.data);
          toast.error(err.response?.data.message);
        }
      }
    },
  });

  const [statesList, setStatesArrayList] = React.useState([]);
  const [localGov, setLocalGovArray] = React.useState([]);
  const [ward, setWardArray] = React.useState([]);
  const [pollingUnit, setPollingUnitArray] = React.useState([]);

  const inputElement = {
    constantInputElements: [
      {
        id: 1,
        label: "User Type",
        name: "user_type",
        type: "text",
        placeholder: "User Type",
        error: formik.errors.user_type,
        touched: formik.touched.user_type,
        onBlur: formik.handleBlur("user_type"),
        value: formik.values.user_type,
        // onChange: handleChange("user_type"),
      },
      {
        id: 2,
        label: "Transaction Type",
        name: "transaction_type",
        type: "text",
        placeholder: "Transaction Type",
        error: formik.errors.transaction_type,
        touched: formik.touched.transaction_type,
        onBlur: formik.handleBlur("transaction_type"),
        value: formik.values.transaction_type,
        // onChange: handleChange("transaction_type"),
      },
      {
        id: 3,
        label: "Wallet Type",
        name: "wallet_type",
        type: "text",
        placeholder: "Wallet Type",
        error: formik.errors.wallet_type,
        touched: formik.touched.wallet_type,
        onBlur: formik.handleBlur("wallet_type"),
        value: formik.values.wallet_type,
        // onChange: handleChange("wallet_type"),
      },
      {
        id: 4,
        label: "Narration",
        name: "narration",
        type: "text",
        placeholder: "Narration",
        error: formik.errors.narration,
        touched: formik.touched.narration,
        onBlur: formik.handleBlur("narration"),
        value: formik.values.narration,
        // onChange: handleChange("narration"),
      },
      {
        id: 5,
        label: "Amount",
        name: "amount",
        type: "number",
        placeholder: "Amount",
        error: formik.errors.amount,
        touched: formik.touched.amount,
        onBlur: formik.handleBlur("amount"),
        value: formik.values.amount,
        // onChange: handleChange("amount"),
      },
    ],
    votingAddressInputElements: [
      {
        id: 1,
        label: "State",
        name: "state",
        type: "text",
        placeholder: "State",
        error: formik.errors.state,
        touched: formik.touched.state,
        onBlur: formik.handleBlur("state"),
        value: formik.values.state,
      },
      {
        id: 2,
        label: "Local government",
        name: "local_government",
        type: "text",
        placeholder: "Local government",
        error: formik.errors.local_government,
        touched: formik.touched.local_government,
        onBlur: formik.handleBlur("local_government"),
        value: formik.values.local_government,
      },
      {
        id: 3,
        label: "Ward",
        name: "ward",
        type: "text",
        placeholder: "Ward",
        error: formik.errors.ward,
        touched: formik.touched.ward,
        onBlur: formik.handleBlur("ward"),
        value: formik.values.ward,
      },
      {
        id: 4,
        label: "Polling Unit",
        name: "polling_unit",
        type: "text",
        placeholder: "Polling Unit",
        error: formik.errors.polling_unit,
        touched: formik.touched.polling_unit,
        onBlur: formik.handleBlur("polling_unit"),
        value: formik.values.polling_unit,
      },
    ],
  };

  const onChangeInputFields = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const onChangeVotingAddress = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    switch (e.target.name) {
      //   case "state":
      //     const stateData = StateLgaWardPu.filter(
      //       (state) =>
      //         state.name.trim().toLowerCase() ===
      //         e.target.value.trim().toLowerCase()
      //     );
      //     setLocalGovArray(stateData[0].lgas);
      //     break;
      case "local_government":
        const lgaData = localGov.filter(
          (lga) =>
            lga.name.trim().toLowerCase() ===
            e.target.value.trim().toLowerCase()
        );
        setWardArray(lgaData[0].wards);
        formik.setFieldValue(e.target.name, e.target.value);
        break;
      case "ward":
        const wardData = ward.filter(
          (ward) =>
            ward.name.trim().toLowerCase() ===
            e.target.value.trim().toLowerCase()
        );
        setPollingUnitArray(wardData[0].units);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <GlassCard>
        <form
          onSubmit={formik.handleSubmit}
          className="relative flex flex-col lg:px-10 py-8 space-y-2 overflow-x-auto overflow-y-auto max-h-screen"
        >
          {formik.isSubmitting ? <TableSpinner /> : null}

          {/* Group funding */}
          {Object.keys(inputElement)?.map((page) => {
            return (
              <div
                className={
                  page === "constantInputElements"
                    ? "grid grid-cols-5 gap-4"
                    : "grid grid-cols-5 gap-4"
                }
                key={page}
              >
                {inputElement[page]?.map((item, index) => {
                  return page === "constantInputElements" ? (
                    <>
                      {item.name !== "wallet_type" &&
                      item.name !== "transaction_type" &&
                      item.name !== "user_type" ? (
                        <div key={index}>
                          <Input
                            {...item}
                            label=""
                            onChange={onChangeInputFields}
                          />
                        </div>
                      ) : (
                        <Select
                          key={item.id}
                          {...item}
                          label={""}
                          defaultOption={item.label}
                          // name={item.name}
                          onChange={onChangeInputFields}
                          className="text-gray-500"
                          options={
                            item.name === "wallet_type"
                              ? WALLET_TYPE.map((item, index) => {
                                  return (
                                    <option key={index} value={item.type}>
                                      {item.title}
                                    </option>
                                  );
                                })
                              : [
                                  item.name === "transaction_type"
                                    ? TRANSACTION_TYPE.map((item, index) => {
                                        return item.title !== "Refund" ? (
                                          <option key={index} value={item.type}>
                                            {item.title}
                                          </option>
                                        ) : null;
                                      })
                                    : [
                                        item.name === "user_type"
                                          ? USER_TYPE.map((item, index) => {
                                              return item.type !== "ALL" ? (
                                                <option
                                                  key={index}
                                                  value={item.type}
                                                >
                                                  {item.title}
                                                </option>
                                              ) : null;
                                            })
                                          : null,
                                      ],
                                ]
                          }
                        />
                      )}
                    </>
                  ) : null;
                })}
              </div>
            );
          })}

          {/* VOTING ADDRESS */}
          <div className={"space-y-2 pt-4"}>
            {Object.keys(inputElement)?.map((page) => {
              return (
                <div
                  className={
                    page === "votingAddressInputElements"
                      ? "grid grid-cols-4 gap-4 text-center font-bold"
                      : "grid grid-cols-4 gap-4 text-center font-bold"
                  }
                  key={page}
                >
                  {inputElement[page]?.map((item, index) => {
                    return page === "votingAddressInputElements" ? (
                      <div key={index}>{item.label}</div>
                    ) : null;
                  })}
                </div>
              );
            })}

            {Object.keys(inputElement)?.map((page) => {
              return (
                <div
                  className={
                    page === "constPayload"
                      ? "grid grid-cols-4 gap-4"
                      : "grid grid-cols-4 gap-4"
                  }
                  key={page}
                >
                  {inputElement[page]?.map((item, index) => {
                    return page === "votingAddressInputElements" ? (
                      <div key={index}>
                        <Select
                          key={item.id}
                          defaultOption={
                            item.name === "state" ? "Kogi" : item.label
                          }
                          {...item}
                          label={""}
                          disabled={item.name === "state"}
                          onChange={onChangeVotingAddress}
                          className="text-gray-500"
                          options={
                            item.name === "state"
                              ? statesList?.map((item, index) => {
                                  return (
                                    <option key={index} value={item}>
                                      {"Kogi"}
                                    </option>
                                  );
                                })
                              : [
                                  item.name === "local_government"
                                    ? localGov?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.name}>
                                            {item.name}
                                          </option>
                                        );
                                      })
                                    : [
                                        item.name === "ward"
                                          ? ward?.map((item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })
                                          : [
                                              item.name === "polling_unit"
                                                ? pollingUnit?.map(
                                                    (item, index) => {
                                                      return (
                                                        <option
                                                          key={index}
                                                          value={item.name}
                                                        >
                                                          {item.name}
                                                        </option>
                                                      );
                                                    }
                                                  )
                                                : null,
                                            ],
                                      ],
                                ]
                          }
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              );
            })}
          </div>

          {/* 
                    <div className='flex flex-col justify-start dark-orange-text font-bold'>
                        <label>Schedule this transfer</label>
                        <div className='flex w-fit pt-1 space-x-3'>
                            <Select defaultOption="Schedule Time" options=
                                {transferTime.map((item, index) => {
                                    return (
                                        <option key={index}>{item.title}</option>
                                    )
                                })}
                            />
                        </div>
                    </div> */}

          <div className="flex justify-end dark-blue-text font-bold">
            <div className="flex flex-col">
              <strong className="flex justify-end">Total</strong>
              <div className="flex space-x-2">
                <strong className="my-auto">NGN</strong>
                <div className="flex w-min border-2 border-orange-400 rounded-xl px-10 py-2 text-xl">
                  {formik.values.amount?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  }) || 0.0}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-5">
            <div className="flex space-x-5 w-72">
              <Button type={"submit"} disabled={formik.isSubmitting}>
                Pay all
              </Button>
              {/* <ButtonOutline onClick={() => resetFields()}>Cancel</ButtonOutline> */}
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};

export default GroupFunding;
