import React from "react";
import { Input, Select } from "../../components/form";
import { Button, ButtonOutline } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import { Outlet } from "react-router-dom";
import { WALLET_TYPE, TRANSACTION_TYPE } from "../../enum/transactions-enum";
import { SECTOR_TYPE, USER_TYPE } from "../../enum/users-enum";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";

const SECTOR_FUND = "/admin/credit-wallet-by-payee";

export const BulkFundSector = () => {
  const auth = useAuth();
  const token = auth.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const formik = useFormik({
    initialValues: {
      // idempotentKey: nanoid(),
      user_type: "",
      payee_type: "",
      transaction_type: "",
      narration: "",
      wallet_type: "",
    },
    // validationSchema: FundSingleUserSchema,
    onSubmit: async (values) => {
      // console.log(values);
      formik.setSubmitting(true);
      try {
        await axios.post(SECTOR_FUND, values, config).then((response) => {
          toast.success(response.data?.message);
          formik.setSubmitting(false);
        });
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err.response.data);
          toast.error(err.response?.data.message);
          formik.setSubmitting(false);
        }
      }
    },
  });

  const inputElement = [
    {
      id: 1,
      label: "Sector",
      name: "payee_type",
      type: "text",
      placeholder: "Sector",
      error: formik.errors.payee_type,
      touched: formik.touched.payee_type,
      onBlur: formik.handleBlur("payee_type"),
      value: formik.values.payee_type,
      // onChange: formik.handleChange("payee_type"),
    },
    {
      id: 2,
      label: "User Type",
      name: "user_type",
      type: "text",
      placeholder: "User Type",
      error: formik.errors.user_type,
      touched: formik.touched.user_type,
      onBlur: formik.handleBlur("user_type"),
      value: formik.values.user_type,
      // onChange: formik.handleChange("user_type"),
    },
    {
      id: 3,
      label: "Transaction Type",
      name: "transaction_type",
      type: "text",
      placeholder: "Transaction Type",
      error: formik.errors.transaction_type,
      touched: formik.touched.transaction_type,
      onBlur: formik.handleBlur("transaction_type"),
      value: formik.values.transaction_type,
      // onChange: formik.handleChange("transaction_type"),
    },
    {
      id: 4,
      label: "Wallet Type",
      name: "wallet_type",
      type: "text",
      placeholder: "Wallet Type",
      error: formik.errors.wallet_type,
      touched: formik.touched.wallet_type,
      onBlur: formik.handleBlur("wallet_type"),
      value: formik.values.wallet_type,
      // onChange: formik.handleChange("wallet_type"),
    },
    {
      id: 5,
      label: "Narration",
      name: "narration",
      type: "text",
      placeholder: "Narration",
      error: formik.errors.narration,
      touched: formik.touched.narration,
      onBlur: formik.handleBlur("narration"),
      value: formik.values.narration,
      onChange: formik.handleChange("narration"),
    },
  ];

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <GlassCard>
        <form
          onSubmit={formik.handleSubmit}
          className="relative flex flex-col lg:px-10 py-8 space-y-2 overflow-x-auto overflow-y-auto max-h-screen"
        >
          {formik.isSubmitting ? <TableSpinner /> : null}

          {/* Sector Funding */}
          <div
            className={"flex lg:flex-row flex-col flex-wrap justify-between"}
          >
            {inputElement.map((item, index) => {
              return (
                <>
                  {item.name !== "wallet_type" &&
                  item.name !== "transaction_type" &&
                  item.name !== "payee_type" &&
                  item.name !== "user_type" ? (
                    <div key={index} className="lg:w-[18%] lg:my-0 my-2">
                      <Input {...item} label="" />
                    </div>
                  ) : (
                    <div key={index} className="lg:w-[18%] lg:my-0 my-2">
                      <Select
                        key={item.id}
                        defaultOption={item.label}
                        name={item.name}
                        // value={constType.transactiontype || constType.accounttype}
                        onChange={onChange}
                        className="text-gray-500"
                        options={
                          item.name === "wallet_type"
                            ? WALLET_TYPE.map((item, index) => {
                                return (
                                  <option key={index} value={item.type}>
                                    {item.title}
                                  </option>
                                );
                              })
                            : [
                                item.name === "transaction_type"
                                  ? TRANSACTION_TYPE.map((item, index) => {
                                      return item.title === "Salary" ? (
                                        <option key={index} value={item.type}>
                                          {item.title}
                                        </option>
                                      ) : null;
                                    })
                                  : [
                                      item.name === "user_type"
                                        ? USER_TYPE.map((item, index) => {
                                            return item.type !== "ALL" &&
                                              item.type !== "MEMBER" ? (
                                              <option
                                                key={index}
                                                value={item.type}
                                              >
                                                {item.title}
                                              </option>
                                            ) : null;
                                          })
                                        : [
                                            item.name === "payee_type"
                                              ? SECTOR_TYPE.map(
                                                  (item, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={
                                                          item.abbreviation
                                                        }
                                                      >
                                                        {item.title}
                                                      </option>
                                                    );
                                                  }
                                                )
                                              : null,
                                          ],
                                    ],
                              ]
                        }
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>

          <div className="flex justify-end space-x-5">
            <div className="flex space-x-5 w-72">
              <Button type={"submit"} disabled={formik.isSubmitting}>
                Pay all
              </Button>
              {/* <ButtonOutline onClick={() => resetFields}>Cancel</ButtonOutline> */}
            </div>
          </div>
        </form>
      </GlassCard>
      <Outlet />
    </>
  );
};
