import React from 'react';
import NotFound from '../assets/images/404.gif'
export const NoMatch = () => {
    return (
        <>
            <div className='flex justify-center'>
                <img src={NotFound} alt='404' className='w-screen h-screen' />
            </div>
        </>
    )
}