import React from "react";
import { Input, Select } from "../../components/form";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import * as RiIcons from "react-icons/ri";
import { Button } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { ADMIN_TYPE } from "../../enum/admin-type";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AddAdminSchema } from "../../yup";
import { TableSpinner } from "../../components/spinner";

const REGISTER_URL = "/admin/register";

export const CreateAdmin = () => {
  const auth = useAuth();
  const token = auth.token;

  const [submitLoader, setSubmitLoader] = React.useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: AddAdminSchema,
    onSubmit: async (values) => {
      // console.log("F V", values);
      setSubmitLoader(true);
      try {
        const response = await axios({
          method: "post",
          url: REGISTER_URL,
          data: values,
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(response?.data?.message);
        window.location.reload();
        // console.log(response?.data);
        // console.log(response?.access_token);
        setSubmitLoader(false);
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          toast.error(err?.response.data.message);
          console.log(err.response.data);
          setSubmitLoader(false);
        }
      }
    },
  });

  const inputElement = {
    authdata: [
      {
        id: 1,
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        icon: <AiIcons.AiOutlineMail size="25" className="text-gray-500" />,
        goodSuffix: <FcIcons.FcCheckmark size={25} />,
        autoFocus: "on",
        error: formik.errors.email,
        touched: formik.touched.email,
        onBlur: formik.handleBlur("email"),
        value: formik.values.email,
        onChange: formik.handleChange("email"),
      },
      {
        id: 2,
        label: "Last Name",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        error: formik.errors.last_name,
        touched: formik.touched.last_name,
        onBlur: formik.handleBlur("last_name"),
        value: formik.values.last_name,
        onChange: formik.handleChange("last_name"),
      },
      {
        id: 3,
        label: "First Name",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        errorMessage: "First name is empty",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        error: formik.errors.first_name,
        touched: formik.touched.first_name,
        onBlur: formik.handleBlur("first_name"),
        value: formik.values.first_name,
        onChange: formik.handleChange("first_name"),
      },
      {
        id: 4,
        label: "Phone Number",
        name: "phone_number",
        type: "text",
        placeholder: "Phone Number",
        errorMessage: "Phone number is empty",
        icon: <MdIcons.MdLocalPhone size={25} className="text-gray-500" />,
        error: formik.errors.phone_number,
        touched: formik.touched.phone_number,
        onBlur: formik.handleBlur("phone_number"),
        value: formik.values.phone_number,
        onChange: formik.handleChange("phone_number"),
      },
    ],
  };

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-start">
          <div className="flex text-2xl dark-blue-text font-bold">
            <MdIcons.MdAddCircle size={30} />
            Create Admin
          </div>
        </div>
        <hr className="mt-2 " />

        <form onSubmit={formik.handleSubmit}>
          <div className="lg:py-8 lg:px-10 overflow-auto max-h-[450px]">
            <div className="flex lg:flex-row flex-col lg:space-x-20">
              {submitLoader || formik.isSubmitting ? <TableSpinner /> : null}

              <div className="flex flex-col w-full space-y-4">
                {Object.keys(inputElement)?.map((page) => {
                  return (
                    <div key={page}>
                      {inputElement[page]?.map((item) => {
                        return page === "authdata" ? (
                          <div key={item.id} className="mb-6">
                            <Input {...item} />
                          </div>
                        ) : null;
                      })}
                    </div>
                  );
                })}

                <div>
                  <Select
                    defaultOption="Admin Type"
                    name="type"
                    label="Admin Type"
                    error={formik.errors.type}
                    touched={formik.touched.type}
                    onBlur={formik.handleBlur("type")}
                    value={formik.values.type}
                    onChange={onChange}
                    icon={
                      <RiIcons.RiUserSettingsLine
                        size={25}
                        className="text-gray-500"
                      />
                    }
                    options={ADMIN_TYPE.map((item, index) => {
                      return (
                        <option key={index} value={item.role}>
                          {item.title}
                        </option>
                      );
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-5 pt-5">
              <div className="flex w-full">
                {/* <ButtonOutline type="button" onClick={handleClose}>Cancel</ButtonOutline>  */}
                <Button type="submit">Register</Button>
              </div>
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};
