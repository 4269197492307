import React from "react";
import { Avatar } from "../../components/avatar";
import { GlassCard } from "../../components/card";
import * as MdIcons from "react-icons/md";
import { IconContext } from "react-icons/lib";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import { Badge } from "../../components/badge";
import { AdminView } from "./adminList";
import { ADMIN_ENUM_TYPE } from "../../enum/admin-type";
import logo from "../../assets/logo/coloredlogo.png";
import { useLocation } from "react-router-dom";

export const ViewAdmin = () => {
  const [role, setAdminRole] = React.useState("");
  const location = useLocation();
  const { data } = location.state || {};

//   console.log(data)
  return (
    <>
      <GlassCard>
        <div className="flex justify-between border-b-2 border-gray-300 pb-2 mb-2">
          <h1 className="flex text-xs dark-blue-text font-bold">
            Admin Information
          </h1>
          <span className="space-x-4">
            <MdIcons.MdRemoveRedEye size={20} color={"#f98c19"} />
          </span>
        </div>

        <AdminView.Consumer>
          {(adminObj) => {
            return (
              <>
                <figure>
                  <Avatar
                    cl="h-[150px] w-[150px] rounded-full ring-1 ring-[#f98c19] mx-auto"
                    src={adminObj?.profile_picture || logo}
                  />
                  <figcaption className="border-b-2 border-[#59899b] pb-2 mb-2 text-center">
                    <h1 className="text-md dark-blue-text font-black capitalize">
                      {adminObj?.last_name + " " + adminObj?.first_name}
                    </h1>
                  </figcaption>
                </figure>

                <ul className="dark-blue-text text-sm space-y-4">
                  <IconContext.Provider value={{ color: "#f98c19" }}>
                    <li className="flex w-full">
                      <MdIcons.MdMail size={20} />
                      <span>
                        <span className="flex">Email:</span>
                        <sub>{adminObj?.email}</sub>
                      </span>
                    </li>
                    <li className="flex w-full">
                      <MdIcons.MdPhoneInTalk size={20} />
                      <span>
                        <span className="flex">Phone Number:</span>
                        <sub>{adminObj?.phone_number}</sub>
                      </span>
                    </li>
                    <li className="flex w-full">
                      {adminObj?.account_status?.status === "ACTIVE" ? (
                        <MdIcons.MdOutlineGppGood size={20} />
                      ) : (
                        <MdIcons.MdNotInterested size={20} />
                      )}
                      <span>
                        <span className="flex">Account Status:</span>
                        <Badge
                          cl="dark-blue-bg rounded-full px-1 text-xs"
                          content={adminObj?.account_status?.status}
                        />
                      </span>
                    </li>
                    <li className="flex w-full">
                      <RiIcons.RiShieldUserFill size={20} />
                      <span>
                        <span className="flex">Role:</span>
                        <Badge
                          cl="dark-blue-bg rounded-full px-1 text-xs"
                          content={adminObj?.type}
                        />
                      </span>
                    </li>
                    <li className="flex w-full">
                      <FaIcons.FaClipboardList size={20} />
                      <span>
                        <span className="flex">Previledges:</span>
                        {adminObj?.type === ADMIN_ENUM_TYPE.ENGINEER
                          ? setAdminRole("Can control everything")
                          : [
                              adminObj?.type === ADMIN_ENUM_TYPE.SUPER
                                ? setAdminRole("Can control everything")
                                : [
                                    adminObj?.type === ADMIN_ENUM_TYPE.ADMIN
                                      ? setAdminRole(
                                          "Can view & modify but cannot delete"
                                        )
                                      : [
                                          adminObj?.type ===
                                          ADMIN_ENUM_TYPE.TRANSACTION
                                            ? setAdminRole(
                                                "Can manage transactions"
                                              )
                                            : [
                                                adminObj?.type ===
                                                ADMIN_ENUM_TYPE.STAFF_MANAGER
                                                  ? setAdminRole(
                                                      "Can manage staff"
                                                    )
                                                  : null,
                                              ],
                                        ],
                                  ],
                            ]}
                            <span className="flex min-w-max w-full">
                        <Badge
                          cl="dark-blue-bg rounded-full px-1 text-xs"
                          content={role}
                        />
                        </span>
                      </span>
                    </li>
                  </IconContext.Provider>
                </ul>
              </>
            );
          }}
        </AdminView.Consumer>
      </GlassCard>
    </>
  );
};
