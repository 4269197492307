import React from "react";
import { BsChatDots, BsThreeDotsVertical } from "react-icons/bs";
import { GlassCard } from "../../components/card";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";

import axios from "../../api/axios";
import TextTruncate from "react-text-truncate"; // recommend
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterSelect } from "../../components/form";
import { FEED_CATEGORY, FILTER_BY_STATUS } from "../../enum/feeds-enum";
import { Badge } from "../../components/badge";
import { Pagination, Stack } from "@mui/material";
import { TableSpinner } from "../../components/spinner";
import { FormatDateTime } from "../../components/formatDate";

const GET_FEEDS = "/admin/feeds";
const GET_FEEDS_WITH_REF = "/admin/get-feed-with-reference?reference=";

export const Feeds = () => {
  const auth = useAuth();
  const token = auth.token;
  const navigate = useNavigate();

  // INTERCEPT BARERE TOKEN FORM AUTH
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getFeeds();
  }, []);

  const [feeds, setFeeds] = React.useState();
  const [pageCount, setPageCount] = React.useState(0);
  const [totalFilteredData, setTotalFilteredData] = React.useState(0);

  const getFeeds = async () => {
    try {
      const response = await axios({
        method: "get",
        url: GET_FEEDS,
        ...config,
      });
      setFeeds(response?.data?.data.page_data);
      setPageCount(response?.data.data.page_count);
      setTotalFilteredData(response?.data.data.total_filtered_data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const searchFeeds = async (e) => {
    try {
      if (e.keyCode === 13 && e.target.value.length > 0) {
        const response = await axios({
          method: "get",
          url: GET_FEEDS_WITH_REF + e.target.value,
          ...config,
        });
        const res = response?.data?.data;
        setFeeds([res]);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  const [filterValue, setFilterValue] = React.useState({
    filterCategory: "",
    filterStatus: "",
  });

  const filterByCategory = async (e) => {
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    try {
      const response = await axios({
        methof: "get",
        url: GET_FEEDS + "?category=" + e.target.value,
        ...config,
      });
      const res = response?.data?.data?.page_data;
      setFeeds(res);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  const filterByStatus = async (e) => {
    setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    try {
      const response = await axios({
        methof: "get",
        url: GET_FEEDS + "?status=" + e.target.value,
        ...config,
      });
      const res = response?.data?.data?.page_data;
      setFeeds(res);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
      }
    }
  };

  const handleModify = (ref) => {
    navigate("manage-feeds", { state: { data: ref } });
  };

  const onChangePage = async (newPage) => {
    try {
      const response = await axios({
        method: "get",
        url: GET_FEEDS + "?page=" + newPage,
        ...config,
      });
      setFeeds(response?.data.data.page_data);
      setPageCount(response?.data.data.page_count);
      setTotalFilteredData(response?.data.data.total_filtered_data);
      // console.log(response?.data.data)
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-between border-b-2 border-gray-400">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <SiIcons.SiRss size={22} />
            <strong>Feeds</strong>
          </h1>
          <span className="space-x-4">
            <Link to="create-feeds" className="flex">
              <MdIcons.MdAddCircle size={20} />
              Add new feed
            </Link>
          </span>
        </div>

        <div className="flex lg:flex-row flex-col-reverse my-3 lg:justify-between">
          <span className="flex lg:mt-0 mt-4">
            <div className="relative">
              <div className="absolute pointer-events-auto py-1 px-2">
                <RiIcons.RiSearch2Line size={18} />
              </div>
              <input
                type="text"
                placeholder="Search by reference"
                onKeyDown={searchFeeds}
                className="w-[300px] pl-8 rounded-full border-2 border-b-4 border-gray-200 bg-[#7e7e7e] bg-opacity-20 shadow-inner focus:border-[#012a4a] outline-0"
              />
            </div>
          </span>

          <span className="flex space-x-4">
            <FilterSelect
              defaultOption="Filter by category"
              name="filterCategory"
              value={filterValue.filterCategory}
              onChange={filterByCategory}
              icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
              options={FEED_CATEGORY.map((item, index) => {
                return (
                  <option key={index} value={item.type}>
                    {item.title}
                  </option>
                );
              })}
            />

            <FilterSelect
              defaultOption="Filter by status"
              name="filterStatus"
              value={filterValue.filterStatus}
              onChange={filterByStatus}
              icon={<RiIcons.RiFilter2Fill size={15} color={"#f98c19"} />}
              options={FILTER_BY_STATUS.map((item, index) => {
                return (
                  <option key={index} value={item.type}>
                    {item.title}
                  </option>
                );
              })}
            />
          </span>
        </div>

        <div
          className={
            feeds
              ? "relative mt-1 space-y-3 h-screen overflow-y-auto"
              : "relative mt-1 space-y-3 h-screen overflow-hidden"
          }
        >
          {!feeds ? <TableSpinner /> : null}
          {feeds?.map((item, index) => {
            return (
              <div
                type="button"
                key={index}
                className=" lg:h-52 sm:h-30 px-6 py-5 shadow-inner drop-shadow-lg bg-white cursor-pointer"
                onClick={() => handleModify(item.reference)}
              >
                <div className="flex h-full lg:border-l-4 lg:px-4 border-gray-300 rounded-4xl lg:rounded-md ">
                  <div className="flex-none flex-col justify-between w-1/5 h-full rounded-2xl lg:rounded-md">
                    <img
                      src={item?.media[0]?.path}
                      className="h-full w-full rounded-2xl lg:rounded-md"
                      alt="logo"
                    />
                  </div>
                  <article className="grow flex-col pl-4 w-40">
                    <header className="dark-blue-text lg:text-3xl md:text-2xl sm:text-lg xs:text-md font-bold lg:order-1 order-2 truncate">
                      {item.title}
                    </header>
                    <p className="hidden lg:block lg:order-3 text-justify">
                      <TextTruncate
                        line={4}
                        element="span"
                        truncateText="…"
                        text={item.content}
                      />
                    </p>
                    <p className="dark-blue-text lg:text-xl text-sm lg:order-4 order-1 space-x-2">
                      <span>{item.category}</span>
                      <span>
                        {item?.status.status === "PUBLISHED" ? (
                          <Badge
                            cl="bg-green-200 w-min px-2 rounded-md text-green-500 font-bold text-sm"
                            content={item?.status.status}
                          />
                        ) : (
                          [
                            item?.status.status === "PENDING" ? (
                              <Badge
                                cl="bg-orange-200 w-min px-2 rounded-md text-orange-500 font-bold text-sm"
                                content={item?.status.status}
                              />
                            ) : (
                              <Badge
                                cl="bg-red-200 w-min px-2 rounded-md text-red-500 font-bold text-sm"
                                content={item?.status.status}
                              />
                            ),
                          ]
                        )}
                      </span>
                    </p>
                    <p>Ref: {item.reference}</p>
                    <span className="flex justify-start text-xs lg:hidden order-5">
                      <h6>{FormatDateTime(item.created_at)}</h6>
                    </span>
                  </article>
                  <div className="flex flex-col justify-end w-3/2 pl-4">
                    {/* <span className="flex justify-end">
                      <button onClick={() => handleModify(item.reference)}>
                        <BsThreeDotsVertical
                          size={40}
                          className="light-orange-text"
                        />
                      </button>
                    </span> */}

                    <span className="flex justify-end">
                      <h6 className="lg:block hidden">
                        {FormatDateTime(item.created_at)}
                      </h6>

                      {/* <span className="flex flex-col lg:hidden space-y-1">
                        <span className="flex lg:hidden">
                          <FaIcons.FaRegThumbsUp
                            size={18}
                            className="text-green-500 lg:block"
                          />
                          <h6 className="dark-blue-text font-bold pl-1 text-md">
                            {item.like_by?.length || 0}
                          </h6>
                        </span>
                        <span className="flex lg:hidden">
                          <FaIcons.FaRegThumbsDown
                            size={18}
                            className="text-red-500 lg:block"
                          />
                          <h6 className="dark-blue-text font-bold pl-1 text-md">
                            {item.dislike_by?.length || 0}
                          </h6>
                        </span>
                        <span className="flex lg:hidden">
                          <BsChatDots
                            size={18}
                            className="light-orange-text lg:block"
                          />
                          <h6 className="dark-blue-text font-bold pl-1 text-md">
                            {item.comment?.length || 0}
                          </h6>
                        </span>
                      </span> */}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-between mt-2">
          <span>Filtered Data: {totalFilteredData}</span>
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              shape="rounded"
              onChange={(e, value) => onChangePage(value)}
            />
          </Stack>
        </div>
      </GlassCard>
    </>
  );
};
