export const ADMIN_TYPE = [
    // {
    //     id: 1,
    //     title: 'Engineer',
    //     role: 'ENGINEER'
    // },
    {
        id: 2,
        title: 'Super',
        role: 'SUPER'
    },
    {
        id: 3,
        title: 'Admin',
        role: 'ADMIN'
    },
    {
        id: 4,
        title: 'Transaction',
        role: 'TRANSACTION'
    },
    {
        id: 5,
        title: 'Feeds',
        role: 'FEEDS'
    },
    // {
    //     id: 6,
    //     title: 'Health Care Center',
    //     role: 'HCC',
    // },
    {
        id: 7,
        title: 'Support',
        role: 'SUPPORT',
    }

]

export const ADMIN_ENUM_TYPE = {
    ENGINEER: 'ENGINEER',
    SUPER: 'SUPER',
    ADMIN: 'ADMIN',
    TRANSACTION: 'TRANSACTION',
    STAFF_MANAGER: 'STAFF_MANAGER'
}

// export const ADMIN_TYPE = {
//     ENGINEER : 'ENGINEER',
//     SUPER : 'SUPER',
//     ADMIN : 'ADMIN',
//     TRANSACTION : 'TRANSACTION',
//     STAFF_MANAGER : 'STAFF_MANAGER'
// }