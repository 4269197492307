import React from 'react'
import { Outlet } from 'react-router-dom'

// import * as FaIcons from 'react-icons/fa'
// import * as MdIcons from 'react-icons/md'
// import { GlassCard } from '../../components/card'
// import { AdminList } from './adminList'

export const ManageAdmin = () => {
    return (
        <>
            <Outlet />
        </>
    )
}
