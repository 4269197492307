import React from 'react'
import { toast } from 'react-toastify'
import axios from '../../api/axios'
import { Button } from '../../components/buttons'
import { GlassCard } from '../../components/card'
import { Input } from '../../components/form'
import { TableSpinner } from '../../components/spinner'
import { useAuth } from '../../context/auth'

const RESET_PWD = '/admin/request-password-update'

export const ChangePassword = () => {
    const auth = useAuth()
    const token = auth.token

    const [values, setValues] = React.useState({
        email: '',
    })
    const [submitLoader, setSubmitLoader] = React.useState(false)
    const resetPwd = async(e) =>{
        e.preventDefault();
        setSubmitLoader(true);
        try{
            const response = await axios({
                method: 'put',
                url: RESET_PWD,
                data: {email:values.email},
                headers: { Authorization: `Bearer ${token}` }
            })
            toast.success(response?.data?.message)
            setSubmitLoader(false);
            // console.log(response?.data?.message)
        }
        catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                toast.error(err.response.data.message)
                console.log(err.response.data)
                setSubmitLoader(false);
            }
        }
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    return (
        <>
            <GlassCard>

                <div className='p-8 relative'>
                    {submitLoader? <TableSpinner/> : null}
                    <h1 className='dark-blue-text text-lg'>Reset Password</h1>
                    <form onSubmit={resetPwd} className='flex-col space-y-6'>
                        <div className='flex lg:flex-row flex-col lg:space-x-4'>
                            <Input label="Email" type="email" name="email" defaultValue={auth.user.profile.email} onChange={onChange}/>
                        </div>
                        <div className='flex lg:flex-row flex-col lg:space-x-4 space-y-3'>
                            <Button type="submit" >Reset Password</Button>
                        </div>
                    </form>
                </div>

                {/* <div className='p-8'>
                    <h1 className='dark-blue-text text-lg'>Security Question</h1>
                    <form className='flex-col space-y-6'>
                        <div className='flex lg:flex-row flex-col lg:space-x-4'>
                            <Input label="Question 1" placeholder="Enter your security question" />
                            <Input label="Answer" placeholder="Enter your answer" />
                        </div>
                        <div className='flex lg:flex-row flex-col lg:space-x-4'>
                            <Input label="Question 2" placeholder="Enter your security question" />
                            <Input label="Answer" placeholder="Enter your answer" />
                        </div>
                    </form>
                </div>

                <div className='p-8'>
                    <h1 className='dark-blue-text text-lg'>Two-factor Authentication</h1>
                    <sub>Add another level of security to your account with this feature</sub>

                    <div className='py-3 text-xs'>
                        <label htmlFor="toggle" className="text-sm dark-blue-text pr-2">Enable two factor authentication</label>
                        <ToggleButton />
                    </div>
                </div> */}
            </GlassCard>
        </>
    )
}
