import React from 'react'
import { GlassCard, EmpowermentGlassCard } from '../../components/card'
import axios from '../../api/axios'
import { useAuth } from '../../context/auth'
import { EmpowermentList } from './empowerment-list'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TableSpinner } from '../../components/spinner'

const GET_EMPOWERMENT_SUMMARY = '/admin/empowerments-summary'

export const Empowerment = () => {
    const auth = useAuth()
    const token = auth.token

    // INTERCEPT BARERE TOKEN FORM AUTH
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const [summary, setSummary] = React.useState()

    React.useEffect(() => {
        getEmpowermentSummary()
    }, [])

    const getEmpowermentSummary = async () => {
        try {
            await axios.get(GET_EMPOWERMENT_SUMMARY, config)
                .then((response) => {
                    const res = response.data
                    delete res.message
                    setSummary(res)
                })
        }
        catch (err) {
            if (err.response.status === 401) {
                auth.logout()
            } else {
                console.log(err.response.data)
                toast.error(err.response.data.message)
            }
        }
    };

    return (
        <>
            <div className='lg:p-8 p-0 space-y-6'>

                <GlassCard>
                    <div className='relative flex flex-col space-y-6 min-h-[80px]'>
                        <div className='flex'>
                            <h1 className='dark-blue-text font-black text-lg'>Empowerment Statistics</h1>
                        </div>
                        {!summary ? (
                            <TableSpinner />
                        ) : (
                        <div className='flex lg:flex-row flex-col lg:space-x-4 justify-center'>
                            <EmpowermentGlassCard title="Total Request" count={summary?.data?.TOTAL_COUNT || 0}  />
                            <div className='border-2 border-gray-400 lg:h-20 rounded-full lg:my-10 my-5'></div>
                            <EmpowermentGlassCard title="Pending " count={summary?.data?.PENDING || 0}  />
                            <div className='border-2 border-gray-400 lg:h-20 rounded-full lg:my-10 my-5'></div>
                            <EmpowermentGlassCard title="Approved " count={summary?.data?.APPROVED || 0}  />
                            <div className='border-2 border-gray-400 lg:h-20 rounded-full lg:my-10 my-5'></div>
                            <EmpowermentGlassCard title="Declined " count={summary?.data?.DECLINED || 0}  />
                        </div>
                         )}
                    </div>
                </GlassCard>


                <EmpowermentList />

            </div>
        </>
    )
}
