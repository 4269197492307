import React from "react";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import * as RiIcons from "react-icons/ri";

import { Input, Select } from "../../components/form";
import { Button } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SECTOR_TYPE, USER_TYPE } from "../../enum/users-enum";
import StateLgaWardPu from "../../json/SLWP.json";
import { TableSpinner } from "../../components/spinner";
import { useFormik } from "formik";
import { AddUserSchema } from "../../yup";

const REGISTER_USER_URL = "/admin/register-user";
const GET_BANKS = "/transaction/paystack/get-banks";

export const CreateUser = () => {
  const auth = useAuth();
  const token = auth.token;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getState();
    getBanks();
  }, []);

  const getState = () => {
    let stateArray = [];
    // StateLgaWardPu.map((item, i) => {
    //   return stateArray.push(item.name);
    // });
    const stateData = StateLgaWardPu.filter(
      (state) => state.name.trim().toLowerCase() === "kogi"
    );
    setLocalGov(stateData[0].lgas);
    formik.setFieldValue("state", "Kogi");
    formik.setFieldValue("zone", stateData[0].geopoliticalZone);

    // setStatesList(['Kogi']);
  };

  const [submitLoader, setSubmitLoader] = React.useState(false);
  const [banks, setBanks] = React.useState();

  const [statesList, setStatesList] = React.useState([]);
  const [localGov, setLocalGov] = React.useState([]);
  const [ward, setWard] = React.useState([]);
  const [pollingUnit, setPollingUnit] = React.useState([]);

  const formik = useFormik({
    initialValues: { zone: "North Central", state: "Kogi" },
    validationSchema: AddUserSchema,
    onSubmit: async (values) => {
      // console.log("F V", values);
      setSubmitLoader(true);
      try {
        await axios.post(REGISTER_USER_URL, values, config).then(() => {
          toast.success("User added succefully!");
          window.location.reload();
          setSubmitLoader(false);
        });
      } catch (err) {
        if (err.response?.status === 401) {
          auth.logout();
        } else {
          console.log(err.response?.data);
          toast.error(err.response?.data.message);
          setSubmitLoader(false);
        }
      }
    },
  });

  const inputElement = {
    generalData: [
      {
        id: 1,
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        icon: <AiIcons.AiOutlineMail size="25" className="text-gray-500" />,
        goodSuffix: <FcIcons.FcCheckmark size={25} />,
        autoFocus: "on",
        error: formik.errors.email,
        touched: formik.touched.email,
        onBlur: formik.handleBlur("email"),
        value: formik.values.email,
        onChange: formik.handleChange("email"),
      },
      {
        id: 2,
        label: "Last Name",
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        error: formik.errors.last_name,
        touched: formik.touched.last_name,
        onBlur: formik.handleBlur("last_name"),
        value: formik.values.last_name,
        onChange: formik.handleChange("last_name"),
      },
      {
        id: 3,
        label: "First Name",
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        icon: <AiIcons.AiOutlineUser size={25} className="text-gray-500" />,
        error: formik.errors.first_name,
        touched: formik.touched.first_name,
        onBlur: formik.handleBlur("first_name"),
        value: formik.values.first_name,
        onChange: formik.handleChange("first_name"),
      },
      {
        id: 4,
        label: "Phone Number",
        name: "phone_number",
        type: "tel",
        placeholder: "Phone Number",
        icon: <MdIcons.MdLocalPhone size={25} className="text-gray-500" />,
        error: formik.errors.phone_number,
        touched: formik.touched.phone_number,
        onBlur: formik.handleBlur("phone_number"),
        value: formik.values.phone_number,
        onChange: formik.handleChange("phone_number"),
      },
      {
        id: 5,
        label: "State",
        name: "state",
        type: "text",
        placeholder: "State",
        icon: <MdIcons.MdLocationOn size={25} className="text-gray-500" />,
        error: formik.errors.state,
        touched: formik.touched.state,
        onBlur: formik.handleBlur("state"),
        value: formik.values.state,
        onChange: formik.handleChange("state"),
      },
      {
        id: 6,
        label: "Local Government",
        name: "local_government",
        type: "text",
        placeholder: "Local Government",
        icon: <MdIcons.MdLocationOn size={25} className="text-gray-500" />,
        error: formik.errors.local_government,
        touched: formik.touched.local_government,
        onBlur: formik.handleBlur("local_government"),
        value: formik.values.local_government,
        onChange: formik.handleChange("local_government"),
      },
      {
        id: 7,
        label: "Ward",
        name: "ward",
        type: "text",
        placeholder: "Ward",
        icon: <MdIcons.MdLocationOn size={25} className="text-gray-500" />,
        error: formik.errors.ward,
        touched: formik.touched.ward,
        onBlur: formik.handleBlur("ward"),
        value: formik.values.ward,
        onChange: formik.handleChange("ward"),
      },
      {
        id: 8,
        label: "Polling Unit",
        name: "polling_unit",
        type: "text",
        placeholder: "Polling Unit",
        icon: <MdIcons.MdLocationOn size={25} className="text-gray-500" />,
        error: formik.errors.polling_unit,
        touched: formik.touched.polling_unit,
        onBlur: formik.handleBlur("polling_unit"),
        value: formik.values.polling_unit,
        onChange: formik.handleChange("polling_unit"),
      },
      {
        id: 9,
        label: "User Type",
        name: "user_type",
        type: "text",
        placeholder: "User Type",
        icon: (
          <RiIcons.RiUserSettingsLine size={25} className="text-gray-500" />
        ),
        error: formik.errors.user_type,
        touched: formik.touched.user_type,
        onBlur: formik.handleBlur("user_type"),
        value: formik.values.user_type,
        onChange: formik.handleChange("user_type"),
      },
    ],
    staffData: [
      {
        id: 1,
        name: "payee",
        type: "text",
        placeholder: "Payee",
        label: "Payee",
        icon: (
          <RiIcons.RiUserReceived2Line size="25" className="text-gray-500" />
        ),
        goodSuffix: <FcIcons.FcCheckmark size={25} />,
        error: formik.errors.payee,
        touched: formik.touched.payee,
        onBlur: formik.handleBlur("payee"),
        // value: formik.values.payee,
        onChange: formik.handleChange("payee"),
      },
      {
        id: 2,
        label: "Bank Name",
        name: "bank_name",
        type: "text",
        placeholder: "Bank Name",
        icon: <AiIcons.AiFillBank size={25} className="text-gray-500" />,
        error: formik.errors.bank_name,
        touched: formik.touched.bank_name,
        onBlur: formik.handleBlur("bank_name"),
        // value: formik.values.bank_name,
        onChange: formik.handleChange("bank_name"),
      },
      {
        id: 3,
        label: "Account Number",
        name: "account_number",
        type: "text",
        placeholder: "Account Number",
        icon: (
          <AiIcons.AiOutlineFieldNumber size={25} className="text-gray-500" />
        ),
        // required: values.type === "STAFF" ? true : false,
        // pattern: ACC_NUM_REGEX,
        // autoComplete: "off",
        // max: 10,
        error: formik.errors.account_number,
        touched: formik.touched.account_number,
        onBlur: formik.handleBlur("account_number"),
        value: formik.values.account_number,
        onChange: formik.handleChange("account_number"),
      },
      {
        id: 4,
        label: "Payable Amount",
        name: "payable_amount",
        type: "number",
        placeholder: "Payable Amount",
        icon: <MdIcons.MdMoney size={25} className="text-gray-500" />,
        error: formik.errors.payable_amount,
        touched: formik.touched.payable_amount,
        onBlur: formik.handleBlur("payable_amount"),
        value: formik.values.payable_amount,
        onChange: formik.handleChange("payable_amount"),
      },
      {
        id: 5,
        label: "Grade Level",
        name: "grade_level",
        type: "text",
        placeholder: "Grade Level",
        icon: (
          <AiIcons.AiOutlineFieldNumber size={25} className="text-gray-500" />
        ),
        error: formik.errors.grade_level,
        touched: formik.touched.grade_level,
        onBlur: formik.handleBlur("grade_level"),
        value: formik.values.grade_level,
        onChange: formik.handleChange("grade_level"),
      },
    ],
  };

  const getBanks = async () => {
    try {
      const response = await axios({
        method: "get",
        url: GET_BANKS,
        ...config,
      });
      setBanks(response?.data?.data);
      //   console.log(response?.data?.data);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const onChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    switch (e.target.name) {
      // case "state":
      //   const stateData = StateLgaWardPu.filter(
      //     (state) =>
      //       state.name.trim().toLowerCase() === 'kogi'
      //   );
      //   setLocalGov(stateData[0].lgas);
      //   setVotingAddress({
      //     ...votingAddress,
      //     [e.target.name]: e.target.value,
      //     zone: stateData[0].geopoliticalZone,
      //   });
      //   break;
      case "local_government":
        const lgaData = localGov.filter(
          (lga) =>
            lga.name.trim().toLowerCase() ===
            e.target.value.trim().toLowerCase()
        );
        setWard(lgaData[0].wards);
        formik.setFieldValue(e.target.name, e.target.value);
        formik.setFieldValue(
          "senatorial_district",
          lgaData[0].senatorial_district
        );
        break;
      case "ward":
        const wardData = ward.filter(
          (ward) =>
            ward.name.trim().toLowerCase() ===
            e.target.value.trim().toLowerCase()
        );
        setPollingUnit(wardData[0].units);
        formik.setFieldValue(e.target.name, e.target.value);
        break;
      case "polling_unit":
        formik.setFieldValue(e.target.name, e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <GlassCard>
        <div className="flex justify-start">
          <div className="flex text-2xl dark-blue-text font-bold">
            <MdIcons.MdAddCircle size={30} />
            Add User
          </div>
        </div>
        <hr className="mt-2 " />

        <form
          onSubmit={formik.handleSubmit}
          className={
            submitLoader || formik.isSubmitting
              ? "overflow-hidden max-h-[500px]"
              : "relative max-h-[500px] overflow-y-scroll bg-scroll scroll-m-10"
          }
        >
          {submitLoader || formik.isSubmitting ? <TableSpinner /> : null}

          <div className="lg:py-8 lg:px-10">
            <h1 className="dark-blue-text text-lg">Personal Info</h1>
            <div className="flex lg:flex-row flex-col">
              <div className="w-full space-y-4">
                {Object.keys(inputElement)?.map((page) => {
                  return (
                    <div key={page}>
                      {inputElement[page]?.map((item) => {
                        return page === "generalData" &&
                          item.name !== "user_type" &&
                          item.name !== "state" &&
                          item.name !== "local_government" &&
                          item.name !== "ward" &&
                          item.name !== "polling_unit" ? (
                          <div key={item.id} className="mb-6">
                            <Input {...item} />
                          </div>
                        ) : (
                          [
                            page === "generalData" &&
                            (item.name === "user_type" ||
                              item.name === "state" ||
                              item.name === "local_government" ||
                              item.name === "ward" ||
                              item.name === "polling_unit") ? (
                              <Select
                                key={item.id}
                                defaultOption={
                                  item.name === "state" ? "Kogi" : item.label
                                }
                                disabled={item.name === "state"}
                                {...item}
                                onChange={onChange}
                                options={
                                  item.name === "user_type"
                                    ? USER_TYPE.map((item, index) => {
                                        return item.type !== "ALL" ? (
                                          <option key={index} value={item.type}>
                                            {item.title}
                                          </option>
                                        ) : null;
                                      })
                                    : item.name === "state"
                                    ? statesList?.map((item, index) => {
                                        return (
                                          <option key={index} value={"kogi"}>
                                            Kogi
                                          </option>
                                        );
                                      })
                                    : [
                                        item.name === "local_government"
                                          ? localGov?.map((item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            })
                                          : [
                                              item.name === "ward"
                                                ? ward?.map((item, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={item.name}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    );
                                                  })
                                                : [
                                                    item.name === "polling_unit"
                                                      ? pollingUnit?.map(
                                                          (item, index) => {
                                                            return (
                                                              <option
                                                                key={index}
                                                                value={
                                                                  item.name
                                                                }
                                                              >
                                                                {item.name}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      : null,
                                                  ],
                                            ],
                                      ]
                                }
                              />
                            ) : (
                              [
                                formik.values.user_type === "STAFF" &&
                                item.name !== "payee" ? (
                                  <div key={item.id} className="mb-6">
                                    {item.name === "bank_name" ? (
                                      <Select
                                        defaultOption={item.label}
                                        {...item}
                                        onChange={onChange}
                                        options={banks?.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.bank_code}
                                            >
                                              {item.bank_name}
                                            </option>
                                          );
                                        })}
                                      />
                                    ) : (
                                      <Input {...item} />
                                    )}
                                  </div>
                                ) : (
                                  [
                                    formik.values.user_type === "STAFF" &&
                                    item.name === "payee" ? (
                                      <Select
                                        key={item.id}
                                        defaultOption={item.label}
                                        {...item}
                                        onChange={onChange}
                                        icon={
                                          <RiIcons.RiUserSettingsLine
                                            size={25}
                                            className="text-gray-500"
                                          />
                                        }
                                        options={SECTOR_TYPE.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.abbreviation}
                                              >
                                                {item.title}
                                              </option>
                                            );
                                          }
                                        )}
                                      />
                                    ) : null,
                                  ]
                                ),
                              ]
                            ),
                          ]
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex w-full pt-5">
              <Button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Register User
              </Button>
            </div>
          </div>
        </form>
      </GlassCard>
    </>
  );
};
