import React from 'react';

export const Button = ({onClick, type, children, disabled}) => {
    return (
        <button className='dark-blue-bg hover:bg-gray-700 rounded-lg w-full p-2 disabled:bg-slate-400' onClick={onClick} type={type} disabled={disabled}>{children}</button>
    )
}

export const ButtonOutline = ({onClick, type, children}) => {
    return (
        <button className='bg-transparent border border-[#012a4a] rounded-lg w-full p-2' onClick={onClick} type={type}>{children}</button>
    )
}

export const GradientBlueButton = (props) => {
    return (
        <button className='bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#59899b] text-[#f6f6f6]'>{props.children}</button>
    )
}

export const GradientOrangeButton = (props) => {
    return (
        <button className='bg-gradient-to-t from-[#c05e00] via-[#c05e00] to-[#f98c19] text-[#f6f6f6]'>{props.children}</button>
    )
}

