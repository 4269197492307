import React from 'react'
import spinner from '../assets/images/spinner.gif'

function Spinner() {
    return (
        <div className="backdrop-opacity-10 backdrop-invert bg-white/30 h-full w-full absolute m-auto flex top-0 left-0 z-50">
            <img src={spinner} className="h-[50px] w-[50px] flex m-auto" alt='' />
        </div>
    )
}
export default Spinner

export const TableSpinner = () => {
    return (
        <div className="backdrop-opacity-10 backdrop-invert bg-white/30 h-full w-full absolute flex m-auto top-0 left-0 rounded-lg z-40">
            <img src={spinner} className="h-[40px] w-[40px] flex m-auto" alt='' />
        </div>
    )
}
