import { Typography } from "@mui/material";
import React from "react";
import axios from "../../api/axios";
import { GlassCard } from "../../components/card";
import { GlassModal } from "../../components/modal";
import { useAuth } from "../../context/auth";
import logo from "../../assets/logo/coloredlogo.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as FaIcons from "react-icons/fa";
import { Button } from "../../components/buttons";

const GET_SETTINGS_LIST = "/settings";
const UPDATE_SETTINGS = "/settings/update-settings";

export const ManageSettings = () => {
  const auth = useAuth();
  const token = auth.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const fileUpload = React.useRef(null);

  const handleFileUpload = () => {
    fileUpload.current.click();
  };
  const [filename, setFilename] = React.useState("");

  const [settingsList, setSettingsList] = React.useState();
  const [settingsJSON, setSettingsJSON] = React.useState([]);

  React.useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async() =>{

        try {
            const response = await axios({
                method: "get",
                url: GET_SETTINGS_LIST,
                headers: { Authorization: `Bearer ${token}` }
            })
            setSettingsList(response?.data.data)
            console.log(response)
            // console.log(response?.data.data)
        }
        catch (err) {
            if (err.response?.status === 401) {
                auth.logout()
            } else {
                console.log(err.response.data)
            }
        }

  }

  const [updateModal, setUpdateModal] = React.useState(false);

  const handleClose = () => setUpdateModal(false);

  const updateSetting = async (value) => {
    try {
      const response = await axios({
        method: "patch",
        url: UPDATE_SETTINGS + "?type=" + value,
        data: settingsJSON,
        ...config,
      });
      console.log(response);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        console.log(err.response.data);
      }
    }
  };

  const handleUpdateModal = () => {
    setUpdateModal(true);
  };

  const fileChange = (e) => {
    setFilename(e.target.files[0].name);
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      // console.log("e.target.result", e.target.result);
      setSettingsJSON(JSON.parse(e.target.result));
    };
  };

  return (
    <>
      <GlassModal handleOpen={updateModal} handleClose={handleClose}>
        <Typography
          id="modal-modal-description"
          variant="span"
          component="span"
          sx={{ mt: 2 }}
          className="flex flex-col space-y-6"
        >
          <div className="flex flex-col min-h-[300px] m-4 bg-slate-100 rounded-xl px-4 pt-4 pb-20 relative">
            <strong className="dark-blue-text font-semibold text-sm text-center mb-6">
              Upload settings JSON
            </strong>
            <input type="file" hidden ref={fileUpload} onChange={fileChange} />
            <div className="flex justify-center  dark-orange-text mt-4">
              <button type="button" onClick={handleFileUpload}>
                <FaIcons.FaFileUpload size={100} />
              </button>
            </div>
            <p className="dark-orange-text text-center font-bold">
              {filename ? filename : "Choose file to migrate"}
              <br />
              <br />
              <Button type="button" onClick={() => updateSetting("GENERAL")}>
                Update
              </Button>
            </p>
            <div className="flex justify-end absolute bottom-0 right-2">
              <img src={logo} className="w-16" alt="logo" />
            </div>
          </div>
        </Typography>
      </GlassModal>

      <GlassCard>
        <div className="flex lg:flex-row flex-col-reverse lg:justify-end mb-2">
          <span>
            <button onClick={handleUpdateModal}>Update Setting</button>
          </span>
        </div>
        <div className="flex">
          <ul>
            <li className="grid grid-cols-4 gap-2 w-full bg-white rounded-lg mb-1 p-3">
              <strong>Created At</strong>
              <strong>Updated At</strong>
              <strong>Type</strong>
              <strong>Referral Amount</strong>
            </li>
            {settingsList?.map((item, index) => {
              return (
                <div key={index}>
                  <li className="grid grid-cols-4 gap-2 w-full cursor-pointer bg-white rounded-lg mb-2 p-3">
                    <span>{item.created_at}</span>
                    <span>{item.updated_at}</span>
                    <span className="truncate">{item.type}</span>
                    <span className="truncate">{item.referral_amount}</span>
                  </li>

                  <li className="grid grid-cols-3 gap-2 w-full bg-white rounded-lg mb-1 p-3">
                    <strong>Key</strong>
                    <strong>Acronym</strong>
                    <strong>Ministry</strong>
                  </li>
                  {item?.ministries.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="grid grid-cols-3 gap-2 w-full cursor-pointer bg-white rounded-lg mb-2 p-3"
                      >
                        <span>{item.key}</span>
                        <span className="truncate">{item.accronym}</span>
                        <span className="truncate">{item.ministry}</span>
                      </li>
                    );
                  })}
                </div>
              );
            })}
            {/* <li className="flex justify-center">
                            <Stack spacing={2}>
                                <Pagination count={pageCount} shape="rounded" onChange={(e, value) => onChangePage(value)} />
                            </Stack>
                        </li> */}
          </ul>
        </div>
      </GlassCard>
    </>
  );
};
