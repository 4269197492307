import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { NavLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import logo from '../assets/logo/coloredlogo.png';
import logoText from '../assets/logo/logo-text.png';
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

export const Nav = () => {
  const [nav, setnav] = useState(false);

  const handleNav = () => {
    setnav(!nav);
  }

  return (
    <>
      <nav className="bg-gradient-to-r bg-white sticky top-0 z-50 hidden md:block">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-4">
          <div className="relative flex items-center justify-between h-20">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* <!-- Mobile menu button--> */}
              <button onClick={handleNav} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                {!nav ?
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  :
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                }
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <NavLink exact="true" to="/" >
                <div className="flex-shrink-0 flex items-center">
                  <img className="block lg:hidden h-8 w-auto" src={logo} alt="Koopays" />
                  <img className="hidden lg:block h-14 w-auto" src={logo} alt="Koopays" />
                  <img className="hidden lg:block h-10 w-auto" src={logoText} alt="Koopays" />
                </div>
              </NavLink>
            </div>


            <div className="hidden sm:block sm:ml-6 ml-auto p-4">
              <div className="flex space-x-14">

                <a href="https://www.bellocare.ng" target="_blank" activeclassname="dark-orange-text" className="dark-blue-text rounded-3xl shadow-lg hover:bg-gray-400 hover:text-white px-3 py-2 text-sm font-medium" rel="noreferrer">About Us</a>

                {/* <a href="https://www.bellocare.ng" target="_blank" activeclassname="bg-white" className="dark-blue-text rounded-3xl shadow-lg hover:bg-gray-400 hover:text-white px-3 py-2 text-sm font-medium" rel="noreferrer">Contact</a> */}

                <NavLink exact="true" to="/sign-in" activeclassname="bg-black" className="dark-blue-text rounded-3xl shadow-lg light-gray-bg py-1 px-6">Login</NavLink>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div className={!nav ? 'fixed left-[-100%]' : 'ease-in-out duration-900'} id="mobile-menu">
          <ul className="px-2 pt-2 pb-3 space-y-1">
            <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
              <NavLink exact="true" to={{pathname:"https://www.bellocare.ng"}} activeclassname="dark-orange-text" className="dark-blue-text rounded-3xl shadow-lg hover:bg-gray-400 hover:text-white px-3 py-2 text-sm font-medium">About Us</NavLink>
            </li>
            {/* <li className="dark-blue-text px-3 py-2 text-sm font-medium hover:bg-gray-400 hover:text-white border-b border-gray-400">
              <NavLink exact="true" to="/https://www.bellocare.ng" activeclassname="bg-white" className="dark-blue-text rounded-3xl shadow-lg hover:bg-gray-400 hover:text-white px-3 py-2 text-sm font-medium">Contact</NavLink>
            </li> */}
            <li>
              <NavLink exact="true" to="/sign-in" activeclassname="bg-black" className="dark-blue-text rounded-3xl shadow-lg light-gray-bg py-1 px-6">Login</NavLink>
            </li>
          </ul>
        </div>

      </nav>
    </>
  );
}