import React, { Fragment } from "react";
import logoText from '../assets/logo/logo-text.png';
import { Link } from "react-router-dom";
import * as FaIcons from 'react-icons/fa'

export const Footer = () => {
    return (
        <Fragment>
            <footer className="footer p-10 pb-5 bg-base-200 text-base-content bg-gradient-to-r from-gray-100 shadow-inner" id="contact">
                <div className="flex lg:flex-row flex-col">
                    <div className="lg:basis-1/2 basis-full pb-6">
                        <Link to="#/">
                            <img className="w-25 h-9" src={logoText} alt="Text Logo" />
                        </Link>
                        <div className="flex space-x-2">
                            <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaFacebook size={20} /></a>
                            <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaInstagram size={20} /></a>
                            <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaTwitter size={20} /></a>
                            <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaWhatsapp size={20} /></a>
                        </div>


                        <div className="relative inline-block text-left pt-6">
                            <button type="button" className="inline-flex justify-center w-full rounded-3xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium dark-blue-text hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.05928 0.00683594C6.46929 0.328585 4.97968 1.03892 3.71842 2.07683C2.45716 3.11473 1.46194 4.44918 0.81825 5.96556H5.43363C5.87246 4.10045 6.61245 2.32267 7.62334 0.704925L8.05928 0.00683594ZM0.203333 7.95792C-0.0677775 9.30989 -0.0677775 10.7038 0.203333 12.0558C0.29732 12.0262 0.397307 12.0109 0.501293 12.0109H5.07968C4.92793 10.68 4.92793 9.33569 5.07968 8.0048H0.501293C0.397307 8.0048 0.29732 7.98849 0.203333 7.95792ZM0.81825 14.0481C1.46202 15.5644 2.45726 16.8988 3.7185 17.9367C4.97975 18.9746 6.46932 19.685 8.05928 20.0068L7.62334 19.3087C6.61255 17.6913 5.87256 15.9139 5.43363 14.0491H0.81825V14.0481ZM11.9408 20.0068C13.5307 19.685 15.0203 18.9746 16.2815 17.9367C17.5428 16.8988 18.538 15.5644 19.1818 14.0481H14.5664C14.1276 15.9132 13.3876 17.691 12.3767 19.3087L11.9408 20.0068ZM19.7967 12.0558C20.0678 10.7041 20.0678 9.31057 19.7967 7.95894C19.7027 7.98849 19.6027 8.00378 19.4987 8.00378H14.9204C15.0721 9.33467 15.0721 10.679 14.9204 12.0099H19.4987C19.6027 12.0099 19.7027 12.0262 19.7967 12.0568V12.0558ZM19.1818 5.96658H14.5664C14.1276 4.10147 13.3876 2.32369 12.3767 0.705944L11.9408 0.00785497C13.5307 0.329686 15.0203 1.04005 16.2815 2.07795C17.5428 3.11584 18.538 4.45025 19.1818 5.96658ZM10.6899 1.79945C11.4949 3.08799 12.1056 4.49233 12.5017 5.96556H7.49836C7.8883 4.51435 8.49222 3.109 9.31011 1.79945L10 0.694734L10.6899 1.79945ZM7.09441 12.0099C6.92226 10.6803 6.92226 9.33339 7.09441 8.00378H12.9056C13.0777 9.33305 13.0777 10.6796 12.9056 12.0089H7.09441V12.0099ZM9.31011 18.2132C8.50518 16.9247 7.89446 15.5203 7.49836 14.0471H12.5017C12.1056 15.5203 11.4949 16.9247 10.6899 18.2132L10 19.3179L9.31011 18.2132Z" fill="#F98C19" />
                                </svg>
                                &nbsp;
                                English
                                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-3 basis-1/4">
                        <span className="dark-blue-text font-bold lg:text-sm textlg">Related sites</span>
                        <a href="https://kogistate.gov.ng/" target="new" className="link link-hover text-xs">Kogi State Government Site</a>
                        <a href="https://www.kogipedia.net/" target="new" className="link link-hover text-xs">Kogipedia</a>
                    </div>
                    <div className="flex flex-col space-y-3 basis-1/4">
                        <span className="dark-blue-text font-bold lg:text-sm textlg">Socials</span>
                        <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaFacebook size={20} />&nbsp; Facebook</a>
                        <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaInstagram size={20} />&nbsp;Instagram</a>
                        <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaTwitter size={20} />&nbsp;Twitter</a>
                        <a href="https://www.facebook.com" target="new" className="link link-hover text-xs flex"><FaIcons.FaWhatsapp size={20} />&nbsp;WhatsApp</a>

                    </div>
                    <div className="basis-1/4">
                        <span className="dark-blue-text font-bold lg:text-sm textlg">Address</span>
                        <address className="text-xs">Somewhere in Lokoja, Kogi State.</address>
                    </div>
                    <div className="flex flex-col space-y-3 basis-1/4">
                        <span className="dark-blue-text font-bold lg:text-sm textlg">Contacts</span>
                        <a href="mailto:support@bellocare.ng" target="new" className="link link-hover text-xs flex">support@bellocare.ng</a>
                        <a href="mailto:info@bellocare.ng" target="new" className="link link-hover text-xs flex">info@bellocare.ng</a>
                        <a href="chat" target="new" className="link link-hover text-xs flex">Live Chat support</a>
                    </div>
                </div>
                <div className="flex justify-center pt-7 font-thin text-sm dark-orange-text lg:space-x-20">
                    <p className="lg:flex-1 hidden">&copy; BelloCare </p>
                    <div className="lg:flex lg:space-x-20 space-x-4 flex flex-wrap justify-center">
                        <a href="https://www.bellocare.ng/#about">AboutUs</a>
                        <Link to="privacy-policy"> Privacy Policy</Link>
                        <Link to="terms-of-service">Terms of Service</Link>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}