import React from "react";
import { Route, Routes } from "react-router-dom";
import { NoMatch } from "./pages/noMatch";
import { SignIn } from "./pages/auth-pages/sign-in";
import { SignUp } from "./pages/auth-pages/sign-up";
import { Home } from "./pages/home";
import Dashboard from "./pages/dashboard";
import { Empowerment } from "./pages/empowerment/empowerment";
import { Feeds } from "./pages/feeds/feeds";
import { ViewEditFeeds } from "./pages/feeds/view_edit_feeds";
import { ChangePassword } from "./pages/settings/change-password";
import { ManageAdmin } from "./pages/settings/manage-admin";
import { ManageWebsite } from "./pages/settings/manage-website";
import { Profile } from "./pages/settings/profile";
import { Transactions } from "./pages/transactions/transactions";

// import { DashboardLayout } from './layout/dashboard-layout';
// import { Users } from './pages/users/users';
// import { UsersLayout } from './pages/users/users-layout';
// import { EmpowermentLayout } from './pages/empowerment/empowerment-layout';
// import { TransactionsLayout } from './pages/transactions/transactions-layout';
// import { ChatSupportLayout } from './pages/support/chat-support-layout';
// import { FeedsLayout } from './pages/feeds/feeds-layout';
// import { SettingsLayout } from './pages/settings/settings-layout';
// import { SendPushNotification } from './pages/send-push-notification/send-push-notification';
// import { SendSms } from './pages/send-sms/send-sms';

import { ChatBox } from "./pages/support/chat-box";
import { ChatList } from "./pages/support/chat-list";
import { CreateUser } from "./pages/users/create-user";
import { ViewUser } from "./pages/users/view-user";
import { EditUser } from "./pages/users/edit-user";
import { ViewEmpowerment } from "./pages/empowerment/view-empowerment";
import { CreateAdmin } from "./pages/settings/create-admin";
import { AdminList } from "./pages/settings/adminList";
import { ViewAdmin } from "./pages/settings/view-admin";
import { FundUser } from "./pages/transactions/fund-user";
import { SchedulePayment } from "./pages/transactions/bulk-payment-layout";
import { AuthProvider } from "./context/auth";
import {
  RequireAuth,
  RequireTransactionAuth,
  RequireUsersAuth,
} from "./context/require-auth";
import { AddFeeds } from "./pages/feeds/add-feeds";
import { AuditLogs } from "./pages/settings/audit-logs";
import { ManageSettings } from "./pages/settings/manage-settings";
import Spinner from "./components/spinner";
import { ForgotPassword } from "./pages/auth-pages/forgotPaasword";
import { BulkBankTransfer } from "./pages/transactions/bulk-bank-transfer";
import DetectNetworkConnectivity from "./components/DetectNetworkConnectivity";

// Lazy loading components
const DashboardLayout = React.lazy(() => import("./layout/dashboard-layout"));
const Users = React.lazy(() => import("./pages/users/users"));
const UsersLayout = React.lazy(() => import("./pages/users/users-layout"));
const TransactionsLayout = React.lazy(() =>
  import("./pages/transactions/transactions-layout")
);
const EmpowermentLayout = React.lazy(() =>
  import("./pages/empowerment/empowerment-layout")
);
const ChatSupportLayout = React.lazy(() =>
  import("./pages/support/chat-support-layout")
);
const FeedsLayout = React.lazy(() => import("./pages/feeds/feeds-layout"));
const SendPushNotification = React.lazy(() =>
  import("./pages/send-push-notification/send-push-notification")
);
const SendSms = React.lazy(() => import("./pages/send-sms/send-sms"));
const SettingsLayout = React.lazy(() =>
  import("./pages/settings/settings-layout")
);

function App() {
  return (
    <>
      <DetectNetworkConnectivity />
      <AuthProvider>
        <Routes>
          <Route exact index element={<Home />} />
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/forgot-pwd" element={<ForgotPassword />} />

          <Route
            exact
            path=""
            element={
              <React.Suspense fallback={<Spinner />}>
                <DashboardLayout />
              </React.Suspense>
            }
          >
            <Route exact path="dashboard" element={<Dashboard />} />

            <Route
              exact
              path="bulk-transfer"
              element={
                <RequireTransactionAuth>
                  <BulkBankTransfer />
                </RequireTransactionAuth>
              }
            />

            <Route
              exact
              path="users"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireUsersAuth>
                    <UsersLayout />
                  </RequireUsersAuth>
                </React.Suspense>
              }
            >
              <Route exact path="" element={<Users />} />
              <Route exact path="create-user" element={<CreateUser />} />
              <Route exact path="view-user">
                  <Route exact index element={<ViewUser />} />
                  <Route exact path="edit-user" element={<EditUser />} />
                </Route>
            </Route>

            <Route
              exact
              path="transactions"
              element={
                <RequireTransactionAuth>
                  <TransactionsLayout />
                </RequireTransactionAuth>
              }
            >
              <Route exact index element={<Transactions />} />
              <Route exact path="fund-user" element={<FundUser />}>
                <Route
                  exact
                  path="schedule-payment"
                  element={<SchedulePayment />}
                />
              </Route>
            </Route>

            <Route
              exact
              path="empowerment"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <EmpowermentLayout />
                  </RequireAuth>
                </React.Suspense>
              }
            >
              <Route exact path="" element={<Empowerment />} />
              <Route
                exact
                path="view-empowerment"
                element={<ViewEmpowerment />}
              />
            </Route>

            <Route
              exact
              path="feeds"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <FeedsLayout />
                  </RequireAuth>
                </React.Suspense>
              }
            >
              <Route exact index element={<Feeds />} />
              <Route exact path="create-feeds" element={<AddFeeds />} />
              <Route exact path="manage-feeds" element={<ViewEditFeeds />} />
            </Route>

            <Route
              exact
              path="send-sms"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <SendSms />
                  </RequireAuth>
                </React.Suspense>
              }
            />

            <Route
              exact
              path="send-notification"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <SendPushNotification />
                  </RequireAuth>
                </React.Suspense>
              }
            />

            <Route
              exact
              path="settings"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <SettingsLayout />
                  </RequireAuth>
                </React.Suspense>
              }
            >
              <Route exact index element={<Profile />} />
              <Route exact path="profile" element={<Profile />} />
              <Route
                exact
                path="change-password"
                element={<ChangePassword />}
              />
              <Route exact path="manage-admin" element={<ManageAdmin />}>
                <Route exact path="" element={<AdminList />}>
                  <Route exact path="view-admin" element={<ViewAdmin />} />
                </Route>
                <Route exact path="create-admin" element={<CreateAdmin />} />
              </Route>
              <Route exact path="manage-website" element={<ManageWebsite />} />
              <Route
                exact
                path="manage-settings"
                element={<ManageSettings />}
              />
              <Route exact path="audit-logs" element={<AuditLogs />} />
            </Route>

            <Route
              exact
              path="chat-support"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <RequireAuth>
                    <ChatSupportLayout />
                  </RequireAuth>
                </React.Suspense>
              }
            >
              <Route exact index element={<ChatList />} />
              <Route exact path="chat-box" element={<ChatBox />} />
            </Route>
          </Route>
          <Route exact path="*" element={<NoMatch />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
