import React, { useCallback, useMemo, useRef } from "react";
import logo from "../../assets/logo/coloredlogo-name.png";
import axios from "../../api/axios";
import { useAuth } from "../../context/auth";

import * as BsIcons from "react-icons/bs";

import { Input, Select } from "../../components/form";
import { Button, ButtonOutline } from "../../components/buttons";
import { GlassCard } from "../../components/card";
import { Outlet, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { GlassModal } from "../../components/modal";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WALLET_TYPE, TRANSACTION_TYPE } from "../../enum/transactions-enum";
import { TableSpinner } from "../../components/spinner";
import { BulkBankTransfer } from "./bulk-bank-transfer";
import { useFormik } from "formik";
import { FundSingleUserSchema } from "../../yup";
import { debounce } from "../../utils/Debounce";
import { nanoid } from "nanoid";

const GET_USER_WITH_WALLET_CODE =
  "/admin//get-user-by-wallet-code?wallet_code=";
const FUND_USER = "/admin/fund-user-wallet";

export const FundUser = () => {
  const auth = useAuth();
  const token = auth.token;

  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [summaryModal, setSummaryModal] = React.useState(false);
  const handleClose = () => setSummaryModal(false);

  const {
    handleChange,
    onSelect,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      // idempotentKey: nanoid(),
      wallet_code: "",
      amount: "",
      narration: "",
      wallet_type: "",
      transaction_type: "",
    },
    validationSchema: FundSingleUserSchema,
    onSubmit: async (values) => {
      console.log(values);
      setSummaryModal(true);
    },
  });

  const [validatedUser, setValidatedUser] = React.useState();

  const handleValidateUser = useCallback(async (event) => {
    try {
      const response = await axios.get(
        GET_USER_WITH_WALLET_CODE + event.target.value,
        { ...config }
      );
      setValidatedUser(response?.data?.data);
      // toast(response);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
      }
    }
  }, []);

  // memoize the debounce call with useMemo
  const debouncedOnChange = useMemo(() => {
    return debounce(handleValidateUser, 1000);
  }, [handleValidateUser]);

  const fundUser = async () => {
    setSubmitting(true);

    try {
      const response = await axios.post(FUND_USER, values, config);
      toast.success(response?.data?.message);
      resetForm();
      setValidatedUser();
      setSummaryModal(false);
      setSubmitting(false);
    } catch (err) {
      if (err.response?.status === 401) {
        auth.logout();
      } else {
        toast(err.response?.data.message);
        console.log(err.response?.data);
        setSubmitting(false);
      }
    }
  };

  const inputElement = [
    {
      id: 1,
      label: "Wallet Code",
      name: "wallet_code",
      type: "text",
      placeholder: "Wallet Code",
      error: errors.wallet_code,
      touched: touched.wallet_code,
      onBlur: handleBlur("wallet_code"),
      value: values.wallet_code,
      onChange: handleChange("wallet_code"),
      onKeyUp: debouncedOnChange,
      // autoFocus: true,
    },
    {
      id: 2,
      label: "User Type",
      name: "user_type",
      type: "text",
      placeholder: validatedUser?.user_type ? validatedUser?.user_type : "",
      disabled: "disabled",
      error: errors.user_type,
      touched: touched.user_type,
      value: validatedUser?.user_type ? validatedUser?.user_type : "",
    },
    {
      id: 3,
      label: "Beneficiary Name",
      name: "userType",
      type: "text",
      placeholder: validatedUser?.name ? validatedUser?.name : "",
      disabled: "disabled",
      error: errors.userType,
      touched: touched.userType,
      value: validatedUser?.name ? validatedUser?.name : "",
    },
    {
      id: 4,
      label: "Amount",
      name: "amount",
      type: "number",
      placeholder: "Amount",
      error: errors.amount,
      touched: touched.amount,
      onBlur: handleBlur("amount"),
      value: values.amount,
      onChange: handleChange("amount"),
    },
    {
      id: 5,
      label: "Narration",
      name: "narration",
      type: "text",
      placeholder: "Narration",
      error: errors.narration,
      touched: touched.narration,
      onBlur: handleBlur("narration"),
      value: values.narration,
      onChange: handleChange("narration"),
    },
  ];

  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {/* {isSubmitting ? <TableSpinner /> : null} */}

      <GlassModal handleOpen={summaryModal} handleClose={handleClose}>
        {isSubmitting ? <TableSpinner /> : null}

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-center font-bold"
        >
          <p className="dark-blue-text font-bold text-sm text-center">
            PLEASE CONFIRM TRANSACTION
          </p>
          {/* <button type="button" onClick={handleClose} className='border-2 border-orange-400 rounded-lg p-1 h-min text-xs'><FaIcons.FaTimes size={12} color={'orange'} /></button> */}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex flex-col space-y-6 w-[400px]"
        >
          {/* <div className='flex w-full justify-center space-x-4'>
                        <input type="number" max={1} placeholder='*' className="h-10 w-10 placeholder:text-3xl placeholder:text-blue-900 text-center  border-2 outline-orange-400 outline-8 border-orange-400 rounded-md shadow-inner drop-shadow-2xl" />
                        <input type="number" max={1} placeholder='*' className="h-10 w-10 placeholder:text-3xl placeholder:text-blue-900 text-center  border-2 outline-orange-400 outline-8 border-orange-400 rounded-md shadow-inner drop-shadow-2xl" />
                        <input type="number" placeholder='*' className="h-10 w-10 placeholder:text-3xl placeholder:text-blue-900 text-center  border-2 outline-orange-400 outline-8 border-orange-400 rounded-md shadow-inner drop-shadow-2xl" />
                        <input type="number" placeholder='*' className="h-10 w-10 placeholder:text-3xl placeholder:text-blue-900 text-center  border-2 outline-orange-400 outline-8 border-orange-400 rounded-md shadow-inner drop-shadow-2xl" />
                    </div> */}
          <div className="flex flex-col min-h-[300px] m-4 bg-slate-100 border-2 border-cyan-800 rounded-xl px-4 pt-4 pb-20 relative">
            <p className="dark-blue-text font-semibold text-sm text-center mb-6">
              TRANSACTION SUMMARY
            </p>
            <ul>
              <li className="flex justify-between">
                <p>Beneficiary:</p>
                <p>{validatedUser?.name}</p>
              </li>
              <li className="flex justify-between">
                <p>Wallet Code:</p>
                <p>{values?.wallet_code}</p>
              </li>
              <li className="flex justify-between">
                <p>User Type:</p>
                <p>{validatedUser?.user_type}</p>
              </li>
              <li className="flex justify-between">
                <p>Account Type:</p>
                <p>{values?.wallet_type}</p>
              </li>
              <li className="flex justify-between">
                <p>Transaction Type:</p>
                <p>{values?.transaction_type}</p>
              </li>
              <li className="flex justify-between">
                <p>Narration:</p>
                <p>{values?.narration}</p>
              </li>

              <li className="flex justify-between">
                <p>Amount:</p>
                <p>
                  <small>NGN</small>
                  {values?.amount}
                </p>
              </li>
              <li className="flex justify-between dark-blue-text text-xl font-bold mt-5">
                <p>Total Amount:</p>
                <p>
                  <small>NGN</small>
                  {values?.amount}
                </p>
              </li>
            </ul>
            <div className="flex justify-end absolute bottom-0 right-2">
              <img src={logo} className="w-16" alt="logo" />
            </div>
          </div>

          <footer className="flex w-full justify-center text-lg">
            <button
              type="button"
              onClick={fundUser}
              className="bg-gradient-to-t from-[#012a4a] via-[#012a4a] to-[#59899b] text-[#f6f6f6] rounded-xl font-bold px-10 py-3"
            >
              Pay
            </button>
          </footer>
        </Typography>
      </GlassModal>

      <GlassCard>
        <div className="flex justify-between border-b-2 border-gray-400 pb-2">
          <h1 className="flex text-2xl dark-blue-text font-black space-x-1">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <BsIcons.BsFillCaretLeftFill size={30} />
            </span>
            <strong>Fund User</strong>
          </h1>
          <span className="flex space-x-4" onClick={() => scrollToBottom()}>
            <Link
              to={"schedule-payment"}
              className="dark-blue-bg rounded-lg w-full p-2"
            >
              Make Bulk Payment
            </Link>
          </span>
        </div>

        <form
          onSubmit={handleSubmit}
          className="relative lg:px-20 py-8 lg:space-x-20"
        >
          <div className="flex lg:flex-row flex-wrap flex-col lg:space-x-4">
            <div className="flex flex-wrap w-full justify-between">
              {inputElement.map((item, index) => {
                return (
                  <div key={index} className="w-[48%] my-4">
                    <Input
                      {...item}
                      // value={inputValues[item.name]}
                      // onChange={onChange}
                    />
                  </div>
                );
              })}

              <div className="w-[48%] my-4">
                <Select
                  defaultOption="Account Type"
                  name="wallet_type"
                  label="Account To Fund"
                  // value={inputValues.accountType}
                  // onChange={onChange}
                  error={errors.wallet_type}
                  touched={touched.wallet_type}
                  value={values.wallet_type}
                  onChange={handleChange("wallet_type")}
                  options={WALLET_TYPE?.map((item, index) => {
                    return (
                      <option key={index} value={values[item.type]}>
                        {item.type}
                      </option>
                    );
                  })}
                />
              </div>

              <div className="w-[48%] my-4">
                <Select
                  defaultOption="Transaction Type"
                  name="transaction_type"
                  label="Transaction Type"
                  // value={inputValues.transactionType}
                  // onChange={onChange}
                  error={errors.transaction_type}
                  touched={touched.transaction_type}
                  value={values.transaction_type}
                  onChange={handleChange("transaction_type")}
                  options={TRANSACTION_TYPE.map((item, index) => {
                    return (
                      <option key={index} value={values[item.type]}>
                        {item.type}
                      </option>
                    );
                  })}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-5 pt-5">
            <div className="flex space-x-5 w-72">
              <Button
                // onClick={handleOpen}
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Proceed
              </Button>
              {/* <ButtonOutline type="button">Cancel</ButtonOutline> */}
            </div>
          </div>
        </form>
      </GlassCard>

      <div ref={bottomRef} className="mt-10">
        <Outlet />
      </div>
    </>
  );
};
