import React from "react";
import { DefaultCard, StatCard } from "../components/card";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as SiIcons from "react-icons/si";
import * as GiIcons from "react-icons/gi";
import { useAuth } from "../context/auth";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ResponsiveStream } from "@nivo/stream";
import { ResponsivePie } from "@nivo/pie";
import {
  dashboardReducer,
  fillReducer,
  INITIAL_FILL_STATE,
  INITIAL_TRANSACTION_STATE,
} from "../reducer/dashboardReducer";
import { TableSpinner } from "../components/spinner";

const GET_USERS = "/admin/users";
const GET_TRANSACTIONS = "/admin/transactions";
const GET_TRANSACTIONS_SUMMARY = "/admin/transaction-summary";
const GET_SUPPORT_TICKET = "/admin/supports";
const GET_SUPPORT_SUMMARY = "/admin/support-summary";
const GET_EMPOWERMENT = "/admin/empowerments";
const GET_EMPOWERMENT_SUMMARY = "/admin/empowerments-summary";
const GET_FEEDS = "/admin/feeds";
const GET_FEEDS_SUMMARY = "/admin/feeds-summary";

const supportAdmins = ["ENGINEER", "SUPER", "ADMIN"];

const Dashboard = () => {
  const auth = useAuth();
  const token = auth.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  React.useEffect(() => {
    getDashboardStat()
      }, []);
    
    const getDashboardStat = async () => {
        try {
          const usersResponse = await axios({
            method: "get",
            url: GET_USERS,
            ...config,
          });
          const transactionsResponse = await axios({
            method: "get",
            url: GET_TRANSACTIONS,
            ...config,
          });
          const transactionsSummaryResponse = await axios({
            method: "get",
            url: GET_TRANSACTIONS_SUMMARY,
            ...config,
          });
          if (
            supportAdmins?.filter((data) => data === auth?.user.profile.type)
              .length > 0
          ) {
            const ticketResponse = await axios({
              method: "get",
              url: GET_SUPPORT_TICKET,
              ...config,
            });
            setTotalTicketCount(ticketResponse?.data.data.total_filtered_data);
          }
          const empowermentResponse = await axios({
            method: "get",
            url: GET_EMPOWERMENT,
            ...config,
          });
          const empowermentSummaryResponse = await axios({
            method: "get",
            url: GET_EMPOWERMENT_SUMMARY,
            ...config,
          });
          const feedsResponse = await axios({
            method: "get",
            url: GET_FEEDS,
            ...config,
          });
          const feedsSummaryResponse = await axios({
            method: "get",
            url: GET_FEEDS_SUMMARY,
            ...config,
          });
          const supportSummaryResponse = await axios({
            method: "get",
            url: GET_SUPPORT_SUMMARY,
            ...config,
          });
          setTotalUserCount(usersResponse?.data.data.total_filtered_data);
          setTotalTransactionCount(
            transactionsResponse?.data.data.total_filtered_data
          );
          setTotalEmpowermentCount(
            empowermentResponse?.data.data.total_filtered_data
          );
          setTotalEmpowermentSummary(empowermentSummaryResponse?.data.data);
          setTotalFeedsCount(feedsResponse?.data.data.total_filtered_data);
          setFeedsSummary(feedsSummaryResponse?.data?.status_summary);
          setSupportSummary(supportSummaryResponse?.data?.status_summary);
          transactionChart(transactionsSummaryResponse?.data);
        } catch (err) {
          if (err.response?.status === 401) {
            auth.logout();
          } else {
            console.log(err.response?.data);
            toast.error(err.response?.data.message);
          }
        }
      };

  const data = [
    {
      Failed_Summary: 139,
      Pending: 123,
      Success: 32,
      Reversed: 612,
    },
    {
      Failed_Summary: 230,
      Pending: 349,
      Success: 35,
      Reversed: 612,
    },
    {
      Failed_Summary: 109,
      Pending: 133,
      Success: 38,
      Reversed: 612,
    },
    {
      Failed_Summary: 119,
      Pending: 124,
      Success: 831,
      Reversed: 612,
    },
    {
      Failed_Summary: 149,
      Pending: 163,
      Success: 483,
      Reversed: 612,
    },
    {
      Failed_Summary: 199,
      Pending: 173,
      Success: 612,
      Reversed: 612,
    },
  ];

  const [totalUserCount, setTotalUserCount] = React.useState(0);
  const [totalTransactionCount, setTotalTransactionCount] = React.useState(0);
  const [totalTicketCount, setTotalTicketCount] = React.useState(0);
  const [totalEmpowermentCount, setTotalEmpowermentCount] = React.useState(0);
  const [totalEmpowermentSummary, setTotalEmpowermentSummary] =
    React.useState(0);
  const [feedsSummary, setFeedsSummary] = React.useState();
  const [supportSummary, setSupportSummary] = React.useState();
  const [totalFeedsCount, setTotalFeedsCount] = React.useState(0);

  const [state, dispatch] = React.useReducer(
    dashboardReducer,
    INITIAL_TRANSACTION_STATE
  );
  const [fillState, dispatchFill] = React.useReducer(
    fillReducer,
    INITIAL_FILL_STATE
  );

  const transactionChart = (data) => {
    delete data.message;
    delete data.transactions;

    const chartArray = [];
    const chartFill = [];

    Object.keys(data)?.map((res1, i) => {
      return Object.values(data)?.map((res2, x) => {
        return i === x ? (
          <>
            {chartArray.push({
              id: res1.replace("_summary", ""),
              label: res1.replace("_summary", ""),
              value: res2.total_count,
              color: "hsl(267, 70%, 50%)",
            })}
            {chartFill.push({
              match: {
                id: res1.replace("_summary", ""),
              },
              id: "lines",
            })}
          </>
        ) : null;
      });
    });

    if (chartArray.length > 0) {
      dispatch({
        type: "FETCH_TRANSACTION_SUMMARY",
        payload: chartArray,
      });
      dispatchFill({
        type: "CHART_FILLS",
        payload: chartFill,
      });
    }
  };

  return (
    <>
      <div className="lg:p-8 py-2 space-y-6">
        <div className="flex justify-center lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-4">
          <Link to={"/users"} className="w-full">
            <StatCard
              icon={<FaIcons.FaUsers size={30} />}
              title={totalUserCount || <TableSpinner />}
              subTitle="Users"
            />
          </Link>
          <Link to={"/transactions"} className="w-full">
            <StatCard
              icon={<AiIcons.AiOutlineSwap size={30} />}
              title={totalTransactionCount || <TableSpinner />}
              subTitle="Transaction"
            />
          </Link>
          <Link to={"/chat-support"} className="w-full">
            <StatCard
              icon={<GiIcons.GiTicket size={30} />}
              title={totalTicketCount || <TableSpinner />}
              subTitle="Support Ticket"
            />
          </Link>
          <Link to={"/empowerment"} className="w-full">
            <StatCard
              icon={<SiIcons.SiHelpdesk size={30} />}
              title={totalEmpowermentCount || <TableSpinner />}
              subTitle="Empowerment"
            />
          </Link>
          <Link to={"/feeds"} className="w-full">
            <StatCard
              icon={<SiIcons.SiRss size={30} />}
              title={totalFeedsCount || <TableSpinner />}
              subTitle="Feeds"
            />
          </Link>
        </div>

        <div className="flex lg:flex-row flex-col lg:space-x-6">
          <div className="flex-1 space-y-4">
            <DefaultCard cl="drop-shadow-xl shadow-lg h-min w-full border-2 p-0 dark-orange-bg rounded-lg">
              <span className="absolute p-4 space-y-2">
                <h1>Manage your users</h1>
                <sub>You can create, update, delete or change user status </sub>
              </span>
              <span className="absolute bottom-3 right-8">
                <Link
                  to={"/users"}
                  type="button"
                  className="bg-white text-black p-2 rounded-md"
                >
                  Create User
                </Link>
              </span>
              <svg
                className="w-[210px] ml-auto rounded-r-md"
                width="210"
                height="152"
                viewBox="0 0 210 152"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="94"
                  y="5.34497"
                  width="136.25"
                  height="110.87"
                  rx="6"
                  transform="rotate(-45.0012 94 5.34497)"
                  fill="#F98C19"
                />
                <rect
                  x="114"
                  y="108.345"
                  width="136.25"
                  height="110.87"
                  rx="6"
                  transform="rotate(-45.0012 114 108.345)"
                  fill="#F98C19"
                />
                <rect
                  x="-2"
                  y="166.345"
                  width="136.25"
                  height="110.87"
                  rx="6"
                  transform="rotate(-45.0012 -2 166.345)"
                  fill="#F98C19"
                />
              </svg>
            </DefaultCard>

            <DefaultCard cl="drop-shadow-xl shadow-lg h-min w-full border-2 px-4 py-4 bg-white rounded-lg space-y-6 dark-blue-text">
              <div className="flex space-x-2">
                <SiIcons.SiHelpdesk size={30} color="#012a4a" />
                <h1 className="text-2xl font-black">Empowerment</h1>
              </div>
              <div className="space-x-4 flex justify-end text-center">
                {totalEmpowermentSummary ? (
                  <>
                    <span>
                      <h1 className="light-orange-text text-4xl">
                        {totalEmpowermentSummary.APPROVED || 0}
                      </h1>
                      <sub>Approved Request</sub>
                    </span>
                    <span>
                      <h1 className="light-orange-text text-4xl">
                        {totalEmpowermentSummary.PENDING || 0}
                      </h1>
                      <sub>Pending Request</sub>
                    </span>
                    <span>
                      <h1 className="light-orange-text text-4xl">
                        {totalEmpowermentSummary.DECLINED || 0}
                      </h1>
                      <sub>Declined Request</sub>
                    </span>
                  </>
                ) : (
                  <TableSpinner />
                )}
              </div>
            </DefaultCard>

            <DefaultCard cl="">
              <div className="flex lg:flex-row flex-col lg:space-x-3">
                <div className="flex-1 dark-blue-bg drop-shadow-xl shadow-lg h-min w-full border-2 p-4 rounded-lg space-y-6">
                  <div className="flex space-x-2">
                    <AiIcons.AiFillMessage size={28} color="#fff" />
                    <h1 className="text-2xl font-black">Tickets</h1>
                  </div>
                  <div className="space-x-4 flex justify-end text-center">
                    {supportSummary ? (
                      supportSummary?.map((item, index) => {
                        return (
                          <span key={index}>
                            <h1 className="light-orange-text text-4xl">
                              {item?.total_count}
                            </h1>
                            <sub>{item?._id}</sub>
                          </span>
                        );
                      })
                    ) : (
                      <TableSpinner />
                    )}
                  </div>
                </div>
                <div className="flex-1 dark-blue-bg drop-shadow-xl shadow-lg h-min w-full border-2 p-4 rounded-lg space-y-6">
                  <div className="flex space-x-2">
                    <SiIcons.SiRss size={25} color="#fff" />
                    <h1 className="text-2xl font-black">Feeds</h1>
                  </div>
                  <div className="space-x-4 flex justify-end text-center">
                    {feedsSummary ? (
                      feedsSummary?.map((item, index) => {
                        return (
                          <span key={index}>
                            <h1 className="light-orange-text text-4xl">
                              {item?.total_count || 0}
                            </h1>
                            <sub>{item?._id}</sub>
                          </span>
                        );
                      })
                    ) : (
                      <TableSpinner />
                    )}
                  </div>
                </div>
              </div>
            </DefaultCard>
          </div>
          <div className="flex-1 space-y-4 lg:mt-0 mt-6">
            <DefaultCard cl="drop-shadow-xl shadow-lg h-min w-full border-2 px-4 py-4 bg-white rounded-lg space-y-6 dark-blue-text">
              <div className="flex flex-col w-full h-[400px]">
                <div className="flex space-x-2">
                  <AiIcons.AiOutlineSwap size={30} color="#012a4a" />
                  <h1 className="text-2xl font-black">
                    Transaction Statistics
                  </h1>
                </div>

                {totalTransactionCount ? (
                  <ResponsivePie
                    data={state.data}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    colors={{ scheme: "brown_blueGreen" }}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#fff"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ theme: 'background' }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor="white"

                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    fill={fillState.data}
                    legends={[
                      {
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: "#000",
                        itemDirection: "left-to-right",
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: "circle",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemTextColor: "#000",
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <TableSpinner />
                )}
              </div>
            </DefaultCard>
          </div>
        </div>

        {/* <DefaultCard cl="drop-shadow-xl shadow-lg h-min w-full border-2 px-4 py-4 bg-white rounded-lg space-y-6 dark-blue-text">
                    <div className='flex w-full h-[400px]'>
                        <ResponsiveStream
                            data={data}
                            keys={[
                                'Pending',
                                'Failed_Summary',
                                'Success',
                                'Reversed'
                            ]}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: 36
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: -40
                            }}
                            enableGridX={true}
                            enableGridY={false}
                            curve="basis"
                            offsetType="none"
                            colors={{ scheme: 'oranges' }}
                            borderColor={{ theme: 'labels.text.fill' }}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#2c998f',
                                    size: 4,
                                    padding: 2,
                                    stagger: true
                                },
                                {
                                    id: 'squares',
                                    type: 'patternSquares',
                                    background: 'inherit',
                                    color: '#e4c912',
                                    size: 6,
                                    padding: 2,
                                    stagger: true
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'Failed_Summary'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'Success'
                                    },
                                    id: 'squares'
                                }
                            ]}
                            dotSize={8}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.7
                                    ]
                                ]
                            }}

                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    translateX: 100,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />


                    </div>
                </DefaultCard> */}
      </div>
    </>
  );
};

export default Dashboard;
